import React from 'react'
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import { RangeEurosDividedMillion, RangeYears, GetCity } from "../utility/Range"
import StackedColum from '../TypeGraphic/StackedColum';
import text from '../../DB/text';
import Table from '../TypeGraphic/Table'

export default class EmisionesCONOxPM extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      valueEGV: 0,
      displaychoose: 'lpv',
      city: GetCity(props.city),
      lineYears: null,
      emisionesCO: null,
      emisionesNox: null,
      emisionesPM: null
    }
  }

  componentDidMount() {
    this.allRangeEuro();
  }

  allRangeEuro() {

    var workbook
    var emisionesCO = []
    var emisionesNox = []
    var emisionesPM = []
    for (const iterator of this.props.excel) {
      workbook = iterator.Sheets["ETAPA4_BGE"];

      var Particulares = RangeEurosDividedMillion('Y73:Y93', ['Vehículos Particulares'], workbook);//Emisiones CO Vehículos Particulares Bencineros Gran Santiago
      var Buses = RangeEurosDividedMillion('AT73:AT93', ['Buses'], workbook);//Emisiones CO Vehículos Particulares Diésel Gran Santiago
      var Camiones = RangeEurosDividedMillion('BO73:BO93', ['Camiones'], workbook);//Emisiones CO Vehículos Particulares Gas Gran Santiago
      var Taxis = RangeEurosDividedMillion('CJ73:CJ93', ['Taxis'], workbook);//Emisiones CO Vehículos Particulares Gran Santiago Totales

      emisionesCO.push([Particulares[0], Buses[0], Camiones[0], Taxis[0]])

      Particulares = RangeEurosDividedMillion('Y190:Y210', ['Vehículos Particulares'], workbook);//Emisiones CO Vehículos Particulares Bencineros Gran Santiago
      Buses = RangeEurosDividedMillion('AT190:AT210', ['Buses'], workbook);//Emisiones CO Vehículos Particulares Diésel Gran Santiago
      Camiones = RangeEurosDividedMillion('BO190:BO210', ['Camiones'], workbook);//Emisiones CO Vehículos Particulares Gas Gran Santiago
      Taxis = RangeEurosDividedMillion('CJ190:CJ210', ['Taxis'], workbook);//Emisiones CO Vehículos Particulares Gran Santiago Totales

      emisionesNox.push([Particulares[0], Buses[0], Camiones[0], Taxis[0]])

      Particulares = RangeEurosDividedMillion('Y306:Y326', ['Vehículos Particulares'], workbook);//Emisiones CO Vehículos Particulares Bencineros Gran Santiago
      Buses = RangeEurosDividedMillion('AT306:AT326', ['Buses'], workbook);//Emisiones CO Vehículos Particulares Diésel Gran Santiago
      Camiones = RangeEurosDividedMillion('BO306:BO326', ['Camiones'], workbook);//Emisiones CO Vehículos Particulares Gas Gran Santiago
      Taxis = RangeEurosDividedMillion('CJ306:CJ326', ['Taxis'], workbook);//Emisiones CO Vehículos Particulares Gran Santiago Totales

      emisionesPM.push([Particulares[0], Buses[0], Camiones[0], Taxis[0]])
    }

    var lineYears = RangeYears('D73:D93', workbook);
    var columns = [text[this.props.lenguage].tools.TYPE, text[this.props.lenguage].tools.years, text[this.props.lenguage].tools.YEmissions]

    this.setState({
      lineYears,
      emisionesCO,
      emisionesNox,
      emisionesPM,
      columns
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.flagChange == true) {
      if (nextProps.city) {
        var city = GetCity(nextProps.city)
        this.setState({ city }, () => {
        });
      }
      this.allRangeEuro();
    }
  }

  render() {
    const style = {
        
        
        
    };

    const txt = text[this.props.lenguage].graph;
    return (
      <div style={style}>

        <div  >
          <Grid item xs={12}>

            {this.state.emisionesCO && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g120 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.emisionesCO} /> : null
            }
            {this.state.emisionesNox && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g121 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.emisionesNox} /> : null
            }
            {this.state.emisionesPM && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g122 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.emisionesPM} /> : null
            }
          </Grid>

          <Grid item xs={12}>

            {this.state.emisionesCO && this.props.visualization === 'table' ?
              <Table text={txt.g120 + this.state.city}
                columns={this.state.columns} lpv={this.state.emisionesCO} /> : null
            }
            {this.state.emisionesNox && this.props.visualization === 'table' ?
              <Table text={txt.g121 + this.state.city}
                columns={this.state.columns} lpv={this.state.emisionesNox} /> : null
            }
            {this.state.emisionesPM && this.props.visualization === 'table' ?
              <Table text={txt.g122 + this.state.city}
                columns={this.state.columns} lpv={this.state.emisionesPM} /> : null
            }
          </Grid>

        </div>
      </div>
    );
  }
}



