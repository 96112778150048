import React from 'react'
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Lpv from '../TypeGraphic/Lpv';
import text from "../../DB/text"
import { RangeEuros, RangeYears, GetCity } from "../utility/Range"
import Table from '../TypeGraphic/Table'

export default class VehiclePrivate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      city: GetCity(props.city),
      lineYears: [],
      saturation: null,
      columns: []
    }
  }
  componentDidMount() {
    this.allRangeEuro()
  }

  allRangeEuro() {
    //var workbook = this.props.excel[0].Sheets["ETAPA1_BGE"]
    var workbook
    var saturation = [];
    for (const iterator of this.props.excel) {
      workbook = iterator.Sheets["ETAPA1_BGE"];
      saturation.push(RangeEuros('E13:H35', ['500', '600', '700', '800'], workbook))
    }
    var lineYears = RangeYears('D13:D35', workbook)

    var columns = [text[this.props.lenguage].tools.config103, text[this.props.lenguage].tools.years, text[this.props.lenguage].tools.YVP]
    this.setState({
      lineYears,
      saturation,
      columns
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.flagChange === true) {
      if (nextProps.city) {
        var city = GetCity(nextProps.city)
        this.setState({ city }, () => {
        });
        this.allRangeEuro();
      }
    }
  }

  render() {
    const style = {



    };

    return (
      <div style={style}>
        <div  >

          <Grid container>


            <Grid item xs={12}>
              {this.state.saturation && this.props.visualization === 'graph' ? <Lpv scena={text[this.props.lenguage].tools} yaxis={text[this.props.lenguage].tools.YVP} text={text[this.props.lenguage].graph.g1 + this.state.city} years={this.state.lineYears} lpv={this.state.saturation} /> : null}
            </Grid>

          </Grid>

          {this.state.saturation && this.props.visualization === 'table' ? <Table text={text[this.props.lenguage].graph.g1 + this.state.city} columns={this.state.columns} lpv={this.state.saturation} /> : null}
        </div>
      </div>
    );
  }
}

