
import XLSX from '@sheet/core';
import S5SCalc from '@sheet/formula';
S5SCalc.XLSXLib = XLSX;

const initialStageOne = data => {

  return {
    type: "INITIAL_STAGE_ONE",
    matrixValue: data.matrixValue,
    matrixFormula: data.matrixFormula,
  }
}

const initialStageTwo = data => {
  return {
    type: "INITIAL_STAGE_TWO",
    matrixValue: data.matrixValue,
    matrixFormula: data.matrixFormula,
  }
}

const initialStageThree = data => {
  return {
    type: "INITIAL_STAGE_THREE",
    matrixValue: data.matrixValue,
    matrixFormula: data.matrixFormula,
  }
}

const initialStageFour = data => {
  return {
    type: "INITIAL_STAGE_FOUR",
    matrixValue: data.matrixValue,
    matrixFormula: data.matrixFormula,
  }
}

const excel = data => {
  return {
    type: "EXCEL",
    workbook: data.workbook,
  }
}

const city = data => {
  return {
    type: "CITY",
    city: data.city,
  }
}


const lenguaje = data => {

  return {
    type: "LENGUAJE",
    lenguaje: data,

  }
}



export { initialStageOne, initialStageTwo, initialStageThree, initialStageFour, lenguaje, excel, city }