import React from 'react';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

const ProyectPrivateGDP = ({ growthPopulation, handleChange, growthGdp, input, index, txt }) => {

  const listArray = () => {
    var arr = [];
    for (var j = -100; j <= 100; j++) {
      arr[arr.length] = j;
    }
    return arr;
  }

  return (
    <div className="row" style={{ marginBottom: 10 }}>


      <div className="col-md-3">
        <blockquote>
          {index == 0 && <span style={{ color: '#757575' }}>{txt.base}</span>}
          {index == 1 && <span style={{ color: '#757575' }}>{txt.comparison}</span>}

        </blockquote>
      </div>

      <div className="col-md-3">

        <span style={{ color: '#757575' }}>{txt.growthPo}</span>
        <Select
          value={growthPopulation}
          onChange={handleChange(index)}
          style={{ marginLeft: 10, height: 30 }}
          input={
            <OutlinedInput
              style={{ borderColor: '#ffa200' }}
              labelWidth={50}
              name="growthPopulation"
              id={'index'}
            />
          }>
          {
            Array.from(listArray(), (val, i) => (
              <MenuItem key={val} style={{ paddingRight: 21, height: '20px !important' }} value={val}>  <span style={{ paddingRight: 21 }}> {val} %</span>  </MenuItem>
            ))
          }
        </Select>

      </div>
      <div className="col-md-3">
        <div style={{ marginLeft: '5px' }}>
          <span style={{ color: '#757575' }}>{txt.gdpGrowth}</span>
          <Select
            value={growthGdp}
            onChange={handleChange(index)}

            style={{ marginLeft: 10, height: 30 }}
            input={
              <OutlinedInput
                style={{ borderColor: '#ffa200' }}
                labelWidth={50}
                name="growthGdp"
                id={'index2'}
              />
            }>
            {
              Array.from(listArray(), (val, i) => (
                <MenuItem key={val} style={{ paddingRight: 21 }} value={val}>  <span style={{ paddingRight: 21 }}> {val} %</span>  </MenuItem>
              ))
            }
          </Select>
        </div>
      </div>
      <div className="col-md-3">
        <div style={{ marginLeft: '5px' }}>
          <span style={{ color: '#757575' }}>{txt.growthcars}</span>
          <Select
            value={input}
            onChange={handleChange(index)}
            style={{ marginLeft: 10, height: 30 }}
            input={
              <OutlinedInput
                style={{ borderColor: '#ffa200' }}
                labelWidth={50}
                name="input"
                id={'index4'}
              />
            }>
            {
              Array.from(listArray(), (val, i) => (
                <MenuItem key={val} style={{ paddingRight: 21 }} value={val}>  <span style={{ paddingRight: 21 }}> {val} %</span>  </MenuItem>
              ))
            }
          </Select>
        </div>
      </div>

    </div>
  );
}
export default ProyectPrivateGDP