import React from 'react'
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import text from '../../DB/text';
import { RangeEurosDividedMillion, RangeYears, GetCity } from "../utility/Range"
import StackedColum from '../TypeGraphic/StackedColum';
import Table from '../TypeGraphic/Table'

export default class EmisionesCO2 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      city: GetCity(props.city),
      parserValue: [],
      parserFormula: [],
      matrixValue: [],
      matrixFormulas: [],
      lineYears: null,

      CO2PrivadosBencineros: null,
      CO2PrivadosDiesel: null,
      CO2PrivadosGas: null,

      CO2BusesBencineros: null,
      CO2BusesDiesel: null,
      CO2BusesGas: null,

      CO2CamionesBencineros: null,
      CO2CamionesDiesel: null,
      CO2CamionesGas: null,

      CO2TaxisBencineros: null,
      CO2TaxisDiesel: null,
      CO2TaxisGas: null,

      CO2Chile: null,
      columns: []
    }
  }

  componentDidMount() {
    this.allRangeEuro()
  }

  allRangeEuro() {


    var workbook
    var CO2PrivadosBencineros = [];
    var CO2PrivadosDiesel = [];
    var CO2PrivadosGas = [];
    var CO2BusesBencineros = [];
    var CO2BusesDiesel = [];
    var CO2BusesGas = [];
    var CO2CamionesBencineros = [];
    var CO2CamionesDiesel = [];
    var CO2CamionesGas = [];
    var CO2TaxisBencineros = [];
    var CO2TaxisDiesel = [];
    var CO2TaxisGas = [];
    var Particulares = [];
    var Buses = [];
    var Camiones = [];
    var Taxis = [];
    var CO2Chile = [];
    var i = 0;
    for (const iterator of this.props.excel) {
      workbook = iterator.Sheets["ETAPA4_BGE"];

      CO2PrivadosBencineros.push(RangeEurosDividedMillion('E570:K590', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO Vehículos Particulares Bencineros Gran Santiago
      CO2PrivadosDiesel.push(RangeEurosDividedMillion('M570:S590', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO Vehículos Particulares Diésel Gran Santiago
      CO2PrivadosGas.push(RangeEurosDividedMillion('U570:U590', ['S/N'], workbook))//Emisiones CO Vehículos Particulares Gas Gran Santiago

      CO2BusesBencineros.push(RangeEurosDividedMillion('Z570:AF590', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO2 Vehículos Particulares Bencineros Gran Santiago
      CO2BusesDiesel.push(RangeEurosDividedMillion('AH570:AN590', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO2 Vehículos Particulares Diésel Gran Santiago
      CO2BusesGas.push(RangeEurosDividedMillion('AQ570:AQ590', ['Total'], workbook))//Emisiones CO2 Vehículos Particulares Gran Santiago Totales

      CO2CamionesBencineros.push(RangeEurosDividedMillion('AU570:BA590', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO2 Vehículos Particulares Bencineros Gran Santiago
      CO2CamionesDiesel.push(RangeEurosDividedMillion('BC570:BI590', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO2 Vehículos Particulares Diésel Gran Santiago
      CO2CamionesGas.push(RangeEurosDividedMillion('BL570:BL590', ['S/N'], workbook))//Emisiones CO2 Vehículos Particulares Gas Gran Santiago

      CO2TaxisBencineros.push(RangeEurosDividedMillion('BP570:BV590', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO2 Vehículos Particulares Bencineros Gran Santiago
      CO2TaxisDiesel.push(RangeEurosDividedMillion('BX570:CD590', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO2 Vehículos Particulares Diésel Gran Santiago
      CO2TaxisGas.push(RangeEurosDividedMillion('CG570:CG590', ['S/N'], workbook))//Emisiones CO2 Vehículos Particulares Gas Gran Santiago

      Particulares.push(RangeEurosDividedMillion('Y570:Y590', ['Vehículos Particulares'], workbook))//Emisiones CO Vehículos Particulares Bencineros Gran Santiago
      Buses.push(RangeEurosDividedMillion('AT570:AT590', ['Buses'], workbook))//Emisiones CO Vehículos Particulares Diésel Gran Santiago
      Camiones.push(RangeEurosDividedMillion('BO570:BO590', ['Camiones'], workbook))//Emisiones CO Vehículos Particulares Gas Gran Santiago
      Taxis.push(RangeEurosDividedMillion('CJ570:CJ590', ['Taxis'], workbook))//Emisiones CO Vehículos Particulares Gran Santiago Totales

      CO2Chile.push([Particulares[i][0], Buses[i][0], Camiones[i][0], Taxis[i][0]])
      i++
    }


    var lineYears = RangeYears('D73:D93', workbook);
    var columns = [text[this.props.lenguage].tools.TYPE, text[this.props.lenguage].tools.years, text[this.props.lenguage].tools.YEmissions]

    this.setState({
      lineYears,

      CO2Chile,
      CO2PrivadosBencineros,
      CO2PrivadosDiesel,
      CO2PrivadosGas,

      CO2BusesBencineros,
      CO2BusesDiesel,
      CO2BusesGas,

      CO2CamionesBencineros,
      CO2CamionesDiesel,
      CO2CamionesGas,

      CO2TaxisBencineros,
      CO2TaxisDiesel,
      CO2TaxisGas,
      columns
    })
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.flagChange == true) {
      if (nextProps.city) {
        var city = GetCity(nextProps.city)
        this.setState({ city }, () => {
        });
      }
      this.allRangeEuro();
    }
  }

  render() {
    const style = {
        
        
        
    };
    const txt = text[this.props.lenguage].graph;
    return (
      <div style={style}>

        <div  >

          <Grid item xs={12}>
            {this.state.CO2PrivadosBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g73 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.CO2PrivadosBencineros} /> : null
            }
            {this.state.CO2PrivadosDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g74 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.CO2PrivadosDiesel} /> : null
            }
            {this.state.CO2PrivadosGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g75 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.CO2PrivadosGas} /> : null
            }
            {this.state.CO2BusesBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g76 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.CO2BusesBencineros} /> : null
            }
            {this.state.CO2BusesDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g77 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.CO2BusesDiesel} /> : null
            }
            {this.state.CO2BusesGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g78 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.CO2BusesGas} /> : null
            }
            {this.state.CO2CamionesBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g79 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.CO2CamionesBencineros} /> : null
            }
            {this.state.CO2CamionesDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g80 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.CO2CamionesDiesel} /> : null
            }
            {this.state.CO2CamionesGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g81 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.CO2CamionesGas} /> : null
            }
            {this.state.CO2TaxisBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g82 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.CO2TaxisBencineros} /> : null
            }
            {this.state.CO2TaxisDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g83 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.CO2TaxisDiesel} /> : null
            }
            {this.state.CO2TaxisGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g84 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.CO2TaxisGas} /> : null
            }

            {this.state.CO2Chile && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g85 + this.state.city}
                yaxis={text[this.props.lenguage].tools.YEmissions} years={this.state.lineYears} lpv={this.state.CO2Chile} /> : null
            }
          </Grid>

          <Grid item xs={12}>
            {this.state.CO2PrivadosBencineros && this.props.visualization === 'table' ?
              <Table text={txt.g73 + this.state.city}
                columns={this.state.columns} lpv={this.state.CO2PrivadosBencineros} /> : null
            }
            {this.state.CO2PrivadosDiesel && this.props.visualization === 'table' ?
              <Table text={txt.g74 + this.state.city}
                columns={this.state.columns} lpv={this.state.CO2PrivadosDiesel} /> : null
            }
            {this.state.CO2PrivadosGas && this.props.visualization === 'table' ?
              <Table text={txt.g75 + this.state.city}
                columns={this.state.columns} lpv={this.state.CO2PrivadosGas} /> : null
            }
            {this.state.CO2BusesBencineros && this.props.visualization === 'table' ?
              <Table text={txt.g76 + this.state.city}
                columns={this.state.columns} lpv={this.state.CO2BusesBencineros} /> : null
            }
            {this.state.CO2BusesDiesel && this.props.visualization === 'table' ?
              <Table text={txt.g77 + this.state.city}
                columns={this.state.columns} lpv={this.state.CO2BusesDiesel} /> : null
            }
            {this.state.CO2BusesGas && this.props.visualization === 'table' ?
              <Table text={txt.g78 + this.state.city}
                columns={this.state.columns} lpv={this.state.CO2BusesGas} /> : null
            }
            {this.state.CO2CamionesBencineros && this.props.visualization === 'table' ?
              <Table text={txt.g79 + this.state.city}
                columns={this.state.columns} lpv={this.state.CO2CamionesBencineros} /> : null
            }
            {this.state.CO2CamionesDiesel && this.props.visualization === 'table' ?
              <Table text={txt.g80 + this.state.city}
                columns={this.state.columns} lpv={this.state.CO2CamionesDiesel} /> : null
            }
            {this.state.CO2CamionesGas && this.props.visualization === 'table' ?
              <Table text={txt.g81 + this.state.city}
                columns={this.state.columns} lpv={this.state.CO2CamionesGas} /> : null
            }
            {this.state.CO2TaxisBencineros && this.props.visualization === 'table' ?
              <Table text={txt.g82 + this.state.city}
                columns={this.state.columns} lpv={this.state.CO2TaxisBencineros} /> : null
            }
            {this.state.CO2TaxisDiesel && this.props.visualization === 'table' ?
              <Table text={txt.g83 + this.state.city}
                columns={this.state.columns} lpv={this.state.CO2TaxisDiesel} /> : null
            }
            {this.state.CO2TaxisGas && this.props.visualization === 'table' ?
              <Table text={txt.g84 + this.state.city}
                columns={this.state.columns} lpv={this.state.CO2TaxisGas} /> : null
            }

            {this.state.CO2Chile && this.props.visualization === 'table' ?
              <Table text={txt.g85 + this.state.city}
                columns={this.state.columns} lpv={this.state.CO2Chile} /> : null
            }
          </Grid>
        </div>
      </div>
    );
  }
}