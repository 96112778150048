import React from 'react';
import Particles from 'react-particles-js';
import ButtonStart from '../Components/ButtonStart';
import text from "../DB/text"

import { connect } from "react-redux";
class Home extends React.Component {

  constructor(props) {
    super(props);
  }
  handleRedirect = () => {
    this.props.history.push('/Introduction')
  
  }


  render() {
    const txt = text[this.props.chooseLenguaje].home;
    return (
      <div style={styles} style={{ backgroundColor: '#062489' }}>
        <div className="welcome-area wow fadeInUp" id="home" style={{
          background: '#062489',
          fontFamily: 'Montserrat',
          fontSize: '14px',
          color: '#cbe5ff',
          overflowX: 'hidden', zIndex: 0
        }}>
          <Particles style={{
            position: 'fixed'
          }}
            params={{
              "particles": {
                "number": {
                  "value": 50
                },
                "size": {
                  "value": 3
                }
              },
              "interactivity": {
                "events": {
                  "onhover": {
                    "enable": true,
                    "mode": "repulse"
                  }
                }
              }
            }}
          />
          <div className="container">
            <div className="row">
              <img style={{ zIndex: 0, position: 'absolute' }} src="img/documentaion.png" alt="" />
              <div style={{ zIndex: 1 }} className="col-12 col-md-6 align-self-center">
                <div className="welcome-right">
                  <div className="welcome-text">

                    <h1 style={{ fontSize: 33 }}>{txt.title} </h1>

                    <div className="row">

                      <div className="col-12 col-md-12" >
                        <ButtonStart txt={txt.start} handleDrawerOpen={() => this.handleRedirect()} />
                      </div>
                    </div>


                    <h4>{txt.subtitle}</h4>
                    <h4>{txt.one}</h4>
                    <h4>{txt.two}</h4>
                    <h4>{txt.three}</h4>
                    <h4>{txt.four}</h4>
                  </div>

                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="welcome-img">
                  <img src="img/data.svg" alt="" />
                </div>
              </div>
            </div>
          </div>


          <div className="distibution-bg">
            <div className="distibution wow fadeInUp" id="token">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <div className="heading">
                      <h5>{txt.token}</h5>
                      <div className="space-10"></div>
                      <h1>{txt.park}</h1>
                    </div>
                    <div className="space-60"></div>
                  </div>
                </div>
                <div className="row">

                  <div className="col-6 text-right">
                    <div className="distibution-svg distibution-svg-1">
                      <img src="img/token-top.png" alt="" />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="distibution-d item-1">
                      <ul>
                        <li className="distibution-list-1">
                          <span>{txt.vp} </span></li>
                        <li className="distibution-list-2">
                          <span>{txt.buses} </span></li>
                        <li className="distibution-list-3">
                          <span>{txt.taxis}</span></li>
                        <li className="distibution-list-4">
                          <span>{txt.camiones} </span> </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="space-90"></div>
                <div className="row">
                  <div className="col-12 text-center">
                    <div className="heading">
                      <h5>{txt.categorizacion}</h5>
                      <div className="space-10"></div>
                      <h1>{txt.emisiones}</h1>
                    </div>
                    <div className="space-90"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2 text-right">
                    <div className="distibution-d distibution-d-2">
                      <ul>
                        <li className="distibution-list-5">
                          <span> {txt.CO2} </span></li>
                        <li className="distibution-list-6">
                          <span>{txt.PM} </span></li>
                        <li className="distibution-list-7">
                          <span>{txt.NOX} </span></li>
                        <li className="distibution-list-8">
                          <span>{txt.CO}</span> </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="distibution-svg distibution-svg-2">
                      <img src="img/token-bottom.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-90"></div>
            </div>
          </div>
        </div>




      </div >
    );
  }
}



const mapStateToProps = (state) => {
  return { chooseLenguaje: state.lenguaje };
};
const mapDispatchToProps = dispatch => {
  return {

  };
};


const AppScreen = connect(mapStateToProps, mapDispatchToProps)(Home);
export default AppScreen;

const styles = {

  h1: {
    color: '#fff',
    fontFamily: 'Montserrat',
    textTransform: 'capitalize'
  },
  h2: {
    color: '#fff',
    fontFamily: 'Montserrat',
    textTransform: 'capitalize'
  },
  h3: {
    color: '#fff',
    fontFamily: 'Montserrat',
    textTransform: 'capitalize'
  },
  h4: {
    color: '#fff',
    fontFamily: 'Montserrat',
    textTransform: 'capitalize'
  },
  h5: {
    color: '#fff',
    fontFamily: 'Montserrat',
    textTransform: 'capitalize'
  },
  h6: {
    color: '#fff',
    fontFamily: 'Montserrat',
    textTransform: 'capitalize'
  },

  ul: {
    margin: 0,
    padding: 0,
    listStyle: 'none'
  },

  textarea: {
    color: '#fff',
    textTransform: 'capitalize'
  }

}