import React from 'react'
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import BarColum from '../TypeGraphic/BarColum';
import text from "../../DB/text";
import { RangeEuros, RangeYears, GetCity } from "../utility/Range";
import Table from '../TypeGraphic/Table';

export default class BaseDistance extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      city: GetCity(props.city),
      lineYears: null,
      distanceAll: null,
      accumulatedDistanceAll: null,
      columnsAll: []
    }
  }


  componentDidMount() {
    this.allRangeEuro()
  }

  allRangeEuro() {
    var workbook
    var distanceAll = [];
    var accumulatedDistanceAll = [];
    for (const iterator of this.props.excel) {
      workbook = iterator.Sheets["ETAPA3_BGE"];
      distanceAll.push(RangeEuros('E15:H54', text[this.props.lenguage].tools.vehiclesArray, workbook))
      accumulatedDistanceAll.push(RangeEuros('I15:L54', text[this.props.lenguage].tools.vehiclesArray, workbook))
    }
    var lineYears = RangeYears('D15:D54', workbook)
    var columns = [text[this.props.lenguage].tools.TYPE, text[this.props.lenguage].tools.years, text[this.props.lenguage].tools.YK]
    var columns2 = [text[this.props.lenguage].tools.TYPE, text[this.props.lenguage].tools.years, text[this.props.lenguage].tools.YKA]

    var columnsAll = []
    columnsAll.push(columns)
    columnsAll.push(columns2)
    this.setState({
      lineYears,
      distanceAll,
      accumulatedDistanceAll,
      columns,
      columnsAll
    })
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.flagChange == true) {
      if (nextProps.city) {
        var city = GetCity(nextProps.city)
        this.setState({ city }, () => {
        });
      }
      this.allRangeEuro();
    }
  }
  render() {
    const style = {



    };

    return (
      <div style={style}>
        <div  >
          <Grid item xs={12}>
            {this.state.distanceAll && this.props.visualization === 'graph' ? <BarColum scena={text[this.props.lenguage].tools} yaxis={text[this.props.lenguage].tools.YK} text={text[this.props.lenguage].graph.g50 + this.state.city} years={this.state.lineYears} lpv={this.state.distanceAll} /> : null}
            {this.state.accumulatedDistanceAll && this.props.visualization === 'graph' ? <BarColum scena={text[this.props.lenguage].tools} yaxis={text[this.props.lenguage].tools.YKA} text={text[this.props.lenguage].graph.g51 + this.state.city} years={this.state.lineYears} lpv={this.state.accumulatedDistanceAll} /> : null}
          </Grid>
          {this.state.distanceAll && this.props.visualization === 'table' ? <Table columns={this.state.columnsAll[0]} text={text[this.props.lenguage].graph.g50 + this.state.city} lpv={this.state.distanceAll} /> : null}
          {this.state.accumulatedDistanceAll && this.props.visualization === 'table' ? <Table columns={this.state.columnsAll[1]} text={text[this.props.lenguage].graph.g51 + this.state.city} lpv={this.state.accumulatedDistanceAll} /> : null}

        </div>
      </div>
    );
  }
}




