import XLSX from '@sheet/core';

const RangeEuros = (range, title, workbook) => {
  var resultObj = XLSX.utils.sheet_to_json(workbook, { range, header: 1, raw: true });

  var euroArray = [];
  var arraySection = []
  
  for (let x = 0; x < title.length; x++) {
    arraySection.push([])
  }
  for (let i = 0; i < resultObj.length; i++) {
    for (let x = 0; x < title.length; x++) {
      var parseResult = resultObj[i][x]
      arraySection[x].push(parseResult)
    }
  }
  for (let x = 0; x < title.length; x++) {
    euroArray.push({ data: arraySection[x], name: title[x] })
  }
  return euroArray
}


const RangeEurosDividedMillion = (range, title, workbook) => {
  var resultObj = XLSX.utils.sheet_to_json(workbook, { range, header: 1, raw: true });

  var euroArray = [];
  var arraySection = []
  for (let x = 0; x < title.length; x++) {
    arraySection.push([])
  }
  for (let i = 0; i < resultObj.length; i++) {
    for (let x = 0; x < title.length; x++) {
      var parseResult = resultObj[i][x] / 1000000
      arraySection[x].push(parseResult)
    }
  }
  for (let x = 0; x < title.length; x++) {
    euroArray.push({ data: arraySection[x], name: title[x] })
  }
  return euroArray
}

const RangeYears = (range, workbook) => {

  var lineAllYears = XLSX.utils.sheet_to_json(workbook, { range, header: 1 });
  var lineYearsResult = []
  for (let i = 0; i < lineAllYears.length; i++) {
    lineYearsResult.push(lineAllYears[i][0]);
  }
  var lineYears = lineYearsResult
  return lineYears

}
const GetCity = city => {

  if (city == 7) {
    return 'Chile'
  } else if (city == 6) {
    return 'Valdivia'
  } else if (city == 5) {
    return 'Temuco'
  } else if (city == 4) {
    return 'Concepción'
  } else if (city == 3) {
    return 'La Serena'
  } else if (city == 2) {
    return 'Copiapó'
  } else if (city == 1) {
    return 'Santiago'
  }
}



export { RangeEuros, RangeYears, GetCity, RangeEurosDividedMillion }