import React from 'react'
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import StackedColum from '../TypeGraphic/StackedColum';
import text from '../../DB/text';
import { RangeEurosDividedMillion, RangeYears, GetCity } from "../utility/Range"
import Table from '../TypeGraphic/Table'

export default class EmisionesPM25 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      city: GetCity(props.city),
      lineYears: null,

      PMPrivadosBencineros: null,
      PMPrivadosDiesel: null,
      PMPrivadosGas: null,
      PMPrivadosTotales: null,
      PMTotales: null,
      PMBusesBencineros: null,
      PMBusesDiesel: null,
      PMBusesGas: null,
      PMBusesTotales: null,
      PMCamionesBencineros: null,
      PMCamionesDiesel: null,
      PMCamionesGas: null,
      PMCamionesTotales: null,
      PMTaxisBencineros: null,
      PMTaxisDiesel: null,
      PMTaxisGas: null,
      PMTaxisTotales: null,
      columns: []
    }
  }



  componentDidMount() {
    this.allRangeEuro();
  }

  allRangeEuro() {


    var workbook
    var PMPrivadosBencineros = [];
    var PMPrivadosDiesel = [];
    var PMPrivadosGas = [];
    var PMPrivadosTotales = [];
    var PMTotales = [];
    var PMBusesBencineros = [];
    var PMBusesDiesel = [];
    var PMBusesGas = [];
    var PMBusesTotales = [];
    var PMCamionesBencineros = [];
    var PMCamionesDiesel = [];
    var PMCamionesGas = [];
    var PMCamionesTotales = [];
    var PMTaxisBencineros = [];
    var PMTaxisDiesel = [];
    var PMTaxisGas = [];
    var PMTaxisTotales = [];


    for (const iterator of this.props.excel) {
      workbook = iterator.Sheets["ETAPA4_BGE"];
      PMPrivadosBencineros.push(RangeEurosDividedMillion('E306:K326', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones PM Vehículos Particulares Bencineros Gran Santiago
      PMPrivadosDiesel.push(RangeEurosDividedMillion('M306:S326', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones PM Vehículos Particulares Diésel Gran Santiago
      PMPrivadosGas.push(RangeEurosDividedMillion('U306:U326', ['S/N'], workbook))//Emisiones PM Vehículos Particulares Gas Gran Santiago
      PMPrivadosTotales.push(RangeEurosDividedMillion('Y306:Y326', ['Total'], workbook))//Emisiones PM Vehículos Particulares Gran Santiago Totales
      PMTotales.push(RangeEurosDividedMillion('CK306:CK326', ['Total'], workbook)) //Emisiones PM Gran Santiago Totales


      PMBusesBencineros.push(RangeEurosDividedMillion('Z306:AF326', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones PM Vehículos Particulares Bencineros Gran Santiago
      PMBusesDiesel.push(RangeEurosDividedMillion('AH306:AN326', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones PM Vehículos Particulares Diésel Gran Santiago
      PMBusesGas.push(RangeEurosDividedMillion('AQ306:AQ326', ['Total'], workbook))//Emisiones PM Vehículos Particulares Gran Santiago Totales
      PMBusesTotales.push(RangeEurosDividedMillion('AT306:AT326', ['Total'], workbook)) //Emisiones PM Gran Santiago Totales


      PMCamionesBencineros.push(RangeEurosDividedMillion('AU306:BA326', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones PM Vehículos Particulares Bencineros Gran Santiago
      PMCamionesDiesel.push(RangeEurosDividedMillion('BC306:BI326', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones PM Vehículos Particulares Diésel Gran Santiago
      PMCamionesGas.push(RangeEurosDividedMillion('BL306:BL326', ['S/N'], workbook))//Emisiones PM Vehículos Particulares Gas Gran Santiago
      PMCamionesTotales.push(RangeEurosDividedMillion('BO306:BO326', ['Total'], workbook))//Emisiones PM Vehículos Particulares Gran Santiago Totales


      PMTaxisBencineros.push(RangeEurosDividedMillion('BP306:BV326', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones PM Vehículos Particulares Bencineros Gran Santiago
      PMTaxisDiesel.push(RangeEurosDividedMillion('BX306:CD326', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones PM Vehículos Particulares Diésel Gran Santiago
      PMTaxisGas.push(RangeEurosDividedMillion('CG306:CG326', ['S/N'], workbook))//Emisiones PM Vehículos Particulares Gas Gran Santiago
      PMTaxisTotales.push(RangeEurosDividedMillion('CJ306:CJ326', ['Total'], workbook))//Emisiones CO Vehículos Particulares Gran Santiago Totales
    }

    var lineYears = RangeYears('D306:D326', workbook);
    var columns = [text[this.props.lenguage].tools.TEURO, text[this.props.lenguage].tools.years, text[this.props.lenguage].tools.YEmissions]

    this.setState({
      lineYears,
      PMPrivadosBencineros,
      PMPrivadosDiesel,
      PMPrivadosGas,
      PMPrivadosTotales,
      PMTotales,
      PMBusesBencineros,
      PMBusesDiesel,
      PMBusesGas,
      PMBusesTotales,
      PMCamionesBencineros,
      PMCamionesDiesel,
      PMCamionesGas,
      PMCamionesTotales,
      PMTaxisBencineros,
      PMTaxisDiesel,
      PMTaxisGas,
      PMTaxisTotales,
      columns
    })
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.flagChange == true) {
      if (nextProps.city) {
        var city = GetCity(nextProps.city)
        this.setState({ city }, () => {
        });
      }
      this.allRangeEuro();
    }
  }
  render() {
    const style = {
        
        
        
    };
    const txt = text[this.props.lenguage].graph;
    return (
      <div style={style}>

        <div  >
          <Grid item xs={12}>

            {this.state.PMPrivadosBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g103 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMPrivadosBencineros} /> : null
            }

            {this.state.PMPrivadosDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g104 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMPrivadosDiesel} /> : null
            }
            {this.state.PMPrivadosGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g105 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMPrivadosGas} /> : null
            }
            {this.state.PMPrivadosTotales && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g106 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMPrivadosTotales} /> : null
            }
            {this.state.PMTotales && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g107 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMTotales} /> : null
            }


            {this.state.PMBusesBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g108 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMBusesBencineros} /> : null
            }

            {this.state.PMBusesDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g109 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMBusesDiesel} /> : null
            }
            {this.state.PMBusesGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g110 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMBusesGas} /> : null
            }
            {this.state.PMBusesTotales && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g111 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMBusesTotales} /> : null
            }


            {this.state.PMCamionesBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g112 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMCamionesBencineros} /> : null
            }

            {this.state.PMCamionesDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g113 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMCamionesDiesel} /> : null
            }
            {this.state.PMCamionesGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g114 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMCamionesGas} /> : null
            }
            {this.state.PMCamionesTotales && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g115 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMCamionesTotales} /> : null
            }


            {this.state.PMTaxisBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g116 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMTaxisBencineros} /> : null
            }

            {this.state.PMTaxisDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g117 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMTaxisDiesel} /> : null
            }
            {this.state.PMTaxisGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g118 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMTaxisGas} /> : null
            }
            {this.state.PMTaxisTotales && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g119 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.PMTaxisTotales} /> : null
            }

          </Grid>


          <Grid item xs={12}>

            {this.state.PMPrivadosBencineros && this.props.visualization === 'table' ?
              <Table text={txt.g103 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMPrivadosBencineros} /> : null
            }

            {this.state.PMPrivadosDiesel && this.props.visualization === 'table' ?
              <Table text={txt.g104 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMPrivadosDiesel} /> : null
            }
            {this.state.PMPrivadosGas && this.props.visualization === 'table' ?
              <Table text={txt.g105 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMPrivadosGas} /> : null
            }
            {this.state.PMPrivadosTotales && this.props.visualization === 'table' ?
              <Table text={txt.g106 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMPrivadosTotales} /> : null
            }
            {this.state.PMTotales && this.props.visualization === 'table' ?
              <Table text={txt.g107 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMTotales} /> : null
            }


            {this.state.PMBusesBencineros && this.props.visualization === 'table' ?
              <Table text={txt.g108 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMBusesBencineros} /> : null
            }

            {this.state.PMBusesDiesel && this.props.visualization === 'table' ?
              <Table text={txt.g109 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMBusesDiesel} /> : null
            }
            {this.state.PMBusesGas && this.props.visualization === 'table' ?
              <Table text={txt.g110 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMBusesGas} /> : null
            }
            {this.state.PMBusesTotales && this.props.visualization === 'table' ?
              <Table text={txt.g111 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMBusesTotales} /> : null
            }


            {this.state.PMCamionesBencineros && this.props.visualization === 'table' ?
              <Table text={txt.g112 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMCamionesBencineros} /> : null
            }

            {this.state.PMCamionesDiesel && this.props.visualization === 'table' ?
              <Table text={txt.g113 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMCamionesDiesel} /> : null
            }
            {this.state.PMCamionesGas && this.props.visualization === 'table' ?
              <Table text={txt.g114 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMCamionesGas} /> : null
            }
            {this.state.PMCamionesTotales && this.props.visualization === 'table' ?
              <Table text={txt.g115 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMCamionesTotales} /> : null
            }


            {this.state.PMTaxisBencineros && this.props.visualization === 'table' ?
              <Table text={txt.g116 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMTaxisBencineros} /> : null
            }

            {this.state.PMTaxisDiesel && this.props.visualization === 'table' ?
              <Table text={txt.g117 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMTaxisDiesel} /> : null
            }
            {this.state.PMTaxisGas && this.props.visualization === 'table' ?
              <Table text={txt.g118 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMTaxisGas} /> : null
            }
            {this.state.PMTaxisTotales && this.props.visualization === 'table' ?
              <Table text={txt.g119 + this.state.city}
                columns={this.state.columns} lpv={this.state.PMTaxisTotales} /> : null
            }

          </Grid>
        </div>
      </div>
    );
  }
}



