
import React from 'react';
import Emisiones from '../Components/SteepFour/Emisiones';
import EmisionesNox from '../Components/SteepFour/EmisionesNox';
import EmisionesPM25 from '../Components/SteepFour/EmisionesPM25';
import EmisionesCONOxPM from '../Components/SteepFour/EmisionesCONOxPM';
import ResumenEmisionesCO2 from '../Components/SteepFour/ResumenEmisionesCO2';
import EmisionesCO2 from '../Components/SteepFour/EmisionesCO2';
import Introduction from '../Components/Introduction';
import City from '../Components/Tools/City';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import text from "../DB/text"
import routes from "../routes.js";
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import OptionsView from '../Components/Tools/OptionsView';

import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OneElectric from '../Components/Tools/OneElectric';
import ToolsStepFourOne from '../Components/Tools/ToolsStepFourOne';
import FunctionSupervival from '../Components/Tools/FunctionSupervival';
import TechComposition from '../Components/Tools/TechComposition';
import ParkAgeTools from '../Components/Tools/ParkAgeTools';
import VariationDistances from '../Components/Tools/VariationDistances';
import Button from '@material-ui/core/Button';
import LinearDeterminate from "../Components/utility/LinearDeterminate"


import {
  StepOneHandleByFour,
  CityChange,
  StepOneElectricPrivate,
  StepOneElectricTaxi,
  StepOneElectricBus,
  StepOneElectricCamion,
  StepTwoSurvival,
  StepTwoHandlebyFour,
  StepTwoPark,
  StepThreeVD,
  StepThreeVK,
} from "../Components/utility/SetInputs"
import { connect } from "react-redux";
import XLSX from '@sheet/core';
import S5SCalc from '@sheet/formula';
import { excel, city } from '../actionCreators';
S5SCalc.XLSXLib = XLSX; // <-- make the connection


class StepFour extends React.Component {
  constructor(props) {
    super(props);
    var Inputs = this.setDefaultValues(props.excel);

    this.state = {
      tab: 0,
      visualization: "graph",
      expanded: null,
      loading: false,
      Inputs
    }


  }

  setDefaultValues = (excel) => {

    var Inputs = [];


    for (let index = 0; index < excel.length; index++) {

      var valDefaul1 = excel[index].Sheets.ETAPA1_BGE
      var valDefaul2 = excel[index].Sheets.ETAPA2_BGE
      var valDefaul3 = excel[index].Sheets.ETAPA3_BGE

      var objAuxInput = {
        index: index,
        growthPopulation: valDefaul1.D155.v * 100,
        growthGdp: valDefaul1.D156.v * 100,
        input: valDefaul1.J16.v * 100,
        variationTaxi: valDefaul1.D215.v * 100,
        variationBus: valDefaul1.D216.v * 100,
        variationCamion: valDefaul1.D217.v * 100,
        lvSaturation: valDefaul1.D425.v,
        lvSaturationElectric: valDefaul1.D518.v,
        electricValues: {
          deltaC: valDefaul1.D520.v,
          p: valDefaul1.D521.v,
          q: valDefaul1.D522.v,
          t0: valDefaul1.D523.v,
          deltaP: valDefaul1.D524.v,
          l: valDefaul1.G520.v,
          k: valDefaul1.G521.v,
          t02: valDefaul1.G522.v,
          fcv: valDefaul1.J520.v,
          phev: valDefaul1.J521.v,
          bev: valDefaul1.J522.v,
        },
        electricValuesTaxis: {
          deltaC: valDefaul1.D656.v,
          p: valDefaul1.D657.v,
          q: valDefaul1.D658.v,
          t0: valDefaul1.D659.v,
          deltaP: valDefaul1.D660.v,
          l: valDefaul1.G656.v,
          k: valDefaul1.G657.v,
          t02: valDefaul1.G658.v,
          fcv: valDefaul1.J656.v,
          phev: valDefaul1.J657.v,
          bev: valDefaul1.J668.v,
        },
        electricValuesBuses: {
          deltaC: valDefaul1.D793.v,
          p: valDefaul1.D794.v,
          q: valDefaul1.D795.v,
          t0: valDefaul1.D796.v,
          deltaP: valDefaul1.D797.v,
          l: valDefaul1.G793.v,
          k: valDefaul1.G794.v,
          t02: valDefaul1.G795.v,
          fcv: valDefaul1.J793.v,
          phev: valDefaul1.J794.v,
          bev: valDefaul1.J795.v,
        },
        electricValuesCamiones: {
          deltaC: valDefaul1.D927.v,
          p: valDefaul1.D928.v,
          q: valDefaul1.D929.v,
          t0: valDefaul1.D230.v,
          deltaP: valDefaul1.D231.v,
          l: valDefaul1.G927.v,
          k: valDefaul1.G928.v,
          t02: valDefaul1.G929.v,
          fcv: valDefaul1.J927.v,
          phev: valDefaul1.J928.v,
          bev: valDefaul1.J929.v,
        },
        fsValues: {
          Avp: valDefaul2.D273.v,
          alfaTaxis: valDefaul2.G273.v,
          alfaBuses: valDefaul2.E273.v,
          alfaCamiones: valDefaul2.F273.v,
          betaVP: valDefaul2.D274.v,
          betaTaxis: valDefaul2.G274.v,
          betaBuses: valDefaul2.E274.v,
          betaCamiones: valDefaul2.F274.v,
          GVP: valDefaul2.D275.v,
          GTaxis: valDefaul2.G275.v,
          GBuses: valDefaul2.E275.v,
          GCamiones: valDefaul2.F275.v,
        },
        pkValues: {
          vp: 1.618,
          taxis: 1.618,
          buses: 1.618,
          camiones: 1.618,
          Bvp: -0.141,
          BTaxis: -0.188,
          BBuses: -0.161,
          BCamiones: -0.47,
        },
        taxis: valDefaul2.D463.v,
        camiones: valDefaul2.D413.v,
        buses: valDefaul2.D365.v,
        tcVehicle: valDefaul2.D317.v,
        vdValues: {
          autos: valDefaul3.D170.v * 100,
          buses: valDefaul3.E170.v * 100,
          camiones: valDefaul3.F170.v * 100,
          taxis: valDefaul3.G170.v * 100
        },
        vkValues: {
          autos: valDefaul3.D255.v * 100,
          buses: valDefaul3.E255.v * 100,
          camiones: valDefaul3.F255.v * 100,
          taxis: valDefaul3.G255.v * 100
        }
      }


      Inputs.push(JSON.parse(JSON.stringify(objAuxInput)));
    }

    return Inputs
  }


  handleChangePanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChange = index => event => {

    if (event.target.name === 'city') {
      var value = event.target.value;
      var valueInt = parseInt(value);
      this.setState({
        loading: true
      }, () => {
        setTimeout(() => {

          var workbook = this.props.excel[0];

          var prom = CityChange(event.target.value, workbook);
          prom.then((workbook) => {
            this.props.changeCity({ city: valueInt });
            var newWorkbook = []

            newWorkbook.push(JSON.parse(JSON.stringify(workbook)));
            newWorkbook.push(JSON.parse(JSON.stringify(workbook)));

            this.props.changeExcel({ workbook: newWorkbook })
            var newValuesDefault = this.setDefaultValues(newWorkbook)
            this.setState({
              Inputs: newValuesDefault
            });
            this.setState({ [event.target.name]: event.target.value });
            this.setState({ flagChange: true, loading: false }, () => {
              this.setState({ flagChange: false }, () => {
              })
            })
          }).catch(function (error) {
            // ops, mom don't buy it
            console.log(error.message);
          });
        }, 1000);
      })
    }

    this.setState(prevState => ({
      Inputs: prevState.Inputs.map(
        obj => (obj.index === index ? Object.assign(obj, { [event.target.name]: event.target.value }) : obj)
      )
    }));
  };

  handleChangeTabs = (event, tab) => {
    this.setState({ tab });
  };

  handleDirection = (direction) => {
    var position = routes.listRoutes.indexOf(this.props.match.url)
    if (direction == 'next') {
      this.props.history.push(routes.listRoutes[position + 1])
    } else {
      this.props.history.push(routes.listRoutes[position - 1])
    }
  }

  handleInputChange = index => event => {
    var nowState = this.state.Inputs
    nowState[index].electricValues[event.target.name] = event.target.value
    this.setState({
      Inputs: nowState
    });
  }
  handleInputChangeTaxis = index => event => {
    var nowState = this.state.Inputs
    nowState[index].electricValuesTaxis[event.target.name] = event.target.value
    this.setState({
      Inputs: nowState
    });
  }
  handleInputChangeBuses = index => event => {
    var nowState = this.state.Inputs
    nowState[index].electricValuesBuses[event.target.name] = event.target.value
    this.setState({
      Inputs: nowState
    });
  }
  handleInputChangeCamiones = index => event => {
    var nowState = this.state.Inputs
    nowState[index].electricValuesCamiones[event.target.name] = event.target.value
    this.setState({
      Inputs: nowState
    });
  }
  handleInputChangeFs = index => event => {
    var nowState = this.state.Inputs
    nowState[index].fsValues[event.target.name] = event.target.value
    this.setState({
      Inputs: nowState
    });
  }
  handleInputChangePK = index => event => {
    var nowState = this.state.Inputs
    nowState[index].pkValues[event.target.name] = event.target.value
    this.setState({
      Inputs: nowState
    });
  }
  handleInputChangeVd = index => event => {
    var nowState = this.state.Inputs
    nowState[index].vdValues[event.target.name] = event.target.value
    this.setState({
      Inputs: nowState
    });
  }
  handleInputChangeVk = index => event => {
    var nowState = this.state.Inputs
    nowState[index].vkValues[event.target.name] = event.target.value
    this.setState({
      Inputs: nowState
    });
  }


  sendOneOne = (index) => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => {
        var val = {
          gp: this.state.Inputs[index].growthPopulation,
          gdp: this.state.Inputs[index].growthGdp,
          inp: this.state.Inputs[index].input,
          vt: this.state.Inputs[index].variationTaxi,
          vb: this.state.Inputs[index].variationBus,
          vc: this.state.Inputs[index].variationCamion,
          ls: this.state.Inputs[index].lvSaturation
        }
        console.log('this.state.Inputs 2', this.state.Inputs);
        var workbook = this.props.excel[index];
        workbook = StepOneHandleByFour(val, workbook);
        var sendWorkbook = this.props.excel;
        sendWorkbook[index] = workbook
        this.props.changeExcel({ workbook: sendWorkbook })
        this.setState({ flagChange: true, loading: false }, () => {
          this.setState({ flagChange: false }, () => {
          })
        })
      }, 1000);
    })

  }
  sendOneTwo = (index) => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => {
        var workbook = this.props.excel[index];

        workbook = StepOneElectricPrivate(this.state.Inputs[index].electricValues, workbook);
        var sendWorkbook = this.props.excel;
        sendWorkbook[index] = workbook
        this.props.changeExcel({ workbook: sendWorkbook })
        this.setState({ flagChange: true, loading: false }, () => {
          this.setState({ flagChange: false }, () => {
          })
        })
      }, 1000);
    })
  }
  sendOneThree = (index) => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => {
        var workbook = this.props.excel[index];
        workbook = StepOneElectricTaxi(this.state.Inputs[index].electricValuesTaxis, workbook);
        var sendWorkbook = this.props.excel;
        sendWorkbook[index] = workbook
        this.props.changeExcel({ workbook: sendWorkbook })
        this.setState({ flagChange: true, loading: false }, () => {
          this.setState({ flagChange: false }, () => {
          })
        })
      }, 1000);
    })
  }
  sendOneFour = (index) => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => {
        var workbook = this.props.excel[index];
        workbook = StepOneElectricBus(this.state.Inputs[index].electricValuesBus, workbook);
        var sendWorkbook = this.props.excel;
        sendWorkbook[index] = workbook
        this.props.changeExcel({ workbook: sendWorkbook })
        this.setState({ flagChange: true, loading: false }, () => {
          this.setState({ flagChange: false }, () => {
          })
        })
      }, 1000);
    })
  }
  sendOneFive = (index) => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => {
        var workbook = this.props.excel[index];
        workbook = StepOneElectricCamion(this.state.Inputs[index].electricValuesCamiones, workbook);
        var sendWorkbook = this.props.excel;
        sendWorkbook[index] = workbook
        this.props.changeExcel({ workbook: sendWorkbook })
        this.setState({ flagChange: true, loading: false }, () => {
          this.setState({ flagChange: false }, () => {
          })
        })
      }, 1000);
    })
  }
  sendTwoFs = (index) => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => {
        var workbook = this.props.excel[index];
        workbook = StepTwoSurvival(this.state.Inputs[index].fsValues, workbook);
        if (workbook) {
          var sendWorkbook = this.props.excel;
          sendWorkbook[index] = workbook
          this.props.changeExcel({ workbook: sendWorkbook })
          this.setState({ flagChange: true, loading: false }, () => {
            this.setState({ flagChange: false }, () => {
            })
          })
        }
      }, 1000);
    })
  }
  sendTwoTc = (index) => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => {
        var workbook = this.props.excel[index];
        var obgTC = {
          tcVehicle: this.sexpandIcontate.Inputs[index].tcVehicle,
          buses: this.state.Inputs[index].buses,
          camiones: this.state.Inputs[index].camiones,
          taxis: this.state.Inputs[index].taxis,
        }
        workbook = StepTwoHandlebyFour(obgTC, workbook);
        var sendWorkbook = this.props.excel;
        sendWorkbook[index] = workbook
        this.props.changeExcel({ workbook: sendWorkbook })
        this.setState({ flagChange: true, loading: false }, () => {
          this.setState({ flagChange: false }, () => {
          })
        })

      }, 1000);
    })
  }
  sendTwoPk = (index) => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => {
        var workbook = this.props.excel[index];
        workbook = StepTwoPark(this.state.Inputs[index].pkValues, workbook);
        var sendWorkbook = this.props.excel;
        sendWorkbook[index] = workbook
        this.props.changeExcel({ workbook: sendWorkbook })
        this.setState({ flagChange: true, loading: false }, () => {
          this.setState({ flagChange: false }, () => {
          })
        })
      }, 1000);
    })
  }
  sendThreeVd = (index) => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => {
        var workbook = this.props.excel[index];
        workbook = StepThreeVD(this.state.Inputs[index].vdValues, workbook);
        var sendWorkbook = this.props.excel;
        sendWorkbook[index] = workbook
        this.props.changeExcel({ workbook: sendWorkbook })
        this.setState({ flagChange: true, loading: false }, () => {
          this.setState({ flagChange: false }, () => {
          })
        })
      }, 1000);
    })
  }
  sendThreeVk = (index) => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => {
        var workbook = this.props.excel[index];
        workbook = StepThreeVK(this.state.Inputs[index].vkValues, workbook);
        var sendWorkbook = this.props.excel;
        sendWorkbook[index] = workbook
        this.props.changeExcel({ workbook: sendWorkbook })
        this.setState({ flagChange: true, loading: false }, () => {
          this.setState({ flagChange: false }, () => {
          })
        })
      }, 1000);
    })
  }
  handleVizualization = event => {
    this.setState({ visualization: event.target.value });
  };

  render() {
    const { expanded } = this.state;
    const txt = text[this.props.lenguaje];
    return (

      <div style={{ paddingTop: '24px', paddingLeft: '64px', paddingRight: '64px', }}>


        {this.state.loading === true &&
          <div style={{ marginTop: '5%', zIndex: 9999, }}>
            <LinearDeterminate /> </div>}

        {this.state.loading === false && <div>
          <div style={{ marginTop: '5%' }}>


            <div style={{ position: 'fixed', bottom: '40%', left: 8 }}>
              <IconButton style={{ padding: '5px', background: '#dadada4d' }} onClick={() => this.handleDirection('before')} name="before">
                <ChevronLeftIcon style={{ height: 35, width: 35 }} />
              </IconButton>
            </div>
            {this.props.match.params.id !== 'emisionescotwo' &&
              <div style={{ position: 'fixed', bottom: '40%', right: '7px', float: 'right' }}>
                <IconButton style={{ padding: '5px', background: '#dadada4d' }} onClick={() => this.handleDirection('next')} name="before">
                  <ChevronRightIcon style={{ height: 35, width: 35 }} />
                </IconButton>
              </div>
            }


            {this.props.match.params.id === 'factoremisiones' ?
              <div>
                <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.tab}
                    onChange={this.handleChangeTabs}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label={txt.tools.intro} />
                    <Tab label={txt.tools.config1} />
                    <Tab label={txt.tools.config2} />
                    <Tab label={txt.tools.config3} />
                    <Tab label={txt.tools.herra} />
                    <Tab label={txt.tools.cambCiudad} />
                    <Tab label={txt.tools.info} />
                  </Tabs>
                </AppBar>
                {
                  this.state.tab === 0 || this.state.tab === 6 ? <div className={"card-panel"} style={{ width: "100%" }}>
                    {this.state.tab === 0 && <Introduction title={txt.stepFour.ep.title} text={txt.stepFour.ep.description} />}
                    {this.state.tab === 6 && <Introduction title={txt.stepFour.fe.title} text={txt.stepFour.fe.description} />}
                  </div> : null
                }
              </div> : null
            }

            {this.props.match.params.id != 'factoremisiones' ?
              <div>
                <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.tab}
                    onChange={this.handleChangeTabs}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label={txt.tools.info} />
                    <Tab label={txt.tools.config1} />
                    <Tab label={txt.tools.config2} />
                    <Tab label={txt.tools.config3} />
                    <Tab label={txt.tools.herra} />
                    <Tab label={txt.tools.cambCiudad} />
                  </Tabs>
                </AppBar>
                {

                  this.state.tab === 0 ? <div className={"card-panel"} style={{ width: "100%" }}>

                    {this.state.tab === 0 &&
                      <div>
                        {this.props.match.params.id === 'emisionesnox' &&
                          <Introduction title={txt.stepFour.en.title} text={txt.stepFour.en.description} />
                        }
                        {this.props.match.params.id === 'emisionespm' &&
                          <Introduction title={txt.stepFour.epm.title} text={txt.stepFour.epm.description} />
                        }
                        {this.props.match.params.id === 'emisionesconoxpm' &&
                          <Introduction title={txt.stepFour.recnpm.title} text={txt.stepFour.recnpm.description} />
                        }
                        {this.props.match.params.id === 'resumenemisiones' &&
                          <Introduction title={txt.stepFour.eco.title} text={txt.stepFour.eco.description} />
                        }
                        {this.props.match.params.id === 'emisionescotwo' &&
                          <Introduction title={txt.stepFour.reco.title} text={txt.stepFour.reco.description} />
                        }
                      </div>
                    }

                  </div>
                    : null}
              </div> : null
            }


            {this.state.tab === 1 &&

              <div style={{ width: '100%', marginTop: '1%' }}>
                <ExpansionPanel expanded={expanded === 'panel1'} onChange={this.handleChangePanel('panel1')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={{ fontSize: 15, color: 'black' }}>{txt.tools.config101}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails >
                    <div className="row" style={{ boxSizing: 'inherit' }} >
                      {
                        this.props.excel.map((label, index) => {

                          return (
                            <div >
                              {index == 0 && <span style={{ color: '#757575' }}>{txt.tools.base}</span>}
                              {index == 1 && <span style={{ color: '#757575' }}>{txt.tools.comparative}</span>}
                              <ToolsStepFourOne
                                key={index}
                                index={index}
                                input={this.state.Inputs[index].input}
                                lvSaturation={this.state.Inputs[index].lvSaturation}
                                variationTaxi={this.state.Inputs[index].variationTaxi}
                                variationBus={this.state.Inputs[index].variationBus}
                                variationCamion={this.state.Inputs[index].variationCamion}
                                growthPopulation={this.state.Inputs[index].growthPopulation}
                                handleChange={this.handleChange}
                                growthGdp={this.state.Inputs[index].growthGdp}
                                handleSendInfo={this.sendOneOne}
                                txt={txt.tools}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expanded === 'panel2'} onChange={this.handleChangePanel('panel2')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={{ fontSize: 15, color: 'black' }}>
                      {txt.tools.config102}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="row" style={{ boxSizing: 'inherit' }} >

                      {
                        this.props.excel.map((label, index) => {

                          console.log('this.state.Inputs[index].electricValuesPrivate', this);
                          return (
                            <div>
                              {index == 0 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.base}</span>}
                              {index == 1 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.comparative}</span>}

                              <OneElectric
                                handleInputChange={this.handleInputChange}
                                electricValues={this.state.Inputs[index].electricValues}
                                handleSendInfo={this.handleSendInfo}
                                type={'private'}
                                index={index}
                                txt={txt.tools}
                              />
                            </div>
                          );
                        })}
                    </div>

                  </ExpansionPanelDetails>
                </ExpansionPanel>


                <ExpansionPanel expanded={expanded === 'panel3'} onChange={this.handleChangePanel('panel3')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={{ fontSize: 15, color: 'black' }}>
                      {txt.graph.g16}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="row" style={{ boxSizing: 'inherit' }} >
                      {
                        this.props.excel.map((label, index) => {
                          return (
                            <div>
                              {index == 0 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.base}</span>}
                              {index == 1 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.comparative}</span>}

                              <OneElectric
                                handleInputChange={this.handleInputChangeTaxis}
                                electricValues={this.state.Inputs[index].electricValuesTaxis}
                                handleSendInfo={this.sendOneTwo}
                                key={index}
                                index={index}
                                txt={txt.tools}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'panel4'} onChange={this.handleChangePanel('panel4')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={{ fontSize: 15, color: 'black' }}>
                      {txt.graph.g20}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="row" style={{ boxSizing: 'inherit' }} >
                      {
                        this.props.excel.map((label, index) => {
                          return (
                            <div>
                              {index == 0 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.base}</span>}
                              {index == 1 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.comparative}</span>}

                              <OneElectric
                                handleInputChange={this.handleInputChangeBuses}
                                electricValues={this.state.Inputs[index].electricValuesBuses}
                                handleSendInfo={this.sendOneThree}
                                key={index}
                                index={index}
                                txt={txt.tools}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'panel5'} onChange={this.handleChangePanel('panel5')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={{ fontSize: 15, color: 'black' }}>
                      {txt.graph.g24}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="row" style={{ boxSizing: 'inherit' }} >
                      {
                        this.props.excel.map((label, index) => {
                          return (
                            <div>
                              {index == 0 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.base}</span>}
                              {index == 1 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.comparative}</span>}

                              <OneElectric
                                handleInputChange={this.handleInputChangeCamiones}
                                electricValues={this.state.Inputs[index].electricValuesCamiones}
                                handleSendInfo={this.sendOneThree}
                                key={index}
                                index={index}
                                txt={txt.tools} />

                              />
                            </div>
                          );
                        })}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>


            }
            {this.state.tab === 2 &&
              <div style={{ width: '100%', marginTop: '1%' }}>
                <ExpansionPanel expanded={expanded === 'panel16'} onChange={this.handleChangePanel('panel16')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={{ fontSize: 15, color: 'black' }}>{txt.stepTwo.fs.title}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="row" style={{ boxSizing: 'inherit' }} >
                      {
                        this.props.excel.map((label, index) => {
                          return (
                            <div>
                              {index == 0 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.base}</span>}
                              {index == 1 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.comparative}</span>}

                              <FunctionSupervival
                                txt={txt.tools}
                                key={index}
                                index={index}
                                handleInputChange={this.handleInputChangeFs}
                                value={this.state.Inputs[index].fsValues}
                                handleSendInfo={this.sendTwoFs}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expanded === 'panel7'} onChange={this.handleChangePanel('panel7')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={{ fontSize: 15, color: 'black' }}>
                      {txt.stepTwo.ct.title}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="row" style={{ boxSizing: 'inherit' }} >
                      {
                        this.props.excel.map((label, index) => {
                          return (
                            <div>
                              {index == 0 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.base}</span>}
                              {index == 1 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.comparative}</span>}

                              <TechComposition
                                key={index}
                                index={index}
                                tcVehicle={this.state.Inputs[index].tcVehicle}
                                vpHandleChange={this.handleChange}
                                taxis={this.state.Inputs[index].taxis}
                                camiones={this.state.Inputs[index].camiones}
                                buses={this.state.Inputs[index].buses}
                                txt={txt.tools}
                              />
                              <Button variant="contained" color="primary" style={{
                                height: 41,

                              }}
                                onClick={() => this.sendTwoTc(index)}>
                                Calcular
                        </Button>
                            </div>
                          );
                        })}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'panel8'} onChange={this.handleChangePanel('panel8')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={{ fontSize: 15, color: 'black' }}>
                      {txt.graph.g41}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="row" style={{ boxSizing: 'inherit' }} >
                      {
                        this.props.excel.map((label, index) => {
                          return (
                            <div>
                              {index == 0 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.base}</span>}
                              {index == 1 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.comparative}</span>}

                              <FunctionSupervival
                                txt={txt.tools}
                                key={index}
                                index={index}
                                handleInputChange={this.handleInputChangeFs}
                                value={this.state.Inputs[index].fsValues}
                                handleSendInfo={this.sendTwoFs}
                              />


                            </div>
                          );
                        })}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            }
            {this.state.tab === 3 &&
              <div style={{ width: '100%', marginTop: '1%' }}>

                <ExpansionPanel expanded={expanded === 'panel9'} onChange={this.handleChangePanel('panel9')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={{ fontSize: 15, color: 'black' }}>
                      {txt.stepThree.db.title}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="row" style={{ boxSizing: 'inherit' }} >
                      {
                        this.props.excel.map((label, index) => {
                          return (
                            <div>
                              {index == 0 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.base}</span>}
                              {index == 1 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.comparative}</span>}

                              <VariationDistances
                                txt={txt.tools}
                                key={index}
                                index={index}
                                vdValues={this.state.Inputs[index].vdValues}
                                handleInputChange={this.handleInputChangeVd}
                                handleSendInfo={this.sendThreeVd}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'pane20'} onChange={this.handleChangePanel('pane20')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={{ fontSize: 15, color: 'black' }}>
                      {txt.stepThree.vk.title}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="row" style={{ boxSizing: 'inherit' }} >
                      {
                        this.props.excel.map((label, index) => {
                          return (
                            <div>
                              {index == 0 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.base}</span>}
                              {index == 1 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.comparative}</span>}

                              <VariationDistances
                                txt={txt.tools}
                                key={index}
                                index={index}
                                vdValues={this.state.Inputs[index].vkValues}
                                handleInputChange={this.handleInputChangeVk}
                                handleSendInfo={this.sendThreeVk}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

              </div>
            }
            {this.state.tab === 4 && <div className={"card-panel"}><OptionsView txt={txt.tools} visualization={this.state.visualization} handleVizualization={this.handleVizualization} /> </div>}
            {this.state.tab === 5 && <div className={"card-panel"}><City txt={txt.tools} index={0} city={this.props.city} handleChange={this.handleChange} /> </div>}
          </div>

          {this.props.match.params.id == 'factoremisiones' ?
            <div className={"full-height"} style={{ height: '100%' }}>
              {
                this.props.excel &&
                <div>
                  {this.props.city !== 7 && < Emisiones visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} city={this.props.city} textTypeVehicle={'Camiones'} percentages={[0, 100, 0, 0]} flagChange={this.state.flagChange} />}
                  {this.props.city == 7 && <h4 style={{ color: '#1c2e78', textAlign: 'center' }}>Contaminante Local, no disponible para el parque Nacional.</h4>}
                </div>
              }
            </div> : null}

          {this.props.match.params.id == 'emisionesnox' ?
            <div className={"full-height"} style={{ height: '100%' }}>
              {this.props.excel &&

                <div>
                  {this.props.city !== 7 && < EmisionesNox visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} city={this.props.city} textTypeVehicle={'Camiones'} percentages={[0, 100, 0, 0]} flagChange={this.state.flagChange} />}
                  {this.props.city == 7 && <h4 style={{ color: '#1c2e78', textAlign: 'center' }}>Contaminante Local, no disponible para el parque Nacional.</h4>}
                </div>
              }
            </div> : null}

          {this.props.match.params.id == 'emisionespm' ?
            <div className={"full-height"} style={{ height: '100%' }}>
              {
                <div>
                  {this.props.excel &&
                    <div>
                      {this.props.city !== 7 && < EmisionesPM25 visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} city={this.props.city} textTypeVehicle={'Camiones'} percentages={[0, 100, 0, 0]} flagChange={this.state.flagChange} />}
                      {this.props.city == 7 && <h4 style={{ color: '#1c2e78', textAlign: 'center' }}>Contaminante Local, no disponible para el parque Nacional.</h4>}
                    </div>
                  }
                </div>
              }
            </div> : null}
          {this.props.match.params.id == 'emisionesconoxpm' ?
            <div className={"full-height"} style={{ height: '100%' }}>
              {
                <div>
                  {this.props.excel &&
                    <div>
                      {this.props.city !== 7 && < EmisionesCONOxPM visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} city={this.props.city} textTypeVehicle={'Camiones'} percentages={[0, 100, 0, 0]} flagChange={this.state.flagChange} />}
                      {this.props.city == 7 && <h4 style={{ color: '#1c2e78', textAlign: 'center' }}>Contaminante Local, no disponible para el parque Nacional.</h4>}
                    </div>
                  }
                </div>
              }
            </div> : null}

          {this.props.match.params.id == 'resumenemisiones' ?
            <div className={"full-height"} style={{ height: '100%' }}>
              {
                <div>
                  {this.props.excel &&
                    <div>
                      {this.props.city == 7 && < ResumenEmisionesCO2 visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} city={this.props.city} textTypeVehicle={'Camiones'} percentages={[0, 100, 0, 0]} flagChange={this.state.flagChange} />}
                      {this.props.city !== 7 && <h4 style={{ color: '#1c2e78', textAlign: 'center' }}>Contaminante Global, Solo disponible para el parque Nacional.</h4>}
                    </div>
                  }
                </div>
              }
            </div> : null}

          {this.props.match.params.id == 'emisionescotwo' ?
            <div className={"full-height"} style={{ height: '100%' }}>
              {
                <div>
                  {this.props.excel &&
                    <div>
                      {this.props.city == 7 && < EmisionesCO2 visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} city={this.props.city} textTypeVehicle={'Camiones'} percentages={[0, 100, 0, 0]} flagChange={this.state.flagChange} />}
                      {this.props.city !== 7 && <h4 style={{ color: '#1c2e78', textAlign: 'center' }}>Contaminante Global, Solo disponible para el parque Nacional.</h4>}
                    </div>
                  }
                </div>
              }

            </div> : null}

        </div>}
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return { lenguaje: state.lenguaje, excel: state.excel, city: state.city };
};

const mapDispatchToProps = dispatch => {
  return {
    changeExcel: datos => dispatch(excel(datos)),
    changeCity: datos => dispatch(city(datos))
  };
};

const AppScreen = connect(mapStateToProps, mapDispatchToProps)(StepFour);

export default AppScreen;

