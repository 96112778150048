import React from 'react'
import StackedColum from '../TypeGraphic/StackedColum';
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import text from "../../DB/text"
import Table from '../TypeGraphic/Table'
import { RangeEuros, RangeYears, GetCity } from "../utility/Range"

export default class TechnologicalComposition extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [],
            lpv: null,
            lpvDiesel: null,
            city: GetCity(props.city),
            lineYears: null,
            busesBencineros: null,
            busesDiesel: null,
            camionesBencineros: null,
            camionesDiesel: null,
            taxisBencineros: null,
            taxisDiesel: null
        }

    }


    componentDidMount() {
        this.allRangeEuro()
    }

    allRangeEuro() {



        var workbook
        var workbook = [];
        var arrayEuros = [];
        var bencineros = [];
        var diesel = [];
        var busesBencineros = [];
        var busesDiesel = [];
        var camionesBencineros = [];
        var camionesDiesel = [];
        var taxisBencineros = [];
        var taxisDiesel = [];
        var lineYears = [];
        var columns = [];
        var txt = text[this.props.lenguage].tools
        var arrayEuros = txt.euros

        for (const iterator of this.props.excel) {
            workbook = iterator.Sheets["ETAPA2_BGE"];

            bencineros.push(RangeEuros('E290:R310', arrayEuros, workbook))
            diesel.push(RangeEuros('L290:R310', arrayEuros, workbook))
            busesBencineros.push(RangeEuros('E338:K358', arrayEuros, workbook))
            busesDiesel.push(RangeEuros('L338:R358', arrayEuros, workbook))
            camionesBencineros.push(RangeEuros('E386:K406', arrayEuros, workbook))
            camionesDiesel.push(RangeEuros('L386:R406', arrayEuros, workbook))
            taxisBencineros.push(RangeEuros('E434:K454', arrayEuros, workbook))
            taxisDiesel.push(RangeEuros('L434:R454', arrayEuros, workbook))

        }

        var lineYears = RangeYears('C290:C310', workbook)

        var columns = [txt.TEURO, txt.years, txt.NV]

        this.setState({ columns, lineYears, lpv: bencineros, lpvDiesel: diesel, busesBencineros, busesDiesel, camionesBencineros, camionesDiesel, taxisBencineros, taxisDiesel })
    }



    componentWillReceiveProps(nextProps) {
        if (nextProps.flagChange == true) {
            if (nextProps.city) {
                var city = GetCity(nextProps.city)
                this.setState({ city }, () => {
                });
            }
            this.allRangeEuro();
        }
    }

    render() {

        var txt = text[this.props.lenguage].tools

        return (
            <div >

                <div  >

                    <Grid item xs={12}>
                        {this.state.lpv && this.props.visualization === 'graph' ? <StackedColum scena={txt} yaxis={txt.NV} years={this.state.lineYears} text={text[this.props.lenguage].graph.g33 + this.state.city} lpv={this.state.lpv} /> : null}
                        {this.state.lpv && this.props.visualization === 'graph' ? <StackedColum scena={txt} yaxis={txt.NV} years={this.state.lineYears} text={text[this.props.lenguage].graph.g34 + this.state.city} lpv={this.state.lpvDiesel} /> : null}
                        {this.state.lpv && this.props.visualization === 'graph' ? <StackedColum scena={txt} yaxis={txt.NV} years={this.state.lineYears} text={text[this.props.lenguage].graph.g35 + this.state.city} lpv={this.state.busesBencineros} /> : null}
                        {this.state.lpv && this.props.visualization === 'graph' ? <StackedColum scena={txt} yaxis={txt.NV} years={this.state.lineYears} text={text[this.props.lenguage].graph.g36 + this.state.city} lpv={this.state.busesDiesel} /> : null}
                        {this.state.lpv && this.props.visualization === 'graph' ? <StackedColum scena={txt} yaxis={txt.NV} years={this.state.lineYears} text={text[this.props.lenguage].graph.g37 + this.state.city} lpv={this.state.camionesBencineros} /> : null}
                        {this.state.lpv && this.props.visualization === 'graph' ? <StackedColum scena={txt} yaxis={txt.NV} years={this.state.lineYears} text={text[this.props.lenguage].graph.g38 + this.state.city} lpv={this.state.camionesDiesel} /> : null}
                        {this.state.lpv && this.props.visualization === 'graph' ? <StackedColum scena={txt} yaxis={txt.NV} years={this.state.lineYears} text={text[this.props.lenguage].graph.g39 + this.state.city} lpv={this.state.taxisBencineros} /> : null}
                        {this.state.lpv && this.props.visualization === 'graph' ? <StackedColum scena={txt} yaxis={txt.NV} years={this.state.lineYears} text={text[this.props.lenguage].graph.g40 + this.state.city} lpv={this.state.taxisDiesel} /> : null}
                    </Grid>
                    {this.state.lpv && this.props.visualization === 'table' ? <Table columns={this.state.columns} text={text[this.props.lenguage].graph.g33 + this.state.city} lpv={this.state.lpv} /> : null}
                    {this.state.lpv && this.props.visualization === 'table' ? <Table columns={this.state.columns} text={text[this.props.lenguage].graph.g34 + this.state.city} lpv={this.state.lpvDiesel} /> : null}
                    {this.state.lpv && this.props.visualization === 'table' ? <Table columns={this.state.columns} text={text[this.props.lenguage].graph.g35 + this.state.city} lpv={this.state.busesBencineros} /> : null}
                    {this.state.lpv && this.props.visualization === 'table' ? <Table columns={this.state.columns} text={text[this.props.lenguage].graph.g36 + this.state.city} lpv={this.state.busesDiesel} /> : null}
                    {this.state.lpv && this.props.visualization === 'table' ? <Table columns={this.state.columns} text={text[this.props.lenguage].graph.g37 + this.state.city} lpv={this.state.camionesBencineros} /> : null}
                    {this.state.lpv && this.props.visualization === 'table' ? <Table columns={this.state.columns} text={text[this.props.lenguage].graph.g38 + this.state.city} lpv={this.state.camionesDiesel} /> : null}
                    {this.state.lpv && this.props.visualization === 'table' ? <Table columns={this.state.columns} text={text[this.props.lenguage].graph.g39 + this.state.city} lpv={this.state.taxisBencineros} /> : null}
                    {this.state.lpv && this.props.visualization === 'table' ? <Table columns={this.state.columns} text={text[this.props.lenguage].graph.g40 + this.state.city} lpv={this.state.taxisDiesel} /> : null}

                </div>
            </div>
        );
    }
}