
import React from 'react';
import VehiclesPeerThousand from '../Components/SteepOne/VehiclesPeerThousand';
import VehiclePrivate from '../Components/SteepOne/VehiclePrivate';
import VehicleNoPrivate from '../Components/SteepOne/VehicleNoPrivate';
import CombustiblePrivate from '../Components/SteepOne/CombustiblePrivate';
import CombustiblePublic from '../Components/SteepOne/CombustiblePublic';
import VehicleElectric from '../Components/SteepOne/VehicleElectric';
import Introduction from '../Components/Introduction';
import { connect } from "react-redux";
import text from "../DB/text"
import Grid from '@material-ui/core/Grid';
import images from '../DB/images';
import RangePercentage from '../Components/SteepOne/RangePercentage';
import ProyectPrivateGDP from '../Components/Tools/ProyectPrivateGDP';
import ProyectNoPrivate from '../Components/Tools/ProyectNoPrivate';
import OneCombustiblePrivate from '../Components/Tools/OneCombustiblePrivate';
import LvSaturation from '../Components/Tools/LvSaturation';
import OneElectric from '../Components/Tools/OneElectric';
import City from '../Components/Tools/City';
import routes from "../routes.js";
import IconButton from '@material-ui/core/IconButton';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import OptionsView from '../Components/Tools/OptionsView';

import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinearDeterminate from "../Components/utility/LinearDeterminate"

import {
    StepOneElectricPrivate,
    StepOneElectricTaxi,
    StepOneElectricBus,
    StepOneElectricCamion,
    StepOneHandle,
    RangeSlider
} from "../Components/utility/SetInputs"
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import XLSX from '@sheet/core';
import S5SCalc from '@sheet/formula';
import { excel, city } from '../actionCreators';
S5SCalc.XLSXLib = XLSX; // <-- make the connection

class StepOne extends React.Component {
    constructor(props) {
        super(props);
        var Inputs = this.setDefaultValues(props.excel);

        this.state = {
            expanded: null,
            tab: 0,
            visualization: "graph",
            lenguaje: this.props.lenguaje,
            loading: false,
            Inputs
        }

        console.log('this.props ', this.props);
        console.log('this.state', this.state);

    }

    setDefaultValues = (excel) => {

        var Inputs = [];

        for (let index = 0; index < excel.length; index++) {

            var valDefaul = excel[index].Sheets.ETAPA1_BGE
            //valDefaul.A1.v
            var objAuxInput = {
                index: index,
                growthPopulation: valDefaul.D155.v * 100,
                growthGdp: valDefaul.D156.v * 100,
                input: valDefaul.J16.v * 100,
                variationTaxi: valDefaul.D215.v * 100,
                variationBus: valDefaul.D216.v * 100,
                variationCamion: valDefaul.D217.v * 100,
                lvSaturation: valDefaul.D425.v,
                lvSaturationElectric: valDefaul.D518.v,
                electricValuesPrivate: {
                    deltaC: valDefaul.D520.v,
                    p: valDefaul.D521.v,
                    q: valDefaul.D522.v,
                    t0: valDefaul.D523.v,
                    deltaP: valDefaul.D524.v,
                    l: valDefaul.G520.v,
                    k: valDefaul.G521.v,
                    t02: valDefaul.G522.v,
                    fcv: valDefaul.J520.v,
                    phev: valDefaul.J521.v,
                    bev: valDefaul.J522.v,
                },
                electricValuesTaxis: {
                    deltaC: valDefaul.D656.v,
                    p: valDefaul.D657.v,
                    q: valDefaul.D658.v,
                    t0: valDefaul.D659.v,
                    deltaP: valDefaul.D660.v,
                    l: valDefaul.G656.v,
                    k: valDefaul.G657.v,
                    t02: valDefaul.G658.v,
                    fcv: valDefaul.J656.v,
                    phev: valDefaul.J657.v,
                    bev: valDefaul.J668.v,
                },
                electricValuesBuses: {
                    deltaC: valDefaul.D793.v,
                    p: valDefaul.D794.v,
                    q: valDefaul.D795.v,
                    t0: valDefaul.D796.v,
                    deltaP: valDefaul.D797.v,
                    l: valDefaul.G793.v,
                    k: valDefaul.G794.v,
                    t02: valDefaul.G795.v,
                    fcv: valDefaul.J793.v,
                    phev: valDefaul.J794.v,
                    bev: valDefaul.J795.v,
                },
                electricValuesCamiones: {
                    deltaC: valDefaul.D927.v,
                    p: valDefaul.D928.v,
                    q: valDefaul.D929.v,
                    t0: valDefaul.D230.v,
                    deltaP: valDefaul.D231.v,
                    l: valDefaul.G927.v,
                    k: valDefaul.G928.v,
                    t02: valDefaul.G929.v,
                    fcv: valDefaul.J927.v,
                    phev: valDefaul.J928.v,
                    bev: valDefaul.J929.v,
                }
            };

            Inputs.push(JSON.parse(JSON.stringify(objAuxInput)));
        }

        return Inputs;

    }


    handleChangePanel = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleInputChangePrivate = index => event => {
        var nowState = this.state.Inputs
        nowState[index].electricValuesPrivate[event.target.name] = event.target.value
        this.setState({
            Inputs: nowState
        });
    }

    handleInputChangeTaxis = index => event => {
        var nowState = this.state.Inputs
        nowState[index].electricValuesTaxis[event.target.name] = event.target.value
        this.setState({
            Inputs: nowState
        });
    }
    handleInputChangeBuses = index => event => {
        var nowState = this.state.Inputs
        nowState[index].electricValuesBuses[event.target.name] = event.target.value
        this.setState({
            Inputs: nowState
        });
    }

    handleInputChangeCamiones = index => event => {
        var nowState = this.state.Inputs
        nowState[index].electricValuesCamiones[event.target.name] = event.target.value
        this.setState({
            Inputs: nowState
        });
    }

    handleVizualization = event => {
        this.setState({ visualization: event.target.value });
    };

    handleChangeTabs = (event, tab) => {
        this.setState({ tab });
    };

    handleSendInfo = (type, index) => {
        this.setState({
            loading: true
        }, () => {
            setTimeout(() => {
                var workbook = this.props.excel[index];
                if (type == 'private') {
                    var val = this.state.Inputs[index].electricValuesPrivate
                    val.lvSaturationElectric = this.state.Inputs[index].lvSaturationElectric
                    workbook = StepOneElectricPrivate(val, workbook);
                    var sendWorkbook = this.props.excel;
                    sendWorkbook[index] = workbook
                    this.props.changeExcel({ workbook: sendWorkbook })
                }
                else if (type == 'taxi') {
                    var val = this.state.Inputs[index].electricValuesTaxis
                    val.lvSaturationElectric = this.state.Inputs[index].lvSaturationElectric
                    workbook = StepOneElectricTaxi(val, workbook);
                    var sendWorkbook = this.props.excel;
                    sendWorkbook[index] = workbook
                    this.props.changeExcel({ workbook: sendWorkbook })
                }
                else if (type == 'bus') {
                    var val = this.state.Inputs[index].electricValuesBuses
                    val.lvSaturationElectric = this.state.Inputs[index].lvSaturationElectric
                    workbook = StepOneElectricBus(val, workbook);
                    var sendWorkbook = this.props.excel;
                    sendWorkbook[index] = workbook
                    this.props.changeExcel({ workbook: sendWorkbook })
                }
                else if (type == 'camion') {
                    var val = this.state.Inputs[index].electricValuesCamiones
                    val.lvSaturationElectric = this.state.Inputs[index].lvSaturationElectric
                    workbook = StepOneElectricCamion(val, workbook);
                    var sendWorkbook = this.props.excel;
                    sendWorkbook[index] = workbook
                    this.props.changeExcel({ workbook: sendWorkbook })
                }
                this.setState({ flagChange: true, loading: false }, () => {
                    this.setState({ flagChange: false }, () => {
                    })
                })
            }, 1000);
        })
    }


    handleChangeSaturation = index => event => {
        this.setState(prevState => ({
            Inputs: prevState.Inputs.map(
                obj => (obj.index === index ? Object.assign(obj, { lvSaturationElectric: event.target.value }) : obj)
            )
        }));

    }

    handleChange = index => event => {

        this.setState({
            loading: true
        }, () => {
            setTimeout(() => {
                var value = event.target.value;
                var valueInt = parseInt(value);
                var workbook = this.props.excel[index];

                if (event.target.name === 'city') {
                    var prom = StepOneHandle(valueInt, workbook, event.target.name);

                    prom.then((workbook) => {
                        this.props.changeCity({ city: valueInt });
                        var newWorkbook = []

                        newWorkbook.push(JSON.parse(JSON.stringify(workbook)));
                        newWorkbook.push(JSON.parse(JSON.stringify(workbook)));

                        var newValuesDefault = this.setDefaultValues(newWorkbook)
                        this.setState({
                            Inputs: newValuesDefault
                        });

                        this.props.changeExcel({ workbook: newWorkbook })
                        this.setState({ [event.target.name]: event.target.value });
                        this.setState({ flagChange: true, loading: false }, () => {
                            this.setState({ flagChange: false }, () => {
                            })
                        })
                    }).catch(function (error) {
                        // ops
                        console.log(error.message);
                    });

                } else {
                    var newWorkbook = StepOneHandle(valueInt, workbook, event.target.name);
                    var sendWorkbook = this.props.excel;
                    sendWorkbook[index] = newWorkbook
                    this.props.changeExcel({ workbook: sendWorkbook })

                    this.setState(prevState => ({
                        Inputs: prevState.Inputs.map(
                            obj => (obj.index === index ? Object.assign(obj, { [event.target.name]: event.target.value }) : obj)
                        )
                    }));
                    this.setState({ flagChange: true, loading: false }, () => {
                        this.setState({ flagChange: false }, () => {
                        })
                    })
                }

                if (workbook) {

                }

            }, 1000);
        })
    };

    getPercentages = (percetages, type, index) => {
        this.setState({
            loading: true
        }, () => {
            setTimeout(() => {
                var workbook = this.props.excel[index];
                workbook = RangeSlider(percetages, workbook, type)
                var sendWorkbook = this.props.excel;
                sendWorkbook[index] = workbook

                this.props.changeExcel({ workbook: sendWorkbook })

                this.setState({ flagChange: true, loading: false }, () => {
                    this.setState({ flagChange: false }, () => {
                    })
                })
            }, 1000);
        })
    }

    handleDirection = (direction) => {
        var position = routes.listRoutes.indexOf(this.props.match.url)
        if (routes.listRoutes[position + 1] == '/StepOne/proyectnoprivate') {
            this.setState({ tab: 0 })
        }
        if (direction == 'next') {
            this.props.history.push(routes.listRoutes[position + 1])
        } else {
            this.props.history.push(routes.listRoutes[position - 1])
        }
    }

    render() {
        const { expanded } = this.state;
        const txt = text[this.props.lenguaje]
        console.log('lenguaje StepOne', this.props.lenguaje);
        console.log('lenguaje statte StepOne', this.state);
        return (

            <div style={{ paddingTop: '24px', paddingLeft: '64px', paddingRight: '64px', }}>

                {this.state.loading === true &&
                    <div style={{ marginTop: '5%', zIndex: 99999, }}>
                        <LinearDeterminate /> </div>}
                {this.state.loading === false &&
                    <div style={{ marginTop: '5%' }}>
                        {
                            this.props.match.params.id !== 'proyectprivate' &&
                            <div style={{ position: 'fixed', bottom: '40%', left: 8 }}>
                                <IconButton style={{ padding: '5px', background: '#dadada4d', zIndex: 99999, }} onClick={() => this.handleDirection('before')} name="before">
                                    <ChevronLeftIcon style={{ height: 35, width: 35 }} />
                                </IconButton>
                            </div>
                        }

                        <div style={{ position: 'fixed', bottom: '40%', right: '7px', float: 'right' }}>
                            <IconButton style={{ padding: '5px', background: '#dadada4d', zIndex: 99999, }} onClick={() => this.handleDirection('next')} name="before">
                                <ChevronRightIcon style={{ height: 35, width: 35 }} />
                            </IconButton>
                        </div>

                        <div style={{ width: '100% !important' }} >
                            <div >
                                <div className={"row"}>
                                    <div style={{
                                        flexGrow: 1,
                                        width: '100% !important',
                                    }}>
                                        {this.props.match.params.id == 'proyectprivate' ?
                                            <div>
                                                <AppBar position="static" color="default">
                                                    <Tabs
                                                        value={this.state.tab}
                                                        onChange={this.handleChangeTabs}
                                                        indicatorColor="primary"
                                                        textColor="primary"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                    >
                                                        <Tab label={txt.tools.intro} />
                                                        <Tab label={txt.tools.config} />
                                                        <Tab label={txt.tools.herra} />
                                                        <Tab label={txt.tools.cambCiudad} />
                                                        <Tab label={txt.tools.info} />
                                                    </Tabs>
                                                </AppBar>
                                                {
                                                    this.state.tab != 3 && <div className={"card-panel"} style={{ width: "100%" }}>
                                                        {this.state.tab === 0 && <Introduction title={txt.stepOne.pv.title} text={txt.stepOne.pv.description} />}
                                                        {this.state.tab === 1 &&

                                                            this.props.excel.map((label, index) => {
                                                                return ( //Inputs
                                                                    <ProyectPrivateGDP
                                                                        key={index}
                                                                        index={index}
                                                                        input={this.state.Inputs[index].input}
                                                                        growthPopulation={this.state.Inputs[index].growthPopulation}
                                                                        handleChange={this.handleChange}
                                                                        growthGdp={this.state.Inputs[index].growthGdp}
                                                                        txt={txt.tools}
                                                                    />
                                                                );
                                                            })}
                                                        {this.state.tab === 2 && <OptionsView txt={txt.tools} visualization={this.state.visualization} handleVizualization={this.handleVizualization} />}
                                                        {this.state.tab === 4 && <Introduction links={txt.stepOne.vpp.links} title={txt.stepOne.vpp.title} text={txt.stepOne.vpp.description} tileData={images.StepOneVP.tileData} />}
                                                    </div>
                                                }
                                            </div> : null
                                        }

                                        {this.props.match.params.id == 'proyectnoprivate' &&
                                            <div>
                                                <AppBar position="static" color="default">
                                                    <Tabs
                                                        value={this.state.tab}
                                                        onChange={this.handleChangeTabs}
                                                        indicatorColor="primary"
                                                        textColor="primary"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                    >
                                                        <Tab label={txt.tools.info} />
                                                        <Tab label={txt.tools.config} />
                                                        <Tab label={txt.tools.herra} />
                                                        <Tab label={txt.tools.cambCiudad} />
                                                    </Tabs>
                                                </AppBar>

                                                {this.state.tab != 3 && <div className={"card-panel"} style={{ width: "100%" }}>
                                                    {this.state.tab === 0 && <Introduction title={txt.stepOne.vnpp.title} text={txt.stepOne.vnpp.description} tileData={images.StepOneVPP.tileData} />}
                                                    {this.state.tab === 1 &&

                                                        this.props.excel.map((label, index) => {
                                                            return ( //Inputs
                                                                <ProyectNoPrivate
                                                                    key={index}
                                                                    index={index}
                                                                    variationTaxi={this.state.Inputs[index].variationTaxi}
                                                                    variationBus={this.state.Inputs[index].variationBus}
                                                                    variationCamion={this.state.Inputs[index].variationCamion}
                                                                    handleChange={this.handleChange}
                                                                    txt={txt.tools}
                                                                />
                                                            );
                                                        })}


                                                    {this.state.tab === 2 && <OptionsView txt={txt.tools} visualization={this.state.visualization} handleVizualization={this.handleVizualization} />}
                                                </div>
                                                }
                                            </div>
                                        }

                                        {this.props.match.params.id == 'onecombustibleprivate' || this.props.match.params.id == 'onecombustiblepublic' ?
                                            <div>
                                                <AppBar position="static" color="default">
                                                    <Tabs
                                                        value={this.state.tab}
                                                        onChange={this.handleChangeTabs}
                                                        indicatorColor="primary"
                                                        textColor="primary"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                    >
                                                        <Tab label={txt.tools.info} />
                                                        <Tab label={txt.tools.config} />
                                                        <Tab label={txt.tools.herra} />
                                                        <Tab label={txt.tools.cambCiudad} />
                                                    </Tabs>
                                                </AppBar>

                                                {this.state.tab != 3 &&
                                                    <div className={"card-panel"} style={{ width: "100%" }}>
                                                        {this.state.tab === 0 && <Introduction title={txt.stepOne.cpc.title} text={txt.stepOne.cpc.description} />}
                                                        {this.state.tab === 1 &&
                                                            this.props.excel.map((label, index) => {
                                                                return ( //Inputs
                                                                    <OneCombustiblePrivate
                                                                        txt={txt.tools}
                                                                        index={index}
                                                                        lvSaturation={this.state.Inputs[index].lvSaturation}
                                                                        handleChange={this.handleChange} />
                                                                );
                                                            })}
                                                        {this.state.tab === 2 && <OptionsView txt={txt.tools} visualization={this.state.visualization} handleVizualization={this.handleVizualization} />}
                                                    </div>
                                                }
                                            </div> : null
                                        }

                                        {this.props.match.params.id == 'oneelectric' &&
                                            <div>
                                                <AppBar position="static" color="default">
                                                    <Tabs
                                                        value={this.state.tab}
                                                        onChange={this.handleChangeTabs}
                                                        indicatorColor="primary"
                                                        textColor="primary"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                    >
                                                        <Tab label={txt.tools.info} />
                                                        <Tab label={txt.tools.config} />
                                                        <Tab label={txt.tools.herra} />
                                                        <Tab label={txt.tools.cambCiudad} />
                                                    </Tabs>
                                                </AppBar>

                                                {this.state.tab === 0 && <div className={"card-panel"} style={{ width: "100%" }}> <Introduction title={txt.stepOne.pve.title} text={txt.stepOne.pve.description} tileData={images.StepOneVPE.tileData} />  </div>}

                                                {this.state.tab === 2 && <div className={"card-panel"} style={{ width: "100%" }}>  <OptionsView txt={txt.tools} visualization={this.state.visualization} handleVizualization={this.handleVizualization} /> </div>}
                                                {this.state.tab === 1 &&
                                                    <div style={{ width: '100%', marginTop: '1%' }}>
                                                        <ExpansionPanel expanded={expanded === 'panel1'} onChange={this.handleChangePanel('panel1')}>
                                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                <Typography className={{ fontSize: 15, color: 'black' }}>{txt.graph.g12}</Typography>
                                                            </ExpansionPanelSummary>
                                                            <ExpansionPanelDetails>


                                                                <div className="row">

                                                                    {
                                                                        this.props.excel.map((label, index) => {
                                                                            return ( //Inputs
                                                                                <div style={{ width: '100%' }}>
                                                                                    {index == 0 && <span style={{ color: '#757575' }}>{txt.tools.base}</span>}
                                                                                    {index == 1 && <span style={{ color: '#757575' }}>{txt.tools.comparative}</span>}
                                                                                    <div className="row">

                                                                                        <LvSaturation
                                                                                            txt={txt.tools}
                                                                                            index={index}
                                                                                            lvSaturation={this.state.Inputs[index].lvSaturationElectric}
                                                                                            handleChange={this.handleChangeSaturation} />
                                                                                        <OneElectric
                                                                                            handleInputChange={this.handleInputChangePrivate}
                                                                                            electricValues={this.state.Inputs[index].electricValuesPrivate}
                                                                                            handleSendInfo={this.handleSendInfo}
                                                                                            type={'private'}
                                                                                            index={index}
                                                                                            txt={txt.tools} />
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </ExpansionPanelDetails>
                                                        </ExpansionPanel>

                                                        <ExpansionPanel expanded={expanded === 'panel2'} onChange={this.handleChangePanel('panel2')}>
                                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                <Typography className={{ fontSize: 15, color: 'black' }}>
                                                                    {txt.graph.g16}
                                                                </Typography>
                                                            </ExpansionPanelSummary>
                                                            <ExpansionPanelDetails>
                                                                <div className="row">
                                                                    {
                                                                        this.props.excel.map((label, index) => {
                                                                            return ( //Inputs
                                                                                <div style={{ width: '100%' }}>
                                                                                    {index == 0 && <span style={{ color: '#757575' }}>{txt.tools.base}</span>}
                                                                                    {index == 1 && <span style={{ color: '#757575' }}>{txt.tools.comparative}</span>}

                                                                                    <div className="row">
                                                                                        <LvSaturation
                                                                                            txt={txt.tools}
                                                                                            index={index}
                                                                                            lvSaturation={this.state.Inputs[index].lvSaturationElectric}
                                                                                            handleChange={this.handleChangeSaturation} />
                                                                                        <OneElectric
                                                                                            handleInputChange={this.handleInputChangePrivate}
                                                                                            electricValues={this.state.Inputs[index].electricValuesPrivate}
                                                                                            handleSendInfo={this.handleSendInfo}
                                                                                            type={'taxi'}
                                                                                            index={index}
                                                                                            txt={txt.tools}  />
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </ExpansionPanelDetails>
                                                        </ExpansionPanel>

                                                        <ExpansionPanel expanded={expanded === 'panel3'} onChange={this.handleChangePanel('panel3')}>
                                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                <Typography className={{ fontSize: 15, color: 'black' }}>
                                                                    {txt.graph.g20}
                                                                </Typography>
                                                            </ExpansionPanelSummary>
                                                            <ExpansionPanelDetails>
                                                                <div className="row">
                                                                    {
                                                                        this.props.excel.map((label, index) => {
                                                                            return ( //Inputs
                                                                                <div style={{ width: '100%' }}>
                                                                                    {index == 0 && <span style={{ color: '#757575' }}>{txt.tools.base}</span>}
                                                                                    {index == 1 && <span style={{ color: '#757575' }}>{txt.tools.comparative}</span>}

                                                                                    <div className="row">
                                                                                        <LvSaturation
                                                                                            txt={txt.tools}
                                                                                            index={index}
                                                                                            lvSaturation={this.state.Inputs[index].lvSaturationElectric}
                                                                                            handleChange={this.handleChangeSaturation} />
                                                                                        <OneElectric
                                                                                             handleInputChange={this.handleInputChangePrivate}
                                                                                             electricValues={this.state.Inputs[index].electricValuesPrivate}
                                                                                             handleSendInfo={this.handleSendInfo}
                                                                                             type={'bus'}
                                                                                             index={index}
                                                                                             txt={txt.tools}  />
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </ExpansionPanelDetails>
                                                        </ExpansionPanel>

                                                        <ExpansionPanel expanded={expanded === 'panel4'} onChange={this.handleChangePanel('panel4')}>
                                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                <Typography className={{ fontSize: 15, color: 'black' }}>
                                                                    {txt.graph.g24}
                                                                </Typography>
                                                            </ExpansionPanelSummary>
                                                            <ExpansionPanelDetails>
                                                                <div className="row">
                                                                    {
                                                                        this.props.excel.map((label, index) => {
                                                                            return ( //Inputs
                                                                                <div style={{ width: '100%' }}>
                                                                                    {index == 0 && <span style={{ color: '#757575' }}>{txt.tools.base}</span>}
                                                                                    {index == 1 && <span style={{ color: '#757575' }}>{txt.tools.comparative}</span>}

                                                                                    <div className="row">
                                                                                        <LvSaturation
                                                                                            txt={txt.tools}
                                                                                            index={index}
                                                                                            lvSaturation={this.state.Inputs[index].lvSaturationElectric}
                                                                                            handleChange={this.handleChangeSaturation} />
                                                                                        <OneElectric
                                                                                             handleInputChange={this.handleInputChangePrivate}
                                                                                             electricValues={this.state.Inputs[index].electricValuesPrivate}
                                                                                             handleSendInfo={this.handleSendInfo}
                                                                                             type={'camion'}
                                                                                             index={index}
                                                                                             txt={txt.tools}  />
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </ExpansionPanelDetails>
                                                        </ExpansionPanel>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {this.state.tab === 3 && <div className={"card-panel"}><City txt={txt.tools} index={0} city={this.props.city} handleChange={this.handleChange} /> </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.props.match.params.id == 'proyectprivate' &&
                            <div>
                                {this.props.excel &&
                                    <div>
                                        <VehiclePrivate visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} flagChange={this.state.flagChange} city={this.props.city} />
                                        <VehiclesPeerThousand visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} flagChange={this.state.flagChange} city={this.props.city} />
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.props.match.params.id == 'proyectnoprivate' &&
                            <div>
                                {this.props.excel && <VehicleNoPrivate visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} flagChange={this.state.flagChange} city={this.props.city} />}
                            </div>
                        }
                        {
                            this.props.match.params.id == 'onecombustibleprivate' &&
                            <Grid container spacing={8}>
                                <Grid item xs={3}>
                                    <div>
                                        {this.props.excel &&
                                            <RangePercentage
                                                lenguaje={this.props.lenguaje}
                                                excel={this.props.excel}
                                                textTypeVehicle={'Private'}
                                                getPercentages={this.getPercentages}
                                                city={this.props.city}
                                                flagChange={this.state.flagChange} />
                                        }

                                    </div>
                                </Grid>
                                <Grid item xs={9}>
                                    {this.props.excel && <CombustiblePrivate visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} flagChange={this.state.flagChange} city={this.props.city} />}
                                </Grid>
                            </Grid>
                        }
                        {this.props.match.params.id == 'onecombustiblepublic' &&
                            <div>
                                <Grid container spacing={8}>
                                    <Grid item xs={3}>
                                        <div >
                                            {this.props.excel && <RangePercentage lenguaje={this.props.lenguaje} excel={this.props.excel} textTypeVehicle={'Taxis'} getPercentages={this.getPercentages} city={this.props.city} flagChange={this.state.flagChange} />}
                                        </div>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {this.props.excel && <CombustiblePublic visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} range={'D278:G298'} textTypeVehicle={txt.graph.g7} flagChange={this.state.flagChange} city={this.props.city} />}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={8}>
                                    <Grid item xs={3}>
                                        <div >
                                            {this.props.excel && <RangePercentage lenguaje={this.props.lenguaje} excel={this.props.excel} textTypeVehicle={'Buses'} getPercentages={this.getPercentages} city={this.props.city} flagChange={this.state.flagChange} />}
                                        </div>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {this.props.excel && <CombustiblePublic visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} range={'D325:G345'} textTypeVehicle={txt.graph.g8} flagChange={this.state.flagChange} city={this.props.city} />}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={8}>
                                    <Grid item xs={3}>
                                        <div >
                                            {this.props.excel && <RangePercentage lenguaje={this.props.lenguaje} excel={this.props.excel} textTypeVehicle={'Camiones'} getPercentages={this.getPercentages} city={this.props.city} flagChange={this.state.flagChange} />}
                                        </div>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {this.props.excel && <CombustiblePublic visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} range={'D373:G393'} textTypeVehicle={txt.graph.g9} flagChange={this.state.flagChange} city={this.props.city} />}
                                    </Grid>
                                </Grid>
                            </div>
                        }
                        {
                            this.props.match.params.id == 'oneelectric' &&
                            <div>
                                {this.props.excel && < VehicleElectric visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} flagChange={this.state.flagChange} city={this.props.city} textTypeVehicle={'Camiones'} private={false} />}
                            </div>
                        }
                    </div >}
            </div >
        );
    }
}


const mapStateToProps = (state) => {
    return { lenguaje: state.lenguaje, excel: state.excel, city: state.city };
};

const mapDispatchToProps = dispatch => {
    return {
        changeExcel: datos => dispatch(excel(datos)),
        changeCity: datos => dispatch(city(datos))

    };
};

const AppScreen = connect(mapStateToProps, mapDispatchToProps)(StepOne);

export default AppScreen;
