import React from 'react'
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import StackedColum from '../TypeGraphic/StackedColum';
import text from "../../DB/text"
import { RangeEuros, RangeYears, GetCity } from "../utility/Range"
import Table from '../TypeGraphic/Table'

export default class CombustiblePublic extends React.Component {

    constructor(props) {
        super(props);
        console.log('this.props', this.props)
        this.state = {
            city: GetCity(props.city),
            lineYears: null,
            publicoCombustible: null,
            columns: []
        }
    }

    componentDidMount() {
        this.allRangeEuro()
    }

    allRangeEuro() {
        var workbook;
        var publicoCombustibleArray = [];
        for (const iterator of this.props.excel) {
            workbook = iterator.Sheets["ETAPA1_BGE"];
            var publicoCombustible = RangeEuros(this.props.range, text[this.props.lenguage].tools.fuels, workbook);
            for (let index = 0; index < publicoCombustible.length; index++) {
                for (let i = 0; i < publicoCombustible[index].data.length; i++) {
                    if (publicoCombustible[index].data[i] < 0) {
                        publicoCombustible[index].data[i] = 0;
                    }
                }
            }
            publicoCombustibleArray.push(publicoCombustible);
        }

        var lineYears = RangeYears('C232:C252', workbook)
        var columns = [text[this.props.lenguage].tools.TYPEC, text[this.props.lenguage].tools.years, text[this.props.lenguage].tools.YCP]
        this.setState({
            lineYears,
            publicoCombustible: publicoCombustibleArray,
            columns
        })
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.flagChange == true) {
            if (nextProps.city) {
                var city = GetCity(nextProps.city)
                this.setState({ city }, () => {
                });
            }
            this.allRangeEuro();
        }
    }

    render() {
        const style = {



        };

        return (
            <div style={style}>

                <div  >
                    <Grid item xs={12}>

                        {this.state.publicoCombustible && this.props.visualization === 'graph' ?
                            <StackedColum scena={text[this.props.lenguage].tools} yaxis={this.props.textTypeVehicle + text[this.props.lenguage].tools.YCPP} text={this.props.textTypeVehicle + text[this.props.lenguage].graph.g5 + this.state.city} years={this.state.lineYears} lpv={this.state.publicoCombustible} />
                            : null}

                        {this.state.publicoCombustible && this.props.visualization === 'table' ?
                            <Table text={this.props.textTypeVehicle + text[this.props.lenguage].graph.g5 + this.state.city} columns={this.state.columns} lpv={this.state.publicoCombustible} />
                            : null}

                    </Grid>
                </div>
            </div>
        );
    }
}

