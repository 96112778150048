import React from 'react'
import Stacked from '../TypeGraphic/Stacked';
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import BarColum from '../TypeGraphic/BarColum';
import text from "../../DB/text"
import { RangeEuros, RangeYears, GetCity } from "../utility/Range"
import Table from '../TypeGraphic/Table'

export default class VehicleElectric extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      city: GetCity(props.city),
      lineYears: null,

      investmentPrivados: null,
      percentagePrivado: null,
      privateConvencionalesvsElec: null,
      projectedPrivate: null,

      investmentTaxi: null,
      percentageTaxiElectric: null,
      TaxiConvencionalesvsElec: null,
      projectedTaxi: null,

      investmentBuses: null,
      percentageBuses: null,
      projectedBuses: null,
      busesConvencionalesvsElec: null,

      investmentCamion: null,
      percentageCamion: null,
      projectedCamion: null,
      camionConvencionalesvsElec: null,

      columns: []
    }
  }

  componentDidMount() {
    this.allRangeEuro()
  }

  allRangeEuro() {


    var investmentPrivados = []
    var percentagePrivado = []
    var projectedPrivate = []

    var privateConvencionalesvsElec = []

    var investmentTaxi = []
    var percentageTaxiElectric = []

    var projectedTaxi = []
    var TaxiConvencionalesvsElec = []

    var investmentBuses = []
    var percentageBuses = []

    var projectedBuses = []
    var busesConvencionalesvsElec = []

    var investmentCamion = []
    var percentageCamion = []

    var projectedCamion = []
    var camionConvencionalesvsElec = []

    var workbook;
    var txt = text[this.props.lenguage].tools;
    for (const iterator of this.props.excel) {
      workbook = iterator.Sheets["ETAPA1_BGE"];

      investmentPrivados.push(RangeEuros('I495:I515', [txt.typeVehiclesArray[0]], workbook))
      percentagePrivado.push(RangeEuros('D495:D515', [txt.perVPE], workbook))
      projectedPrivate.push(RangeEuros('D560:F580', txt.arrProjectedPrivate, workbook))

      privateConvencionalesvsElec.push(RangeEuros('AF560:AI580', txt.convencionalesvsElec, workbook))

      investmentTaxi.push(RangeEuros('I633:I653', [txt.vehiclesArray[3]], workbook))
      percentageTaxiElectric.push(RangeEuros('D633:D653', [txt.percentageTaxiElectric], workbook))

      projectedTaxi.push(RangeEuros('D696:F716', txt.arrProjectedPrivate, workbook))
      TaxiConvencionalesvsElec.push(RangeEuros('AF696:AI716', txt.convencionalesvsElec, workbook))

      investmentBuses.push(RangeEuros('I770:I790', [txt.vehiclesArray[1]], workbook))
      percentageBuses.push(RangeEuros('D770:D790', [txt.percentageBuses], workbook))

      projectedBuses.push(RangeEuros('D832:G852', txt.arrProjectedPrivate, workbook))
      busesConvencionalesvsElec.push(RangeEuros('AF832:AI852', txt.convencionalesvsElec, workbook))

      investmentCamion.push(RangeEuros('I904:I924', [txt.vehiclesArray[2]], workbook))
      percentageCamion.push(RangeEuros('D904:D924', [txt.percentageCamion], workbook))

      projectedCamion.push(RangeEuros('D966:F986', txt.arrProjectedPrivate, workbook))
      camionConvencionalesvsElec.push(RangeEuros('AF966:AI986', txt.convencionalesvsElec, workbook))
    }

    var lineYears = RangeYears('C560:C580', workbook);

    var columnsInversion = [txt.YIAM, txt.years, txt.OVehicle]
    var columnsVentas = [txt.YVEP, txt.years, txt.OVehicle]
    var columnsElectric = [txt.YVE, txt.years, txt.TYPE]
    var columnsVehicles = [txt.TYPE, txt.years, txt.TYPE]


    this.setState({
      lineYears,
      investmentPrivados,
      percentagePrivado,
      projectedPrivate,
      privateConvencionalesvsElec,
      investmentTaxi,
      percentageTaxiElectric,
      projectedTaxi,
      TaxiConvencionalesvsElec,
      investmentBuses,
      percentageBuses,
      projectedBuses,
      busesConvencionalesvsElec,
      investmentCamion,
      percentageCamion,
      projectedCamion,
      camionConvencionalesvsElec,

      columnsInversion,
      columnsVentas,
      columnsElectric,
      columnsVehicles
    })
  }

  getCity = city => {
    if (city == 7) {
      return 'Chile'
    } else if (city == 6) {
      return 'Valdivia'
    } else if (city == 5) {
      return 'Temuco'
    } else if (city == 4) {
      return 'Concepción'
    } else if (city == 3) {
      return 'La Serena'
    } else if (city == 2) {
      return 'Copiapó'
    } else if (city == 1) {
      return 'Santiago'
    }
  }
  componentWillReceiveProps(nextProps) {

    if (nextProps.flagChange == true) {
      if (nextProps.city) {
        var city = GetCity(nextProps.city)
        this.setState({ city }, () => {
        });
      }
      this.allRangeEuro();
    }
  }
  render() {
    const style = {



    };

    var txt = text[this.props.lenguage].tools;
    return (
      <div style={style}>

        <div  >
          <Grid item xs={12}>
            {this.state.investmentPrivados && this.props.visualization === 'graph' ? <BarColum scena={txt} id="1" yaxis={txt.YIAM} text={text[this.props.lenguage].graph.g10 + this.state.city} years={this.state.lineYears} lpv={this.state.investmentPrivados} /> : null}

            {this.state.percentagePrivado && this.props.visualization === 'graph' ? <BarColum scena={txt} id="2" isPercentage={true} yaxis={txt.YVEP} text={text[this.props.lenguage].graph.g11 + this.state.city} years={this.state.lineYears} lpv={this.state.percentagePrivado} /> : null}

            {this.state.projectedPrivate && this.props.visualization === 'graph' ? <Stacked scena={txt} id="3" yaxis={txt.YVE} text={text[this.props.lenguage].graph.g12 + this.state.city} years={this.state.lineYears} lpv={this.state.projectedPrivate} /> : null}

            {this.state.privateConvencionalesvsElec && this.props.visualization === 'graph' ? <Stacked scena={txt} id="4" yaxis={txt.OVehicle} text={text[this.props.lenguage].graph.g13 + this.state.city} years={this.state.lineYears} lpv={this.state.privateConvencionalesvsElec} /> : null}

            {this.state.investmentTaxi && this.props.visualization === 'graph' ? <BarColum scena={txt} id="5" yaxis={txt.YIAM} text={text[this.props.lenguage].graph.g14 + this.state.city} years={this.state.lineYears} lpv={this.state.investmentTaxi} /> : null}

            {this.state.percentageTaxiElectric && this.props.visualization === 'graph' ? <BarColum scena={txt} id="6" isPercentage={true} yaxis={txt.YVEP} text={text[this.props.lenguage].graph.g15 + this.state.city} years={this.state.lineYears} lpv={this.state.percentageTaxiElectric} /> : null}

            {this.state.projectedTaxi && this.props.visualization === 'graph' ? <Stacked scena={txt} id="7" yaxis={txt.YVE} text={text[this.props.lenguage].graph.g16 + this.state.city} years={this.state.lineYears} lpv={this.state.projectedTaxi} /> : null}

            {this.state.TaxiConvencionalesvsElec && this.props.visualization === 'graph' ? <Stacked scena={txt} id="8" yaxis={txt.OVehicle} text={text[this.props.lenguage].graph.g17 + this.state.city} years={this.state.lineYears} lpv={this.state.TaxiConvencionalesvsElec} /> : null}

            {this.state.investmentBuses && this.props.visualization === 'graph' ? <BarColum scena={txt} id="9" yaxis={txt.YIAM} text={text[this.props.lenguage].graph.g18 + this.state.city} years={this.state.lineYears} lpv={this.state.investmentBuses} /> : null}

            {this.state.percentageBuses && this.props.visualization === 'graph' ? <BarColum scena={txt} id="10" isPercentage={true} yaxis={txt.YVEP} text={text[this.props.lenguage].graph.g19 + this.state.city} years={this.state.lineYears} lpv={this.state.percentageBuses} /> : null}

            {this.state.projectedBuses && this.props.visualization === 'graph' ? <Stacked scena={txt} id="11" yaxis={txt.YVE} text={text[this.props.lenguage].graph.g20 + this.state.city} years={this.state.lineYears} lpv={this.state.projectedBuses} /> : null}

            {this.state.busesConvencionalesvsElec && this.props.visualization === 'graph' ? <Stacked scena={txt} id="12" yaxis={txt.OVehicle} text={text[this.props.lenguage].graph.g21 + this.state.city} years={this.state.lineYears} lpv={this.state.busesConvencionalesvsElec} /> : null}

            {this.state.investmentCamion && this.props.visualization === 'graph' ? <BarColum scena={txt} id="13" yaxis={txt.YIAM} text={text[this.props.lenguage].graph.g22 + this.state.city} years={this.state.lineYears} lpv={this.state.investmentCamion} /> : null}

            {this.state.percentageCamion && this.props.visualization === 'graph' ? <BarColum scena={txt} id="14" isPercentage={true} yaxis={txt.YVEP} text={text[this.props.lenguage].graph.g23 + this.state.city} years={this.state.lineYears} lpv={this.state.percentageCamion} /> : null}

            {this.state.projectedCamion && this.props.visualization === 'graph' ? <Stacked scena={txt} id="15" yaxis={txt.YVE} text={text[this.props.lenguage].graph.g24 + this.state.city} years={this.state.lineYears} lpv={this.state.projectedCamion} /> : null}

            {this.state.camionConvencionalesvsElec && this.props.visualization === 'graph' ? <Stacked scena={txt} id="16" yaxis={txt.OVehicle} text={text[this.props.lenguage].graph.g25 + this.state.city} years={this.state.lineYears} lpv={this.state.camionConvencionalesvsElec} /> : null}
          </Grid>

          <Grid item xs={12}>
            {this.state.investmentPrivados && this.props.visualization === 'table' ? <Table columns={this.state.columnsInversion} text={text[this.props.lenguage].graph.g10 + this.state.city} lpv={this.state.investmentPrivados} /> : null}

            {this.state.percentagePrivado && this.props.visualization === 'table' ? <Table columns={this.state.columnsVentas} text={text[this.props.lenguage].graph.g11 + this.state.city} lpv={this.state.percentagePrivado} /> : null}

            {this.state.projectedPrivate && this.props.visualization === 'table' ? <Table columns={this.state.columnsElectric} yaxis={txt.YVE} text={text[this.props.lenguage].graph.g12 + this.state.city} lpv={this.state.projectedPrivate} /> : null}

            {this.state.privateConvencionalesvsElec && this.props.visualization === 'table' ? <Table columns={this.state.columnsVehicles} yaxis={txt.OVehicle} text={text[this.props.lenguage].graph.g13 + this.state.city} lpv={this.state.privateConvencionalesvsElec} /> : null}

            {this.state.investmentTaxi && this.props.visualization === 'table' ? <Table columns={this.state.columnsInversion} text={text[this.props.lenguage].graph.g14 + this.state.city} lpv={this.state.investmentTaxi} /> : null}

            {this.state.percentageTaxiElectric && this.props.visualization === 'table' ? <Table columns={this.state.columnsVentas} text={text[this.props.lenguage].graph.g15 + this.state.city} lpv={this.state.percentageTaxiElectric} /> : null}

            {this.state.projectedTaxi && this.props.visualization === 'table' ? <Table columns={this.state.columnsElectric} yaxis={txt.YVE} text={text[this.props.lenguage].graph.g16 + this.state.city} lpv={this.state.projectedTaxi} /> : null}

            {this.state.TaxiConvencionalesvsElec && this.props.visualization === 'table' ? <Table columns={this.state.columnsVehicles} yaxis={txt.OVehicle} text={text[this.props.lenguage].graph.g17 + this.state.city} lpv={this.state.TaxiConvencionalesvsElec} /> : null}

            {this.state.investmentBuses && this.props.visualization === 'table' ? <Table columns={this.state.columnsInversion} text={text[this.props.lenguage].graph.g18 + this.state.city} lpv={this.state.investmentBuses} /> : null}

            {this.state.percentageBuses && this.props.visualization === 'table' ? <Table columns={this.state.columnsVentas} text={text[this.props.lenguage].graph.g19 + this.state.city} lpv={this.state.percentageBuses} /> : null}

            {this.state.projectedBuses && this.props.visualization === 'table' ? <Table columns={this.state.columnsElectric} yaxis={txt.YVE} text={text[this.props.lenguage].graph.g20 + this.state.city} lpv={this.state.projectedBuses} /> : null}

            {this.state.busesConvencionalesvsElec && this.props.visualization === 'table' ? <Table columns={this.state.columnsVehicles} yaxis={txt.OVehicle} text={text[this.props.lenguage].graph.g21 + this.state.city} lpv={this.state.busesConvencionalesvsElec} /> : null}

            {this.state.investmentCamion && this.props.visualization === 'table' ? <Table columns={this.state.columnsInversion} text={text[this.props.lenguage].graph.g22 + this.state.city} lpv={this.state.investmentCamion} /> : null}

            {this.state.percentageCamion && this.props.visualization === 'table' ? <Table columns={this.state.columnsVentas} text={text[this.props.lenguage].graph.g23 + this.state.city} lpv={this.state.percentageCamion} /> : null}

            {this.state.projectedCamion && this.props.visualization === 'table' ? <Table columns={this.state.columnsElectric} yaxis={txt.YVE} text={text[this.props.lenguage].graph.g24 + this.state.city} lpv={this.state.projectedCamion} /> : null}

            {this.state.camionConvencionalesvsElec && this.props.visualization === 'table' ? <Table columns={this.state.columnsVehicles} yaxis={txt.OVehicle} text={text[this.props.lenguage].graph.g25 + this.state.city} lpv={this.state.camionConvencionalesvsElec} /> : null}
          </Grid>

        </div>
      </div>
    );
  }
}

