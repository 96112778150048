import React from 'react';
import MaterialTable from 'material-table';

export default function TableGlossary(txt) {


  console.log('txt.txt.tableGlossary', txt);

  const [state, setState] = React.useState({
    columns: [
      { title: txt.txt.concept, field: 'concept' },
      { title: txt.txt.description, field: 'description' },

    ],
    data: txt.txt.tableGlossary,
  });

  return (
    <MaterialTable
      title=""
      columns={state.columns}
      data={state.data}
      localization={{ toolbar: { searchPlaceholder: txt.txt.search }, pagination: { labelRowsSelect: txt.txt.rows } }}

    />
  );
}