import React from 'react';
import Slider from 'rc-slider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import text from "../../DB/text"
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { RangeEuros, RangeYears, GetCity } from "../utility/Range"
import XLSX from '@sheet/core';
import { debug } from 'util';
export default class RangePercentage extends React.Component {


  constructor(props) {
    super(props);
    console.log('Range percentage', this.props);
    this.state = {
      petroleo: null,
      diesel: null,
      gas: null,
      electrico: null,
      city: GetCity(props.city),
      suma: null,
      error: false
    }
  }

  componentDidMount() {
    this.allRangeEuro()
  }

  allRangeEuro() {

    var workbook;
    var petroleo = [];
    var diesel = [];
    var gas = [];
    var electrico = [];
    var range = 'D429:G432';

    for (const iterator of this.props.excel) {
      workbook = iterator.Sheets["ETAPA1_BGE"];


      var percentages = XLSX.utils.sheet_to_json(workbook, { range: range, raw: true, header: 1 });
      var p = []
      var d = []
      var e = []
      var g = []

      percentages.forEach(element => {
        p.push(element[0])
        d.push(element[1])
        e.push(element[2])
        g.push(element[3])
      });
      var newPercentage = [p, d, g, e]
      var txt = this.props.textTypeVehicle;
      var index = 0;
      if (txt == 'Private') {
        index = 0;
      } else if (txt == 'Taxis') {
        index = 1;
      } else if (txt == 'Buses') {
        index = 2;
      } else if (txt == 'Camiones') {
        index = 3;
      }

      var petroleofixed = newPercentage[index][0] * 100
      petroleo.push(petroleofixed.toFixed(3))
      var dieselfixed = newPercentage[index][1] * 100
      diesel.push(dieselfixed.toFixed(3))
      var gasFixed = newPercentage[index][3] * 100
      gas.push(gasFixed.toFixed(3))
      var electricoFixed = newPercentage[index][2] * 100
      electrico.push(electricoFixed.toFixed(3))

    }

    var sumArray = [];

    this.setState({
      petroleo,
      diesel,
      gas,
      electrico
    }, () => {


      for (let i = 0; i < this.props.excel.length; i++) {

        sumArray.push(parseFloat(this.state.petroleo[i]) + parseFloat(this.state.diesel[i]) + parseFloat(this.state.gas[i]) + parseFloat(this.state.electrico[i]))
      }
      this.setState({
        suma: sumArray
      })
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.flagChange == true) {
      if (nextProps.city) {
        var city = GetCity(nextProps.city)
        this.setState({ city }, () => {
        });
      }
      this.allRangeEuro();
    }
  }

  handleInput = index => event => {

    var nowState = this.state[event.target.name]
    nowState[index] = event.target.value

    this.setState({
      [event.target.name]: nowState
    }, () => {
      var sumatoria = parseFloat(this.state.petroleo[index]) + parseFloat(this.state.diesel[index]) + parseFloat(this.state.gas[index]) + parseFloat(this.state.electrico[index])

      var nowState = this.state.suma
      nowState[index] = sumatoria
      this.setState({ suma: nowState });
    })

  }

  onClickChangeGdp = (index) => {

    if (this.state.suma[index] === 100) {
      this.setState({ error: false })

      var sendPercentages = [
        this.state.electrico[index], this.state.gas[index], this.state.diesel[index] / 100, this.state.petroleo[index] / 100
      ]
      this.props.getPercentages(sendPercentages, this.props.textTypeVehicle, index)
    } else {
      this.setState({ error: true })
    }

  }
  render() {

    console.log('text[this.props.lenguaje]', text);
    console.log('text[this.props.lenguaje]', this.props.lenguaje);
    return (

      <div>
        {
          this.state.electrico &&
          this.props.excel.map((label, index) => {
            return (
              <div key={index}>
                <div className={"row"}>

                  <Grid item xs={8} style={{ marginLeft: 20 }}>
                    <ul className="collection">
                      <li className="collection-item"> <svg height="20" width="20">
                        <circle cx="10" cy="10" r="5" fill="#ff4560" />
                        Sorry, your browser does not support inline SVG.
                  </svg>
                        <TextField
                          disabled
                          id="electrico"
                          label={text[this.props.lenguaje].tools.fuels[3]}
                          style={{ width: 100, color: 'black !important', fontSize: 19, margin: 0 }}
                          value={this.state.electrico[index]}
                          onChange={this.handleInput(index)}
                          margin="normal"
                          name="electrico"
                          color="black"
                          type="number"
                          fontSize="19"
                          InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                          }}
                        />
                      </li>
                      <li className="collection-item">
                        <svg height="20" width="20">
                          <circle cx="10" cy="10" r="5" fill="#feb019" />
                          Sorry, your browser does not support inline SVG.
                      </svg>
                        <TextField
                          id="gas"
                          label={text[this.props.lenguaje].tools.fuels[2]}
                          style={{ width: 100, color: 'black !important', fontSize: 19, margin: 0 }}
                          value={this.state.gas[index]}
                          onChange={this.handleInput(index)}
                          margin="normal"
                          name="gas"
                          color="black"
                          type="number"
                          fontSize="19"
                          InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                          }}
                        />
                      </li>
                      <li className="collection-item">
                        <svg height="20" width="20">
                          <circle cx="10" cy="10" r="5" fill="#00e396" />
                          Sorry, your browser does not support inline SVG.
                    </svg>
                        <TextField
                          id="diesel"
                          label={text[this.props.lenguaje].tools.fuels[1]}
                          style={{ width: 100, color: 'black !important', fontSize: 19, margin: 0 }}
                          value={this.state.diesel[index]}
                          onChange={this.handleInput(index)}
                          margin="normal"
                          name="diesel"
                          color="black"
                          type="number"
                          fontSize="19"
                          InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                          }}
                        />
                      </li>
                      <li className="collection-item">    <svg height="20" width="20">
                        <circle cx="10" cy="10" r="5" fill="#008ffb" />
                        Sorry, your browser does not support inline SVG.
                    </svg>
                        <TextField
                          id="petroleo"
                          label={text[this.props.lenguaje].tools.fuels[0]}
                          style={{ width: 100, color: 'black !important', fontSize: 19, margin: 0 }}
                          value={this.state.petroleo[index]}
                          onChange={this.handleInput(index)}
                          margin="normal"
                          name="petroleo"
                          color="black"
                          type="number"
                          fontSize="19"
                          InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                          }}
                        />

                      </li>
                      <li className="collection-item">

                        {'Total: '}{this.state.suma && this.state.suma[index]}{'%'}
                        <div>{this.state.error && <span style={{ color: 'red' }}>*El valor total debe ser 100%</span>}</div>
                      </li>
                    </ul>
                  </Grid>

                </div >
                <div>
                  <div style={{ textAlign: "left" }} className={"row"}>
                    <Button style={{ marginLeft: 20 }} onClick={() => this.onClickChangeGdp(index)} variant="contained" color="primary" >
                     {text[this.props.lenguaje].tools.ApplyPercentages}
               </Button>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div >
    );
  }
}