import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const OneElectric = ({ handleInputChange, electricValues, handleSendInfo, type, index, txt }) => {
 
  return (

    <form style={{ flexWrap: 'wrap' }} noValidate autoComplete="off">
      <TextField
        id="standard-c"
        label="delta C"
        style={{ width: 70, color: 'black !important', fontSize: 19, marginLeft: 15, marginRight: 15 }}
        value={electricValues.deltaC}
        onChange={handleInputChange(index)}
        margin="normal"
        name="deltaC"
        color="black"
        type="number"
        fontSize="19"
      />
      <TextField
        id="standard-p"
        label="P"
        style={{ width: 50, color: 'black !important', marginLeft: 15, marginRight: 15 }}
        value={electricValues.p}
        onChange={handleInputChange(index)}
        margin="normal"
        name="p"
        color="black"
        type="number"
      />

      <TextField
        id="standard-q"
        label="q"
        style={{ width: 70, color: 'black !important', marginLeft: 15, marginRight: 15 }}
        value={electricValues.q}
        onChange={handleInputChange(index)}
        margin="normal"
        name="q"
        color="black"
        type="number"
      />

      <TextField
        id="standard-t"
        label="t0"
        style={{ width: 70, color: 'black !important', marginLeft: 15, marginRight: 15 }}
        value={electricValues.t0}
        onChange={handleInputChange(index)}
        margin="normal"
        name="t0"
        color="black"
        type="number"
      />

      <TextField
        id="standard-dp"
        label="deltaP"
        style={{ width: 50, color: 'black !important', marginLeft: 15, marginRight: 15 }}
        value={electricValues.deltaP}
        onChange={handleInputChange(index)}
        margin="normal"
        name="deltaP"
        color="black"
        type="number"
      />

      <TextField
        id="standard-l"
        label="l"
        style={{ width: 80, color: 'black !important', marginLeft: 15, marginRight: 15 }}
        value={electricValues.l}
        onChange={handleInputChange(index)}
        margin="normal"
        name="l"
        color="black"
        type="number"
      />
      <TextField
        id="standard-k"
        label="k"
        style={{ width: 50, color: 'black !important', marginLeft: 15, marginRight: 15 }}
        value={electricValues.k}
        onChange={handleInputChange(index)}
        margin="normal"
        name="k"
        color="black"
        type="number"
      />
      <TextField
        id="standard-t02"
        label="t02"
        style={{ width: 50, color: 'black !important', marginLeft: 15, marginRight: 15 }}
        value={electricValues.t02}
        onChange={handleInputChange(index)}
        margin="normal"
        name="t02"
        color="black"
        type="number"
      />

      <TextField
        id="standard-fcv"
        label="fcv"
        style={{ width: 50, color: 'black !important', marginLeft: 15, marginRight: 15 }}
        value={electricValues.fcv}
        onChange={handleInputChange(index)}
        margin="normal"
        name="fcv"
        color="black"
        type="number"
      />
      <TextField
        id="standard-phev"
        label="phev"
        style={{ width: 50, color: 'black !important', marginLeft: 15, marginRight: 15 }}
        value={electricValues.phev}
        onChange={handleInputChange(index)}
        margin="normal"
        name="phev"
        color="black"
        type="number"
      />
      <TextField
        id="standard-bev"
        label="bev"
        style={{ width: 50, color: 'black !important', marginLeft: 15, marginRight: 15 }}
        value={electricValues.bev}
        onChange={handleInputChange(index)}
        margin="normal"
        name="bev"
        color="black"
        type="number"
      />
      <Button variant="contained" color="primary" style={{
        height: 41,
        top: 20
      }}
        onClick={() => handleSendInfo(type, index)}>
        {txt.calculate}
      </Button>
    </form>
  );
}
export default OneElectric