import React from 'react'
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import BarColum from '../TypeGraphic/BarColum';
import text from "../../DB/text"
import { RangeEuros, RangeYears, GetCity } from "../utility/Range";
import Table from '../TypeGraphic/Table'

export default class TotalParkAgeVehicles extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            city: GetCity(props.city),
            lineYears: null,
            particularesCombustible: null,
            particularesCombustiblePrivate: null,
            columns: [],
            columns2: []
        }
    }

    componentDidMount() {
        this.allRangeEuro()
    }

    allRangeEuro() {


        var workbook
        var particularesCombustible = [];
        var particularesCombustiblePrivate = [];
        for (const iterator of this.props.excel) {
            workbook = iterator.Sheets["ETAPA2_BGE"];
            particularesCombustible.push(RangeEuros('G14:I54', ['Buses', 'Camiones', 'Taxis'], workbook))
            particularesCombustiblePrivate.push(RangeEuros('F14:F54', ['Vehículos Particulares'], workbook));
        }

        var lineYears = RangeYears('E14:E54', workbook)
        var columns = [text[this.props.lenguage].tools.TYPE, text[this.props.lenguage].tools.years, text[this.props.lenguage].tools.YNVP]
        var columns2 = [text[this.props.lenguage].tools.TYPE, text[this.props.lenguage].tools.years, text[this.props.lenguage].tools.YNOVP]

        this.setState({
            lineYears,
            particularesCombustible,
            particularesCombustiblePrivate,
            columns,
            columns2
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.flagChange == true) {
            if (nextProps.city) {
                var city = GetCity(nextProps.city)
                this.setState({ city }, () => {
                });
            }
            this.allRangeEuro();
        }
    }

    render() {
        const style = {
              
              
              
        };

        return (
            <div style={style}>

                <div  >

                    <Grid item xs={12}>
                        {this.state.particularesCombustiblePrivate && this.props.visualization === 'graph' ? <BarColum scena={text[this.props.lenguage].tools} yaxis={text[this.props.lenguage].tools.YNVP} text={text[this.props.lenguage].graph.g26 + this.state.city} years={this.state.lineYears} lpv={this.state.particularesCombustiblePrivate} /> : null}

                        {this.state.particularesCombustible && this.props.visualization === 'graph' ? <BarColum scena={text[this.props.lenguage].tools} yaxis={text[this.props.lenguage].tools.YNOVP} text={text[this.props.lenguage].graph.g27 + this.state.city} years={this.state.lineYears} lpv={this.state.particularesCombustible} /> : null}
                    </Grid>
                    {this.state.particularesCombustiblePrivate && this.props.visualization === 'table' ? <Table text={text[this.props.lenguage].graph.g26 + this.state.city} columns={this.state.columns} lpv={this.state.particularesCombustiblePrivate} /> : null}

                    {this.state.particularesCombustible && this.props.visualization === 'table' ? <Table text={text[this.props.lenguage].graph.g27 + this.state.city} columns={this.state.columns2} lpv={this.state.particularesCombustible} /> : null}
                </div>
            </div>
        );
    }
}

