import React from 'react';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem'

const TechComposition = ({ taxis, camiones, buses, tcVehicle, vpHandleChange, index, txt }) => {
  const arrayList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
  return (
    <div>

      <div className="row">

        <div className="col-md-2" >
          <blockquote style={{ marginLeft: '10px' }}>
            <span style={{ color: '#757575', fontSize: 12 }}>
              {txt.txtEuroSix}
            </span>
          </blockquote>
        </div>

        <div className="col-md-3" >

          <span style={{ color: '#757575' }}>{txt.typeVehiclesArray[0]}</span>
          <Select
            value={tcVehicle}
            onChange={vpHandleChange(index)}
            style={{ marginLeft: 20, height: 30 }}
            input={
              <OutlinedInput
                style={{ borderColor: '#ffa200' }}
                labelWidth={50}
                name="tcVehicle"
                id="outlined-city-simple"
              />
            }>
            {
              arrayList.flatMap(i => (
                <MenuItem key={i} style={{ paddingRight: 41 }} value={i}>  <span style={{ paddingRight: 41 }}> {i} </span>  </MenuItem>
              ))
            }
          </Select>
        </div>
        <div className="col-md-2" >
          <span style={{ color: '#757575' }}>{txt.typeVehiclesArray[1]}</span>
          <Select
            value={buses}
            onChange={vpHandleChange(index)}
            style={{ marginLeft: 20, height: 30 }}
            input={
              <OutlinedInput
                style={{ borderColor: '#ffa200' }}
                labelWidth={50}
                name="buses"
                id="outlined-city-simple"
              />
            }>
            {
              arrayList.flatMap(i => (
                <MenuItem key={i} style={{ paddingRight: 41 }} value={i}>  <span style={{ paddingRight: 41 }}> {i} </span>  </MenuItem>
              ))
            }
          </Select>
        </div>



        <div className="col-md-2" >
          <span style={{ color: '#757575' }}>{txt.typeVehiclesArray[2]}</span>
          <Select
            value={camiones}
            onChange={vpHandleChange(index)}
            style={{ marginLeft: 10, height: 30 }}
            input={
              <OutlinedInput
                style={{ borderColor: '#ffa200' }}
                labelWidth={45}
                name="camiones"
                id="outlined-city-simple"
              />
            }>
            {
              arrayList.flatMap(i => (
                <MenuItem key={i} style={{ paddingRight: 41 }} value={i}>  <span style={{ paddingRight: 41 }}> {i} </span>  </MenuItem>
              ))
            }
          </Select>
        </div>

        <div className="col-md-2" >
          <span style={{ color: '#757575' }}>{txt.typeVehiclesArray[3]}</span>
          <Select
            value={taxis}
            onChange={vpHandleChange(index)}
            style={{ marginLeft: 20, height: 30 }}
            input={
              <OutlinedInput
                style={{ borderColor: '#ffa200' }}
                labelWidth={50}
                name="taxis"
                id="outlined-city-simple"
              />
            }>
            {
              arrayList.flatMap(i => (
                <MenuItem key={i} style={{ paddingRight: 41 }} value={i}>  <span style={{ paddingRight: 41 }}> {i} </span>  </MenuItem>
              ))
            }
          </Select>
        </div>

      </div>
    </div>
  );
}
export default TechComposition