import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import text from "../DB/text"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import routes from "../routes.js";

const styled = {
  root: {
    maxWidth: 400,
    flexGrow: 1,
    position: "fixed",
    transform: "translateX(-50%)",
    alignItems: "center",
    left: "50%",
    justifyContent: "center",
    bottom: "8px"
  },
  buttonCenter: {
    position: "fixed",
    transform: "translateX(-50%)",
    alignItems: "center",
    left: "50%",
    justifyContent: "center",
    bottom: "8px"
  },

  text: {
    textAlign: 'center',
    color: '#fff',
  }
};
const theme = () => useTheme();
// img/option1.jpg

const FunctionStep = ({ img, title, subtitle }) => {
  return (

    <div className="container">
      <div className="row" style={{ height: "30%" }} >
        <div className="col-md-3">
        </div>
        <div className="col-md-6">
          <img src={img} alt={''} style={{ maxWidth: "100%" }} />

        </div>
        <div className="col-md-3">
        </div>
      </div>

      <div className="row" >
        <div className="col-md-3">
        </div>
        <div className="col-md-6">
          <Typography variant="h4" component="h2" style={styled.text}>
            {title}
          </Typography>
        </div>
        <div className="col-md-3">
        </div>
      </div>

      <div className="row" >

        <div className="col-md-12">
          <Typography variant="subtitle1" component="h2" style={styled.text}>
            {subtitle}
          </Typography>
        </div>

      </div>
    </div>
  )
}

class Introduction extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      checked: false,
      color: '#062489',
      img: 'img/opcion1.jpg',
      title: 'Primera Etapa',
      subtible: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo'
    }

    setTimeout(() => {
      this.setState({ checked: true })
    }, 500);
  }


  handleRedirect = () => {
    this.props.history.push('/StepOne/proyectprivate')
  }

  handleNext = () => {
    var prev = this.state.activeStep + 1;
    this.setState({ activeStep: prev })
    this.hanldeColor(prev)
  }

  handleBack = () => {
    var prev = this.state.activeStep - 1
    this.setState({ activeStep: prev })
    this.hanldeColor(prev)
  }


  handleDirection = (direction) => {
    var position = routes.listRoutes.indexOf(this.props.match.url)
    if (direction == 'next') {
      this.props.history.push(routes.listRoutes[position + 1])
    } else {
      this.props.history.push(routes.listRoutes[position - 1])
    }
  }

  hanldeColor(step) {

    switch (step) {
      case 0:
        this.setState({ img: 'img/opcion1.jpg', title: 'Primera Etapa' })
        break;
      case 1:
        this.setState({ img: 'img/opcion2.jpg', title: 'Segunda Etapa' })
        break;
      case 2:
        this.setState({ img: 'img/opcion3.jpg', title: 'Terceta Etapa' })
        break;
      case 3:
        this.setState({ img: 'img/opcion4.jpg', title: 'Cuarta Etapa' })
        break;
      case 4:
        this.setState({ img: 'img/opcion5.jpg', title: 'Resumen' })
        break;


      default:
        break;
    }
  }

  render() {

    const txt = text[this.props.chooseLenguaje].home;
    return (

      <div style={{ backgroundColor: this.state.color, height: '100%' }}>
        <div className="welcome-area wow fadeInUp" id="home" style={{
          background: this.state.color,
          fontFamily: 'Montserrat',
          fontSize: '14px',
          color: '#cbe5ff',
          overflowX: 'hidden', zIndex: 0
        }}>


          {
            this.state.activeStep === 4 &&
            <div style={{ zIndex: 9999, position: 'fixed', bottom: '40%', right: '20px', float: 'right' }}>
              <IconButton style={{ padding: '5px', background: '#dadada' }} onClick={() => this.handleDirection('next')} name="before">
                <ChevronRightIcon style={{ height: 35, width: 35 }} />
              </IconButton>
            </div>

          }


          {this.state.img && <FunctionStep img={this.state.img} title={this.state.title} subtitle={this.state.subtible} />}


          <div className="row" >
            <div className="col-md-3">
            </div>
            <div className="col-md-6">
              <div >
                <MobileStepper
                  variant="dots"
                  steps={5}
                  position="static"
                  activeStep={this.state.activeStep}
                  nextButton={
                    <Button size="small" onClick={() => this.handleNext()} disabled={this.state.activeStep === 4}>
                      Siguiente
           {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                  }
                  backButton={
                    <Button size="small" onClick={() => this.handleBack()} disabled={this.state.activeStep === 0}>
                      {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                      Anterior
        </Button>
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
            </div>
          </div>
        </div>

      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return { chooseLenguaje: state.lenguaje };
};
const mapDispatchToProps = dispatch => {
  return {
  };
};


const AppScreen = connect(mapStateToProps, mapDispatchToProps)(Introduction);
export default AppScreen;

