import React from 'react'
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import BarColum from '../TypeGraphic/BarColum';
import text from "../../DB/text"
import { RangeEuros, RangeYears, GetCity } from "../utility/Range";
import Table from '../TypeGraphic/Table'

export default class TotalParkAgeVehicleFuel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            city: GetCity(props.city),
            lineYears: null,
            particularesCombustible: null,
            particularesCombustible: null,
            taxiCombustible: null,
            camionCombustible: null,
            busCombustible: null,
            columns: [],
            columns2: [],
            columns3: [],
            columns4: []
        }
    }

    componentDidMount() {
        this.allRangeEuro()
    }

    allRangeEuro() {

        var workbook
        var particularesCombustible = [];
        var taxiCombustible = [];
        var camionCombustible = [];
        var busCombustible = [];
        var txt = text[this.props.lenguage].tools;
        var combustibles = txt.arrayFuels;
        for (const iterator of this.props.excel) {
            workbook = iterator.Sheets["ETAPA2_BGE"];
            particularesCombustible.push(RangeEuros('F119:I159', combustibles, workbook))
            taxiCombustible.push(RangeEuros('J119:M159', combustibles, workbook))
            camionCombustible.push(RangeEuros('N119:Q159', combustibles, workbook))
            busCombustible.push(RangeEuros('R119:U159', combustibles, workbook))
        }
        var lineYears = RangeYears('E119:E159', workbook);

        var columns = [txt.TYPEC, txt.years, txt.YNVP]
        var columns2 = [txt.TYPEC, txt.years, txt.YTaxi]
        var columns3 = [txt.TYPEC, txt.years, txt.YTruck]
        var columns4 = [txt.TYPEC, txt.years, txt.YBuses]

        this.setState({
            lineYears,
            particularesCombustible,
            taxiCombustible,
            camionCombustible,
            busCombustible,
            columns,
            columns2,
            columns3,
            columns4
        })
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.flagChange == true) {
            if (nextProps.city) {
                var city = GetCity(nextProps.city)
                this.setState({ city }, () => {
                });
            }
            this.allRangeEuro();
        }
    }

    render() {

        var txt = text[this.props.lenguage].tools;
        return (
            <div >
                <div  >
                    <Grid item xs={12}>
                        {this.state.particularesCombustible && this.props.visualization === 'graph' ? <BarColum scena={txt} yaxis={txt.YNVP} text={text[this.props.lenguage].graph.g28 + this.state.city} years={this.state.lineYears} lpv={this.state.particularesCombustible} /> : null}
                        {this.state.taxiCombustible && this.props.visualization === 'graph' ? <BarColum scena={txt} yaxis={txt.YTaxi} text={text[this.props.lenguage].graph.g29 + this.state.city} years={this.state.lineYears} lpv={this.state.taxiCombustible} /> : null}
                        {this.state.camionCombustible && this.props.visualization === 'graph' ? <BarColum scena={txt} yaxis={txt.YTruck} text={text[this.props.lenguage].graph.g30 + this.state.city} years={this.state.lineYears} lpv={this.state.camionCombustible} /> : null}
                        {this.state.busCombustible && this.props.visualization === 'graph' ? <BarColum scena={txt} yaxis={txt.YBuses} text={text[this.props.lenguage].graph.g31 + this.state.city} years={this.state.lineYears} lpv={this.state.busCombustible} /> : null}
                    </Grid>
                    {this.state.particularesCombustible && this.props.visualization === 'table' ? <Table text={text[this.props.lenguage].graph.g28 + this.state.city} columns={this.state.columns} lpv={this.state.particularesCombustible} /> : null}
                    {this.state.taxiCombustible && this.props.visualization === 'table' ? <Table text={text[this.props.lenguage].graph.g29 + this.state.city} columns={this.state.columns2} lpv={this.state.taxiCombustible} /> : null}
                    {this.state.camionCombustible && this.props.visualization === 'table' ? <Table text={text[this.props.lenguage].graph.g30 + this.state.city} columns={this.state.columns3} lpv={this.state.camionCombustible} /> : null}
                    {this.state.busCombustible && this.props.visualization === 'table' ? <Table text={text[this.props.lenguage].graph.g31 + this.state.city} columns={this.state.columns4} lpv={this.state.busCombustible} /> : null}
                </div>
            </div>
        );
    }
}

