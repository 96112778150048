import React from 'react';
import Particles from 'react-particles-js';
export default class Loader extends React.Component {

  render() {
    return (
      <div style={{ backgroundColor: '#130a52', overflowX: 'hidden' }}>
        <Particles style={{
          position: 'fixed'
        }}
          params={{
            "particles": {
              "number": {
                "value": 50
              },
              "size": {
                "value": 3
              }
            },
            "interactivity": {
              "events": {
                "onhover": {
                  "enable": true,
                  "mode": "repulse"
                }
              }
            }
          }}
        />
        <div className="row" style={{ height: '720px', marginTop: '-8%' }}>

          <div className="col-6 col-sm-6">
            <div className="welcome-img">

            </div>
          </div>


        </div>
      </div>
    )
  }
}