import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';

const VariationDistances = ({ txt, handleInputChange, vdValues, handleSendInfo, index }) => {
  return (
    <form style={{ flexWrap: 'wrap', }} noValidate autoComplete="off">
      <blockquote style={{ marginLeft: '10px', height: 20, marginTop: 30 }}>
        <Typography className={{ color: '#777777' }}> {txt.distanceVariation}</Typography>

      </blockquote>
      <TextField
        id="standard-name"
        label={txt.vehiclesArray[0]}
        style={{ width: 100, color: 'black !important', fontSize: 19, marginLeft: 20, marginRight: 20 }}
        value={vdValues.autos}
        onChange={handleInputChange(index)}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
        margin="normal"
        name="autos"
        color="black"
        type="number"
        fontSize="19"
      />

      <TextField
        id="standard-name"
        label={txt.vehiclesArray[1]}
        style={{ width: 100, color: 'black !important', marginLeft: 20, marginRight: 20 }}
        value={vdValues.buses}
        onChange={handleInputChange(index)}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
        margin="normal"
        name="buses"
        color="black"
        type="number"
      />

      <TextField
        id="standard-name"
        label={txt.vehiclesArray[2]}
        style={{ width: 100, color: 'black !important', marginLeft: 20, marginRight: 20 }}
        value={vdValues.camiones}
        onChange={handleInputChange(index)}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
        margin="normal"
        name="camiones"
        color="black"
        type="number"
      />

      <TextField
        id="standard-name"
        label={txt.vehiclesArray[3]}
        style={{ width: 100, color: 'black !important', marginLeft: 20, marginRight: 20 }}
        value={vdValues.taxis}
        onChange={handleInputChange(index)}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
        margin="normal"
        name="taxis"
        color="black"
        type="number"
      />

      <Button variant="contained" color="primary" style={{
        height: 41,
        top: 20
      }}
        onClick={() => handleSendInfo(index)}>
        {txt.calculate}
      </Button>
    </form>
  );
}
export default VariationDistances