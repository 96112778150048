import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';

export default class Table extends Component {

  constructor(props) {
    super(props);
    var array = [];
    var value = 0;
    for (let i = 0; i < props.lpv[0].length; i++) {
      for (let e = 0; e < props.lpv[0][i].data.length; e++) {
        if (this.props.isPercentage) {
          value = props.lpv[0][i].data[e] * 100;
          value = value.toFixed(1) + '%'
          array.push({ lv: props.lpv[0][i].name, years: e, data: value })
        } else {
          array.push({ lv: props.lpv[0][i].name, years: e, data: props.lpv[0][i].data[e].toLocaleString("es-CL") })
        }
      }
    }


    this.state = {
      dataTable: array
    };
  }

  export = () => {
    this.dt.exportCSV();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lpv[0] !== this.props.lpv[0]) {
      var array = [];
      var value = 0;
      for (let i = 0; i < nextProps.lpv[0].length; i++) {
        for (let e = 0; e < nextProps.lpv[0][i].data.length; e++) {
          if (this.props.isPercentage) {
            value = nextProps.lpv[0][i].data[e] * 100;
            value = value.toFixed(1) + '%'
            array.push({ lv: nextProps.lpv[0][i].name, years: e, data: value })
          } else {
            array.push({ lv: nextProps.lpv[0][i].name, years: e, data: nextProps.lpv[0][i].data[e].toLocaleString("es-CL") })
          }
        }
      }
      this.setState({ dataTable: array });

    }
  }

  render() {
    const listName = ['lv', 'years', 'data']
    var header = <div style={{ 'textAlign': 'left' }}>
      <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
      <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Busqueda Global" size="50" />
      <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
    </div>;
    return (


      <div className={'row'} >
        <div className="content-section implementation">
          <h4 style={{ color: '#1c2e78', textAlign: 'center' }}>{this.props.text}</h4>

          {this.props.columns &&

            <DataTable ref={(el) => this.dt = el} value={this.state.dataTable} paginator={true} rows={10} header={header}
              globalFilter={this.state.globalFilter}>

              {this.props.columns.map((x, index) => {
                return <Column key={index} field={listName[index]} header={x} filter={true} />
              })}


            </DataTable>
          }



        </div>

      </div>
    );
  }
}