import React from 'react'
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Lpv from '../TypeGraphic/Lpv';
import text from "../../DB/text"
import { RangeEuros, RangeYears, GetCity } from "../utility/Range"
import Table from '../TypeGraphic/Table'

export default class VehiclesPeerThousand extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      city: GetCity(props.city),
      lineYears: null,
      investmentPrivados: null,
      columns: []
    }
  }

  componentDidMount() {
    this.allRangeEuro()
  }

  allRangeEuro() {

    var workbook
    var investmentPrivados = [];
    for (const iterator of this.props.excel) {
      workbook = iterator.Sheets["ETAPA1_BGE"];
      investmentPrivados.push(RangeEuros('E61:H83', ['500', '600', '700', '800'], workbook))
    }

    var lineYears = RangeYears('D61:D83', workbook);
    var columns = [text[this.props.lenguage].tools.config103, text[this.props.lenguage].tools.years, text[this.props.lenguage].tools.YVPM]

    this.setState({
      lineYears,
      investmentPrivados,
      columns
    })
  }

  componentWillReceiveProps(nextProps) {
    var city = GetCity(nextProps.city);
    if (nextProps.flagChange == true) {
      this.setState({ city }, () => {
      });
      this.allRangeEuro();
    }
  }

  render() {
    const style = {
        
        
        
    };

    return (
      <div style={style}>

        <div  >
          <Grid item xs={12}>
            {this.state.investmentPrivados && this.props.visualization === 'graph' ? <Lpv scena={text[this.props.lenguage].tools} yaxis={text[this.props.lenguage].tools.YVPM} text={text[this.props.lenguage].graph.g2 + this.state.city} years={this.state.lineYears} lpv={this.state.investmentPrivados} /> : null}

            {this.state.investmentPrivados && this.props.visualization === 'table' ? <Table text={text[this.props.lenguage].graph.g2 + this.state.city} columns={this.state.columns} lpv={this.state.investmentPrivados} /> : null}
          </Grid>

        </div>
      </div>
    );
  }
}

