import React from 'react';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';

const City = ({ txt, index, city, handleChange }) => {
  return (
    <blockquote>

      <span style={{ color: '#757575' }}>{txt.city}</span>

      <Select
        value={city}
        onChange={handleChange(index)}
        style={{ marginLeft: 20 }}
        input={
          <OutlinedInput
            style={{ borderColor: '#ffa200' }}
            labelWidth={50}
            name="city"
            id="outlined-city-simple"
          />
        }>
        <MenuItem style={{ paddingRight: 41 }} value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={1}> <span style={{ paddingRight: 41 }}> Gran Santiago</span> </MenuItem>
        <MenuItem style={{ paddingRight: 41 }} value={2}>  <span style={{ paddingRight: 41 }}> Copiapo</span>  </MenuItem>
        <MenuItem style={{ paddingRight: 41 }} value={3}>  <span style={{ paddingRight: 41 }}> La serena</span> </MenuItem>
        <MenuItem style={{ paddingRight: 41 }} value={4}>  <span style={{ paddingRight: 41 }}> Concepción</span> </MenuItem>
        <MenuItem style={{ paddingRight: 41 }} value={5}>  <span style={{ paddingRight: 41 }}> Temuco</span></MenuItem>
        <MenuItem style={{ paddingRight: 41 }} value={6}>  <span style={{ paddingRight: 41 }}> Valdivia</span> </MenuItem>
        <MenuItem style={{ paddingRight: 41 }} value={7}>  <span style={{ paddingRight: 41 }}> Chile</span> </MenuItem>
      </Select>
    </blockquote>
  );
}
export default City