import React from 'react';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const ToolsStepFourOne = ({ variationTaxi, variationBus, variationCamion, growthPopulation, growthGdp, handleChange, lvSaturation, handleSendInfo, input, index, txt }) => {
  return (

    <form style={{ flexWrap: 'wrap', display: 'flex' }} noValidate autoComplete="off">

      <FormControl >
        <InputLabel style={{ marginLeft: 15 }} htmlFor="variationTaxi">{txt.arrayVehicles[3]}</InputLabel>
        <Select
          value={variationTaxi}
          onChange={handleChange(index)}
          style={{ marginLeft: 10, height: 50, paddingTop: 10 }}
          input={
            <OutlinedInput
              style={{ borderColor: '#ffa200' }}
              labelWidth={50}
              name="variationTaxi"
              id="variationTaxi"
            />
          }>
          {
            Array.from(new Array(100), (val, i) => (
              <MenuItem key={i} style={{ paddingRight: 41 }} value={i}>  <span style={{ paddingRight: 41 }}> {i} %</span>  </MenuItem>
            ))
          }
        </Select>

      </FormControl>
      <div style={{ marginLeft: 20 }}>
        <FormControl >

          <InputLabel style={{ marginLeft: 15 }} htmlFor="variationBus">{txt.arrayVehicles[1]}</InputLabel>
          <Select
            value={variationBus}
            onChange={handleChange(index)}
            style={{ marginLeft: 10, height: 50, paddingTop: 10 }}
            input={
              <OutlinedInput
                style={{ borderColor: '#ffa200' }}
                labelWidth={50}
                name="variationBus"
                id="variationBus"
              />
            }>
            {
              Array.from(new Array(100), (val, i) => (
                <MenuItem key={i} style={{ paddingRight: 41 }} value={i}>  <span style={{ paddingRight: 41 }}> {i} %</span>  </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </div>
      <div style={{ marginLeft: 20 }}>
        <div style={{ marginLeft: '5px' }}>
          <FormControl >
            <InputLabel style={{ marginLeft: 15 }} htmlFor="variationCamion">{txt.arrayVehicles[2]}</InputLabel>
            <Select
              value={variationCamion}
              onChange={handleChange(index)}
              style={{ marginLeft: 10, height: 50, paddingTop: 10 }}
              input={
                <OutlinedInput
                  style={{ borderColor: '#ffa200' }}
                  labelWidth={50}
                  name="variationCamion"
                  id="variationCamion"
                />
              }>
              {
                Array.from(new Array(100), (val, i) => (
                  <MenuItem key={i} style={{ paddingRight: 41 }} value={i}>  <span style={{ paddingRight: 41 }}> {i} %</span>  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </div>
      </div>

      <div style={{ marginLeft: 20 }}>
        <FormControl >
          <InputLabel style={{ marginLeft: 15 }} htmlFor="growthPopulation">{txt.population}</InputLabel>
          <Select
            value={growthPopulation}
            onChange={handleChange(index)}
            style={{ marginLeft: 10, height: 50, paddingTop: 10 }}
            input={
              <OutlinedInput
                style={{ borderColor: '#ffa200' }}
                labelWidth={50}
                name="growthPopulation"
                id="growthPopulation"
              />
            }>
            {
              Array.from(new Array(100), (val, i) => (
                <MenuItem key={i} style={{ paddingRight: 21, height: '20px !important' }} value={i}>  <span style={{ paddingRight: 21 }}> {i} %</span>  </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </div>
      <div style={{ marginLeft: 20 }}>
        <FormControl >
          <InputLabel style={{ marginLeft: 15 }} htmlFor="growthGdp">GDP</InputLabel>
          <Select
            value={growthGdp}
            onChange={handleChange(index)}
            style={{ marginLeft: 10, height: 50, paddingTop: 10 }}
            input={
              <OutlinedInput
                style={{ borderColor: '#ffa200' }}
                labelWidth={50}
                name="growthGdp"
                id="growthGdp"
              />
            }>
            {
              Array.from(new Array(100), (val, i) => (
                <MenuItem key={i} style={{ paddingRight: 21 }} value={i}>  <span style={{ paddingRight: 21 }}> {i} %</span>  </MenuItem>
              ))
            }
          </Select>

        </FormControl>
      </div>


      <div style={{ marginLeft: 20 }}>
        <FormControl >
          <InputLabel style={{ marginLeft: 15 }} htmlFor="lvSaturation">{txt.saturation}</InputLabel>
          <Select
            value={lvSaturation}
            onChange={handleChange(index)}
            style={{ marginLeft: 10, height: 50, paddingTop: 10, width: 100 }}
            input={
              <OutlinedInput
                style={{ borderColor: '#ffa200' }}
                labelWidth={100}
                name="lvSaturation"
                id="lvSaturation"
              />
            }>
            {
              [500, 600, 700, 800].flatMap(i => (
                <MenuItem key={i} style={{ paddingRight: 41 }} value={i}>  <span style={{ paddingRight: 41 }}> {i} </span>  </MenuItem>

              ))
            }
          </Select>

        </FormControl>
      </div>
      <div style={{ marginLeft: 20 }}>
        <FormControl >

          <InputLabel style={{ marginLeft: 15 }} htmlFor="input">{txt.growthcars}</InputLabel>
          <Select
            value={input}
            onChange={handleChange(index)}
            style={{ marginLeft: 10, height: 50, paddingTop: 10, width: 130 }}
            input={
              <OutlinedInput
                style={{ borderColor: '#ffa200' }}
                labelWidth={100}
                name="input"
                id="input"
              />
            }>
            {
              Array.from(new Array(100), (val, i) => (
                <MenuItem key={i} style={{ paddingRight: 21 }} value={i}>  <span style={{ paddingRight: 21 }}> {i} %</span>  </MenuItem>
              ))
            }
          </Select>

        </FormControl>
      </div>
      <div style={{ marginLeft: 20, marginBottom: 10 }}>
        <Button variant="contained" color="primary" style={{
          height: 41,

        }}
          onClick={() => handleSendInfo(index)}>
          {txt.calculate}
        </Button>
      </div>

    </form>
  );
}
export default ToolsStepFourOne