import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Favorite from '@material-ui/icons/Favorite';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Home from '@material-ui/icons/Home';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import Filter1 from '@material-ui/icons/LooksOne';
import Filter2 from '@material-ui/icons/LooksTwo';
import Filter3 from '@material-ui/icons/Looks3';
import Filter4 from '@material-ui/icons/Looks4';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { lenguaje } from '../actionCreators';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import routes from "../routes.js";
import { Link, Route } from "react-router-dom";
import text from '../DB/text';
import { connect } from "react-redux";


const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%'

  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    height: '100%',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});


class Template extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      openList: this.props.location.pathname.indexOf("tep") > -1 ? 0 : 10,
      selectLenguaje: this.props.chooseLenguaje == "español" ? true : false,
      lenguaje: this.props.chooseLenguaje
    };

  }


  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleChange = name => event => {


    if (event.target.checked == false) {
      localStorage.setItem('lenguaje', 'english');
      window.location.reload()
    } else {
      localStorage.setItem('lenguaje', 'español');
      window.location.reload()
    }

    this.setState({ [name]: event.target.checked });
  };

  handleClick = id => event => {
    if (this.state.openList == id) {

      this.setState({ openList: 0 });

    } else {
      this.setState({ openList: id });
    }
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />


        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
          color="inherit"
          style={{ backgroundColor: '#1c2e78', color: 'white' }}
        >
          <Toolbar disableGutters={!this.state.open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: this.state.open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>

              {this.props.chooseLenguaje == "español" && "Modelo Guess"}
              {this.props.chooseLenguaje == "english" && "Guess Model"}
            </Typography>

            <FormGroup row style={{ marginLeft: '70%' }} >
              <FormControlLabel

                value="EN"
                control={<div></div>}
                label={<Typography style={{ color: '#f4f5f9' }} >ENG</Typography>}

              />
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.selectLenguaje}
                    onChange={this.handleChange('selectLenguaje')}
                    value="selectLenguaje"
                  />
                }
                label={<Typography style={{ color: '#f4f5f9' }} >ESP</Typography>}
              />
            </FormGroup>

          </Toolbar>



        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={this.state.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />



          <List>
            <Link style={{ color: '#000000de' }} to={"/"}>
              <ListItem onClick={this.handleClick(10)} button key={'Inicio'}>
                <ListItemIcon>
                  <Home lenguaje={this.props.chooseLenguaje} />
                </ListItemIcon>
                <ListItemText primary={text[this.state.lenguaje].tools.start} />
              </ListItem>
            </Link>

            <Link style={{ color: '#000000de' }} to={"/Introduction"}>
              <ListItem onClick={this.handleClick(10)} button key={'Introduccion'}>
                <ListItemIcon>
                  <LocalLibrary lenguaje={this.props.chooseLenguaje} />
                </ListItemIcon>
                <ListItemText primary={text[this.state.lenguaje].tools.introduction} />
              </ListItem>
            </Link>

          </List>
          <Divider />
          <List>
            <ListItem button onClick={this.handleClick(1)} key={'Etapa 1'}>
              <ListItemIcon>
                <Filter1 />
              </ListItemIcon>
              <ListItemText primary={text[this.state.lenguaje].tools.stepOne} />
              {this.state.openList === 1 ? < ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openList === 1 ? true : false} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {routes.stepOne.map((route, index) => (
                  <Link key={index + 'a'} style={{ display: 'block', height: '100%', color: '#000000de' }} to={route.layout + route.path}>
                    <ListItem button className={classes.nested}>
                      <ListItemText primary={route.name[this.state.lenguaje]} />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Collapse>


            <ListItem button onClick={this.handleClick(2)} key={'Etapa 2'}>
              <ListItemIcon>
                <Filter2 />
              </ListItemIcon>
              <ListItemText primary={text[this.state.lenguaje].tools.stepTwo} />
              {this.state.openList === 2 ? < ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openList === 2 ? true : false} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {routes.stepTwo.map((route, index) => (
                  <Link key={index + 'b'} style={{ display: 'block', height: '100%', color: '#000000de' }} to={route.layout + route.path}>
                    <ListItem button className={classes.nested}>
                      <ListItemText primary={route.name[this.state.lenguaje]} />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Collapse>

            <ListItem button onClick={this.handleClick(3)} key={'Etapa 3'}>
              <ListItemIcon>
                <Filter3 />
              </ListItemIcon>
              <ListItemText primary={text[this.state.lenguaje].tools.stepThree} />
              {this.state.openList === 3 ? < ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openList === 3 ? true : false} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {routes.stepThree.map((route, index) => (
                  <Link key={index + 'c'} style={{ display: 'block', height: '100%', color: '#000000de' }} to={route.layout + route.path}>
                    <ListItem button className={classes.nested}>
                      <ListItemText primary={route.name[this.state.lenguaje]} />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Collapse>

            <ListItem onClick={this.handleClick(4)} button key={'Etapa 4'}>
              <ListItemIcon>
                <Filter4 />
              </ListItemIcon>
              <ListItemText primary={text[this.state.lenguaje].tools.stepFour} />
              {this.state.openList === 4 ? < ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openList === 4 ? true : false} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {routes.stepFour.map((route, index) => (
                  <Link key={index + 'd'} style={{ display: 'block', height: '100%', color: '#000000de' }} to={route.layout + route.path}>
                    <ListItem button className={classes.nested}>
                      <ListItemText primary={route.name[this.state.lenguaje]} />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Collapse>
          </List>
          <Divider />

          <List>
            <Link style={{ color: '#000000de' }} to={"/gratitude"}>
              <ListItem onClick={this.handleClick(10)} button key={'Agradecimientos'}>
                <ListItemIcon>
                  <Favorite lenguaje={this.props.chooseLenguaje} />
                </ListItemIcon>
                <ListItemText primary={text[this.state.lenguaje].tools.gratitude} />
              </ListItem>
            </Link>
          </List>

        </Drawer>
        <main className={classes.content} onMouseOver={this.handleDrawerClose} style={this.props.location.pathname === "/" ? { backgroundColor: '#062489', height: '100%' } : { backgroundColor: '#fafafa', height: '100%' }} >
          <div className={classes.toolbar} />

          {routes.routes.map((route, index) => (
            <Route
              exact
              key={index}
              path={route.layout + route.path}
              component={route.component}
            />
          ))}
        </main>
      </div >
    );
  }
}





const mapStateToProps = (state) => {
  return { chooseLenguaje: state.lenguaje };
};
const mapDispatchToProps = dispatch => {
  return {
    lenguaje: datos => dispatch(lenguaje(datos)),
  };
};

const App = withStyles(styles, { withTheme: true })(Template);

const AppScreen = connect(mapStateToProps, mapDispatchToProps)(App);
export default AppScreen;

