import React from 'react'
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import BarColum from '../TypeGraphic/BarColum';
import text from "../../DB/text"
import { RangeEuros, RangeYears, GetCity } from "../utility/Range"
import Table from '../TypeGraphic/Table'

export default class ParkAge extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      parkStgo: null,
      lpvDiesel: null,

      city: GetCity(props.city),
      yearEuroDefined: 1,
      busesBencineros: null,
      busesDiesel: null,
      camionesBencineros: null,
      camionesDiesel: null,
      taxisBencineros: null,
      taxisDiesel: null,
      lineYears: null,
      columns: [],
    }
  }


  componentDidMount() {
    this.allRangeEuro()
  }

  allRangeEuro() {

    var workbook
    var parkStgo = [];
    for (const iterator of this.props.excel) {
      workbook = iterator.Sheets["ETAPA2_BGE"];
      console.log(this.props.lenguage)
      parkStgo.push(RangeEuros('D583:G603', text[this.props.lenguage].tools.typeVehiclesArray, workbook))
    }

    var lineYears = RangeYears('C583:C603', workbook);
    var columns = [text[this.props.lenguage].tools.TYPE, text[this.props.lenguage].tools.years, text[this.props.lenguage].tools.YEP]

    this.setState({ parkStgo, lineYears, columns })
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.flagChange == true) {
      if (nextProps.city) {
        var city = GetCity(nextProps.city)
        this.setState({ city }, () => {
        });
      }
      this.allRangeEuro();
    }
  }
  render() {
    const style = {



    };

    return (
      <div style={style}>

        <div  >
          <Grid item xs={12}>
            {this.state.parkStgo && this.props.visualization === 'graph' ? <BarColum scena={text[this.props.lenguage].tools} yaxis={text[this.props.lenguage].tools.YEP} text={text[this.props.lenguage].graph.g41 + this.state.city} years={this.state.lineYears} lpv={this.state.parkStgo} /> : null}

            {this.state.parkStgo && this.props.visualization === 'table' ? <Table columns={this.state.columns} text={text[this.props.lenguage].graph.g41 + this.state.city} lpv={this.state.parkStgo} /> : null}

          </Grid>
        </div>
      </div>
    );
  }
}

