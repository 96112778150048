
import React from 'react';
import BaseDistance from '../Components/SteepThree/baseDistance';
import KmVehicles from '../Components/SteepThree/KmVehicles';
import Introduction from '../Components/Introduction';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import text from "../DB/text"
import OptionsView from '../Components/Tools/OptionsView';
import VariationDistances from '../Components/Tools/VariationDistances';
import City from '../Components/Tools/City';
import routes from "../routes.js";
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import images from '../DB/images';
import { connect } from "react-redux";

import LinearDeterminate from "../Components/utility/LinearDeterminate"
import {
  StepThreeVD,
  StepThreeVK,
  CityChange
} from "../Components/utility/SetInputs"
import XLSX from '@sheet/core';
import S5SCalc from '@sheet/formula';
import { excel, city } from '../actionCreators';
S5SCalc.XLSXLib = XLSX;


class StepThree extends React.Component {
  constructor(props) {
    super(props);

    var Inputs = this.setDefaultValues(props.excel);


    this.state = {
      tab: 0,
      visualization: "graph",
      Inputs,
      loading: false
    }
  }


  setDefaultValues = (excel) => {

    var Inputs = [];


    for (let index = 0; index < excel.length; index++) {

      var valDefaul = excel[index].Sheets.ETAPA3_BGE

      var objAuxInput = {
        vdValues: {
          autos: valDefaul.D170.v * 100,
          buses: valDefaul.E170.v * 100,
          camiones: valDefaul.F170.v * 100,
          taxis: valDefaul.G170.v * 100
        },
        vkValues: {
          autos: valDefaul.D255.v * 100,
          buses: valDefaul.E255.v * 100,
          camiones: valDefaul.F255.v * 100,
          taxis: valDefaul.G255.v * 100
        },
      }

      objAuxInput.index = index
      Inputs.push(JSON.parse(JSON.stringify(objAuxInput)));
    }
    return Inputs
  }

  handleChange = index => event => {

    this.setState(prevState => ({
      Inputs: prevState.Inputs.map(
        obj => (obj.index === index ? Object.assign(obj, { [event.target.name]: event.target.value }) : obj)
      )
    }));


    if (event.target.name === 'city') {
      var value = event.target.value;
      var valueInt = parseInt(value);
      this.setState({
        loading: true
      }, () => {
        setTimeout(() => {
          this.props.changeCity({ city: event.target.value });
          var workbook = this.props.excel[0];
          var prom = CityChange(event.target.value, workbook);
          prom.then((workbook) => {
            this.props.changeCity({ city: valueInt });
            var newWorkbook = []

            newWorkbook.push(JSON.parse(JSON.stringify(workbook)));
            newWorkbook.push(JSON.parse(JSON.stringify(workbook)));

            this.props.changeExcel({ workbook: newWorkbook })
            var newValuesDefault = this.setDefaultValues(newWorkbook)
            this.setState({
              Inputs: newValuesDefault
            });
            this.setState({ [event.target.name]: event.target.value });
            this.setState({ flagChange: true, loading: false }, () => {
              this.setState({ flagChange: false }, () => {
              })
            })
          }).catch(function (error) {
            // ops, mom don't buy it
            console.log(error.message);
          });
        }, 1000);
      })
    }
  };


  handleChangeTabs = (event, tab) => {
    this.setState({ tab });
  };

  handleInputChange = index => event => {
    var nowState = this.state.Inputs
    nowState[index].vdValues[event.target.name] = event.target.value
    this.setState({
      Inputs: nowState
    });
  }
  handleInputChangeVk = index => event => {
    var nowState = this.state.Inputs
    nowState[index].vkValues[event.target.name] = event.target.value
    this.setState({
      Inputs: nowState
    });
  }

  handleSendInfo = (index) => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => {
        var workbook = this.props.excel[index];
        var val = this.state.Inputs[index].vdValues
        workbook = StepThreeVD(val, workbook);
        var sendWorkbook = this.props.excel;
        sendWorkbook[index] = workbook
        this.props.changeExcel({ workbook: sendWorkbook })

        this.setState({ flagChange: true, loading: false }, () => {
          this.setState({ flagChange: false }, () => {
          })
        })
      }, 1000);
    })
  }

  handleVizualization = event => {
    this.setState({ visualization: event.target.value });
  };

  handleSendInfoVk = (index) => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => {
        var workbook = this.props.excel[index];
        var val = this.state.Inputs[index].vkValues
        workbook = StepThreeVK(val, workbook);
        var sendWorkbook = this.props.excel;
        sendWorkbook[index] = workbook
        this.props.changeExcel({ workbook: sendWorkbook })

        this.setState({ flagChange: true, loading: false }, () => {
          this.setState({ flagChange: false }, () => {
          })
        })
      }, 1000);
    })
  }

  handleDirection = (direction) => {
    var position = routes.listRoutes.indexOf(this.props.match.url)
    if (direction == 'next') {
      this.props.history.push(routes.listRoutes[position + 1])
    } else {
      this.props.history.push(routes.listRoutes[position - 1])
    }
  }

  render() {
    const txt = text[this.props.lenguaje]
    return (
      <div style={{ paddingTop: '24px', paddingLeft: '64px', paddingRight: '64px', }}>
        {this.state.loading === true &&
          <div style={{ marginTop: '5%', zIndex: 9999, }}>
            <LinearDeterminate /> </div>}

        {this.state.loading === false && <div>  <div>
          <div style={{ marginTop: '5%' }}>

            <div style={{ position: 'fixed', bottom: '40%', left: 8 }}>
              <IconButton style={{ padding: '5px', background: '#dadada4d' }} onClick={() => this.handleDirection('before')} name="before">
                <ChevronLeftIcon style={{ height: 35, width: 35 }} />
              </IconButton>
            </div>
            <div style={{ position: 'fixed', bottom: '40%', right: '7px', float: 'right' }}>
              <IconButton style={{ padding: '5px', background: '#dadada4d' }} onClick={() => this.handleDirection('next')} name="before">
                <ChevronRightIcon style={{ height: 35, width: 35 }} />
              </IconButton>
            </div>

            {this.props.match.params.id === 'basedistance' ?
              <div>
                <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.tab}
                    onChange={this.handleChangeTabs}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label={txt.tools.intro} />
                    <Tab label={txt.tools.info} />
                    <Tab label={txt.tools.config} />
                    <Tab label={txt.tools.cambCiudad} />
                    <Tab label={txt.tools.herra} />
                  </Tabs>
                </AppBar>
                {
                  this.state.tab != 3 && <div className={"card-panel"} style={{ width: "100%" }}>

                    {this.state.tab === 0 && <Introduction title={txt.stepThree.nap.title} text={txt.stepThree.nap.description} />}
                    {this.state.tab === 1 && <Introduction tileData={images.StepThreeDB.tileData} title={txt.stepThree.db.title} text={txt.stepThree.db.description} />}
                    {this.state.tab === 2 &&
                      this.props.excel.map((label, index) => {
                        return ( //Inputs
                          <div>
                            {index == 0 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.base}</span>}
                            {index == 1 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.comparative}</span>}

                            <VariationDistances
                              txt={txt.tools}
                              key={index}
                              index={index}
                              vdValues={this.state.Inputs[index].vdValues}
                              handleInputChange={this.handleInputChange}
                              handleSendInfo={this.handleSendInfo} />
                          </div>
                        );
                      })}
                    {this.state.tab === 4 && <OptionsView txt={txt.tools} visualization={this.state.visualization} handleVizualization={this.handleVizualization} />}

                  </div>
                }
              </div> : null
            }

            {this.props.match.params.id === 'kmvehicles' ?
              <div>
                <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.tab}
                    onChange={this.handleChangeTabs}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label={txt.tools.info} />
                    <Tab label={txt.tools.config} />
                    <Tab label={txt.tools.herra} />
                    <Tab label={txt.tools.cambCiudad} />
                  </Tabs>
                </AppBar>
                {
                  this.state.tab != 3 && <div className={"card-panel"} style={{ width: "100%" }}>

                    {this.state.tab === 0 && <Introduction tileData={images.StepThreeVK.tileData} title={txt.stepThree.vk.title} text={txt.stepThree.vk.description} />}
                    {this.state.tab === 1 &&
                      this.props.excel.map((label, index) => {
                        return ( //Inputs
                          <div>
                            {index == 0 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.base}</span>}
                            {index == 1 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.comparative}</span>}

                            <VariationDistances
                              txt={txt.tools}
                              key={index}
                              index={index}
                              vdValues={this.state.Inputs[index].vkValues}
                              handleInputChange={this.handleInputChangeVk}
                              handleSendInfo={this.handleSendInfoVk} />
                          </div>
                        );
                      })}
                    {this.state.tab === 2 && <OptionsView txt={txt.tools}  visualization={this.state.visualization} handleVizualization={this.handleVizualization} />}

                  </div>
                }
              </div> : null
            }

            {this.state.tab === 3 && <div className={"card-panel"}><City txt={txt.tools} index={0} city={this.props.city} handleChange={this.handleChange} /> </div>}


          </div>
        </div>

          {this.props.match.params.id == 'basedistance' ?
            <div className={"full-height"} style={{ height: '100%' }}>
              {
                this.props.excel ? <BaseDistance visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} city={this.props.city} textTypeVehicle={'Camiones'} percentages={[0, 100, 0, 0]} private={false} flagChange={this.state.flagChange} />
                  : null
              }
            </div> : null}

          {this.props.match.params.id == 'kmvehicles' ?
            <div className={"full-height"} style={{ height: '100%' }}>
              {
                this.props.excel ? <KmVehicles visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} city={this.props.city} textTypeVehicle={'Camiones'} percentages={[0, 100, 0, 0]} private={false} flagChange={this.state.flagChange} />
                  : null
              }
            </div> : null}
        </div >}
      </div >

    );
  }
}


const mapStateToProps = (state) => {
  return { lenguaje: state.lenguaje, excel: state.excel, city: state.city };
};

const mapDispatchToProps = dispatch => {
  return {
    changeExcel: datos => dispatch(excel(datos)),
    changeCity: datos => dispatch(city(datos))

  };
};
const AppScreen = connect(mapStateToProps, mapDispatchToProps)(StepThree);

export default AppScreen;
