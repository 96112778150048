import React from 'react'
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import StackedColum from '../TypeGraphic/StackedColum';
import text from '../../DB/text';
import { RangeEurosDividedMillion, RangeYears, GetCity } from "../utility/Range";
import Table from '../TypeGraphic/Table'

export default class EmisionesNox extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      city: GetCity(props.city),
      lineYears: null,

      NOxPrivadosBencineros: null,
      NOxPrivadosDiesel: null,
      NOxPrivadosGas: null,
      NOxPrivadosTotales: null,
      NOxTotales: null,
      NOxBusesBencineros: null,
      NOxBusesDiesel: null,
      NOxBusesGas: null,
      NOxBusesTotales: null,
      NOxCamionesBencineros: null,
      NOxCamionesDiesel: null,
      NOxCamionesGas: null,
      NOxCamionesTotales: null,
      NOxTaxisBencineros: null,
      NOxTaxisDiesel: null,
      NOxTaxisGas: null,
      NOxTaxisTotales: null
    }
  }



  componentDidMount() {
    this.allRangeEuro()
  }

  allRangeEuro() {

    var NOxPrivadosBencineros = [];
    var NOxPrivadosDiesel = [];
    var NOxPrivadosGas = [];
    var NOxPrivadosTotales = [];
    var NOxTotales = [];
    var NOxBusesBencineros = [];
    var NOxBusesDiesel = [];
    var NOxBusesGas = [];
    var NOxBusesTotales = [];
    var NOxCamionesBencineros = [];
    var NOxCamionesDiesel = [];
    var NOxCamionesGas = [];
    var NOxCamionesTotales = [];
    var NOxTaxisBencineros = [];
    var NOxTaxisDiesel = [];
    var NOxTaxisGas = [];
    var NOxTaxisTotales = [];

    var workbook
    for (const iterator of this.props.excel) {
      workbook = iterator.Sheets["ETAPA4_BGE"];
      NOxPrivadosBencineros.push(RangeEurosDividedMillion('E190:K210', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones NOx Vehículos Particulares Bencineros Gran Santiago
      NOxPrivadosDiesel.push(RangeEurosDividedMillion('M190:S210', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones NOx Vehículos Particulares Diésel Gran Santiago
      NOxPrivadosGas.push(RangeEurosDividedMillion('U190:U210', ['S/N'], workbook))//Emisiones NOx Vehículos Particulares Gas Gran Santiago
      NOxPrivadosTotales.push(RangeEurosDividedMillion('Y190:Y210', ['Total'], workbook))//Emisiones NOx Vehículos Particulares Gran Santiago Totales
      NOxTotales.push(RangeEurosDividedMillion('CK190:CK210', ['Total'], workbook)) //Emisiones NOx Gran Santiago Totales


      NOxBusesBencineros.push(RangeEurosDividedMillion('Z190:AF210', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones NOx Vehículos Particulares Bencineros Gran Santiago
      NOxBusesDiesel.push(RangeEurosDividedMillion('AH190:AN210', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones NOx Vehículos Particulares Diésel Gran Santiago
      NOxBusesGas.push(RangeEurosDividedMillion('AQ190:AQ210', ['Total'], workbook))//Emisiones NOx Vehículos Particulares Gran Santiago Totales
      NOxBusesTotales.push(RangeEurosDividedMillion('AT190:AT210', ['Total'], workbook)) //Emisiones NOx Gran Santiago Totales


      NOxCamionesBencineros.push(RangeEurosDividedMillion('AU190:BA210', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones NOx Vehículos Particulares Bencineros Gran Santiago
      NOxCamionesDiesel.push(RangeEurosDividedMillion('BC190:BI210', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones NOx Vehículos Particulares Diésel Gran Santiago
      NOxCamionesGas.push(RangeEurosDividedMillion('BL190:BL210', ['S/N'], workbook))//Emisiones NOx Vehículos Particulares Gas Gran Santiago
      NOxCamionesTotales.push(RangeEurosDividedMillion('BO190:BO210', ['Total'], workbook))//Emisiones NOx Vehículos Particulares Gran Santiago Totales


      NOxTaxisBencineros.push(RangeEurosDividedMillion('BP190:BV210', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones NOx Vehículos Particulares Bencineros Gran Santiago
      NOxTaxisDiesel.push(RangeEurosDividedMillion('BX190:CD210', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones NOx Vehículos Particulares Diésel Gran Santiago
      NOxTaxisGas.push(RangeEurosDividedMillion('CG190:CG210', ['S/N'], workbook))//Emisiones NOx Vehículos Particulares Gas Gran Santiago
      NOxTaxisTotales.push(RangeEurosDividedMillion('CJ190:CJ210', ['Total'], workbook))//Emisiones CO Vehículos Particulares Gran Santiago Totale  
    }

    var lineYears = RangeYears('D190:D210', workbook);

    var columns = [text[this.props.lenguage].tools.TEURO, text[this.props.lenguage].tools.years, text[this.props.lenguage].tools.YEmissions]

    this.setState({
      lineYears,
      NOxPrivadosBencineros,
      NOxPrivadosDiesel,
      NOxPrivadosGas,
      NOxPrivadosTotales,
      NOxTotales,
      NOxBusesBencineros,
      NOxBusesDiesel,
      NOxBusesGas,
      NOxBusesTotales,
      NOxCamionesBencineros,
      NOxCamionesDiesel,
      NOxCamionesGas,
      NOxCamionesTotales,
      NOxTaxisBencineros,
      NOxTaxisDiesel,
      NOxTaxisGas,
      NOxTaxisTotales,
      columns
    })
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.flagChange == true) {

      if (nextProps.city) {
        var city = GetCity(nextProps.city)
        this.setState({ city }, () => {
        });
      }
      this.allRangeEuro();
    }
  }

  render() {
    const style = {
        
        
        
    };
    const txt = text[this.props.lenguage].graph;

    return (
      <div style={style}>

        <div  >

          <Grid item xs={12}>
            {this.state.NOxPrivadosBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g86 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxPrivadosBencineros} /> : null
            }

            {this.state.NOxPrivadosDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g87 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxPrivadosDiesel} /> : null
            }
            {this.state.NOxPrivadosGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g88 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxPrivadosGas} /> : null
            }
            {this.state.NOxPrivadosTotales && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g89 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxPrivadosTotales} /> : null
            }
            {this.state.NOxTotales && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g90 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxTotales} /> : null
            }


            {this.state.NOxBusesBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g91 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxBusesBencineros} /> : null
            }

            {this.state.NOxBusesDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g92 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxBusesDiesel} /> : null
            }
            {this.state.NOxBusesGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g93 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxBusesGas} /> : null
            }
            {this.state.NOxBusesTotales && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g94 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxBusesTotales} /> : null
            }


            {this.state.NOxCamionesBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g95 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxCamionesBencineros} /> : null
            }

            {this.state.NOxCamionesDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g96 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxCamionesDiesel} /> : null
            }
            {this.state.NOxCamionesGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g97 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxCamionesGas} /> : null
            }
            {this.state.NOxCamionesTotales && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g98 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxCamionesTotales} /> : null
            }


            {this.state.NOxTaxisBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={text[this.props.lenguage].graph.g99 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxTaxisBencineros} /> : null
            }

            {this.state.NOxTaxisDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={text[this.props.lenguage].graph.g100 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxTaxisDiesel} /> : null
            }
            {this.state.NOxTaxisGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={text[this.props.lenguage].graph.g101 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxTaxisGas} /> : null
            }
            {this.state.NOxTaxisTotales && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={text[this.props.lenguage].graph.g102 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.NOxTaxisTotales} /> : null
            }

          </Grid>



          <Grid item xs={12}>
            {this.state.NOxPrivadosBencineros && this.props.visualization === 'table' ?
              <Table text={txt.g86 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxPrivadosBencineros} /> : null
            }

            {this.state.NOxPrivadosDiesel && this.props.visualization === 'table' ?
              <Table text={txt.g87 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxPrivadosDiesel} /> : null
            }
            {this.state.NOxPrivadosGas && this.props.visualization === 'table' ?
              <Table text={txt.g88 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxPrivadosGas} /> : null
            }
            {this.state.NOxPrivadosTotales && this.props.visualization === 'table' ?
              <Table text={txt.g89 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxPrivadosTotales} /> : null
            }
            {this.state.NOxTotales && this.props.visualization === 'table' ?
              <Table text={txt.g90 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxTotales} /> : null
            }


            {this.state.NOxBusesBencineros && this.props.visualization === 'table' ?
              <Table text={txt.g91 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxBusesBencineros} /> : null
            }

            {this.state.NOxBusesDiesel && this.props.visualization === 'table' ?
              <Table text={txt.g92 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxBusesDiesel} /> : null
            }
            {this.state.NOxBusesGas && this.props.visualization === 'table' ?
              <Table text={txt.g93 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxBusesGas} /> : null
            }
            {this.state.NOxBusesTotales && this.props.visualization === 'table' ?
              <Table text={txt.g94 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxBusesTotales} /> : null
            }


            {this.state.NOxCamionesBencineros && this.props.visualization === 'table' ?
              <Table text={txt.g95 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxCamionesBencineros} /> : null
            }

            {this.state.NOxCamionesDiesel && this.props.visualization === 'table' ?
              <Table text={txt.g96 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxCamionesDiesel} /> : null
            }
            {this.state.NOxCamionesGas && this.props.visualization === 'table' ?
              <Table text={txt.g97 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxCamionesGas} /> : null
            }
            {this.state.NOxCamionesTotales && this.props.visualization === 'table' ?
              <Table text={txt.g98 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxCamionesTotales} /> : null
            }


            {this.state.NOxTaxisBencineros && this.props.visualization === 'table' ?
              <Table text={text[this.props.lenguage].graph.g99 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxTaxisBencineros} /> : null
            }

            {this.state.NOxTaxisDiesel && this.props.visualization === 'table' ?
              <Table text={text[this.props.lenguage].graph.g100 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxTaxisDiesel} /> : null
            }
            {this.state.NOxTaxisGas && this.props.visualization === 'table' ?
              <Table text={text[this.props.lenguage].graph.g101 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxTaxisGas} /> : null
            }
            {this.state.NOxTaxisTotales && this.props.visualization === 'table' ?
              <Table text={text[this.props.lenguage].graph.g102 + this.state.city}
                columns={this.state.columns} lpv={this.state.NOxTaxisTotales} /> : null
            }

          </Grid>

        </div>
      </div>
    );
  }
}



