import React from 'react';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';
const LvSaturation = ({ txt, lvSaturation, handleChange, index }) => {
  return (

    <FormControl >
      <InputLabel style={{ marginLeft: 20, marginTop: 15 }} htmlFor="lvSaturation">{txt.saturation}</InputLabel>
      <Select
        value={lvSaturation}
        onChange={handleChange(index)}
        style={{ marginLeft: 20, height: 33, marginTop: 33 }}
        input={
          <OutlinedInput
            style={{ borderColor: '#ffa200' }}
            labelWidth={50}
            name="lvSaturation"
            id="lvSaturation"
          />
        }>
        {
          [500, 600, 700, 800].flatMap(i => (
            <MenuItem key={i} style={{ paddingRight: 41 }} value={i}>  <span style={{ paddingRight: 41 }}> {i} </span>  </MenuItem>

          ))
        }
      </Select>
    </FormControl>

  );
}
export default LvSaturation