import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import Template from './Containers/Template';
import { connect } from "react-redux";
import { initialStageOne, initialStageTwo, initialStageThree, initialStageFour, lenguaje, excel } from '../src/actionCreators';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { row } from './DB/row';
import Loader from './Components/Loader'
import XLSX from '@sheet/core';
import S5SCalc from '@sheet/formula';
import { initializeApp } from "firebase/app";
S5SCalc.XLSXLib = XLSX; // <-- make the connection

// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyByfs0xX2FBIhQxGbdtqlSVWzQX04ijE88",
  authDomain: "modeloguess-9653a.firebaseapp.com",
  projectId: "modeloguess-9653a",
  storageBucket: "modeloguess-9653a.appspot.com",
  messagingSenderId: "910724343686",
  appId: "1:910724343686:web:0ec908a5ca7e3e7de76e07",
  measurementId: "G-JVF5CJDS57"
};

// Initialize Firebase
initializeApp(firebaseConfig);


const theme = createMuiTheme({
  typography: { useNextVariants: true },

});

class App extends Component {
  constructor(props) {
    super(props);

    if (localStorage.getItem('lenguaje')) {

      var lenguaje = localStorage.getItem('lenguaje');

      this.props.lenguaje(lenguaje)
    }

    this.state = {
      isLoading: true
    }

  }
  createMatrizSheet = (largo, ancho, workbook) => {

    var matrixValue = [];
    for (var i = 0; i < largo; i++) {
      matrixValue[i] = new Array(ancho);
      for (var x = 0; x < ancho; x++) {
        matrixValue[i][x] = 0
      }
    }
    var matrixFormulas = [];
    for (var i = 0; i < largo; i++) {
      matrixFormulas[i] = new Array(ancho);
      for (var x = 0; x < ancho; x++) {
        matrixFormulas[i][x] = 0
      }
    }
    var letterNumber = [];
    Object.keys(workbook).forEach((key, index) => {
      if (key == "!ref" || key == "!margins" || key == "!merges") {
      } else {
        letterNumber = key.match(/[a-z]+|[^a-z]+/gi);
        var indexLetter = row.indexOf(letterNumber[0])
        var indexNumber = parseInt(letterNumber[1])
        matrixValue[indexNumber - 1][indexLetter] = workbook[key].v
        if (workbook[key].f) {
          matrixFormulas[indexNumber - 1][indexLetter] = workbook[key].f
        }
      }
    });
    return { matrixValue, matrixFormulas }
  }

  componentDidMount() {
    var url = window.location.origin + "/GUESS1.xlsx";

    var req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "arraybuffer";
    req.onload = e => {
      var data = new Uint8Array(req.response);

      var workbook = XLSX.read(data, { type: "array" });

      var workbook2 = JSON.parse(JSON.stringify(workbook));
      this.props.excel({ workbook: [workbook, workbook2] })
      this.setState({ isLoading: false })

    };
    req.send();
  }

  render() {
    if (this.state.isLoading === true) {
      return (<Loader />)
    } else {
      return (
        <MuiThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route path="/" component={Template} />
            </Switch>
          </Router>

        </MuiThemeProvider>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {
    initialStageOne: datos => dispatch(initialStageOne(datos)),
    initialStageTwo: datos => dispatch(initialStageTwo(datos)),
    initialStageThree: datos => dispatch(initialStageThree(datos)),
    initialStageFour: datos => dispatch(initialStageFour(datos)),
    lenguaje: datos => dispatch(lenguaje(datos)),
    excel: datos => dispatch(excel(datos)),
  };
};
const AppScreen = connect(mapStateToProps, mapDispatchToProps)(App);
export default AppScreen;

