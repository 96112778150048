import React from 'react'
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import BarColum from '../TypeGraphic/BarColum';
import text from "../../DB/text"
import { RangeEuros, RangeYears, GetCity } from "../utility/Range"
import Table from '../TypeGraphic/Table'

export default class SurvivalFunction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            city: GetCity(props.city),
            lineYears: null,
            survivalFunc: null,
            columns: []
        }
    }

    componentDidMount() {
        this.allRangeEuro()
    }

    allRangeEuro() {


        var workbook
        var survivalFunc = [];
        var txt = text[this.props.lenguage].tools;
        for (const iterator of this.props.excel) {
            workbook = iterator.Sheets["ETAPA2_BGE"];
            survivalFunc.push(RangeEuros('E207:H261', txt.arrayVehicles, workbook))
        }
        var lineYears = RangeYears('D207:D261', workbook)
        var columns = [txt.TYPE, txt.years, txt.Probability]

        this.setState({
            lineYears,
            survivalFunc,
            columns
        })
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.flagChange == true) {
            if (nextProps.city) {
                var city = GetCity(nextProps.city)
                this.setState({ city }, () => {
                });
            }
            this.allRangeEuro();
        }
    }

    render() {


        return (
            <div >
                <div  >

                    <Grid item xs={12}>
                        {this.state.survivalFunc && this.props.visualization === 'graph' ? <BarColum scena={text[this.props.lenguage].tools} isPercentage={true} yaxis={text[this.props.lenguage].tools.Probability} text={text[this.props.lenguage].graph.g32} years={this.state.lineYears} lpv={this.state.survivalFunc} /> : null}
                    </Grid>
                    {this.state.survivalFunc && this.props.visualization === 'table' ? <Table isPercentage={true} text={text[this.props.lenguage].graph.g32 + this.state.city} columns={this.state.columns} lpv={this.state.survivalFunc} /> : null}

                </div>
            </div>
        );
    }
}

