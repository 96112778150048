import React from 'react';
import Chart from "react-apexcharts";

import Card from "../../CustomComponents/Card/Card.jsx";
import CardHeader from "../../CustomComponents/Card/CardHeader.jsx";
import CardBody from "../../CustomComponents/Card/CardBody.jsx";

import Typography from '@material-ui/core/Typography';

export default class Lpv extends React.Component {

  constructor(props) {
    super(props);

    console.log(this.props.lpv[0]);

    var years = [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038]
    if (this.props.years) {
      years = this.props.years;
    }

    var txtYaxis = '';
    if (this.props.yaxis) {
      txtYaxis = this.props.yaxis
    }

    this.state = {
      loading: false,
      options: {
        chart: {
          stacked: false,
          group: "BacktestCharts",
          zoom: {
            type: 'x',
            enabled: true
          },
          toolbar: {
            autoSelected: 'zoom'
          },
          id: this.props.lpv[0][0].data[0],
          type: 'line',
          animations: {
            enabled: false
          }
        },
        plotOptions: {
          line: {
            curve: 'smooth',
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        yaxis: {
          title: {
            text: txtYaxis,
            style: {
              fontSize: 14
            },
          },

          labels: {
            formatter: function (val) {
              if (val) {
                return val.toLocaleString("es-CL")
              }
            },
          },
        },
        markers: {
          size: 6
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 1,
            opacityTo: 0.8,
          },
        },
        xaxis: {
          tickPlacement: true,
          categories: years,
          labels: {
            show: true,
            rotate: -45,
            showDuplicates: true,
            hideOverlappingLabels: false,
            trim: true,
            rotateAlways: true,
          },
          title: {
            text: this.props.scena.years
          }
        },
        title: {
          text: this.props.scena.base,
        },
      },
      options2: {
        chart: {
          stacked: false,
          group: "BacktestCharts",
          zoom: {
            type: 'x',
            enabled: true
          },
          toolbar: {
            autoSelected: 'zoom'
          },
          id: this.props.lpv[0][0].data[0],
          type: 'line',
          animations: {
            enabled: false
          }
        },
        plotOptions: {
          line: {
            curve: 'smooth',
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        yaxis: {
          title: {
            text: txtYaxis,
            style: {
              fontSize: 14
            },
          },
          labels: {
            formatter: function (val) {
              if (val) {
                return val.toLocaleString("es-CL")
              }
            },
          },
        },
        markers: {
          size: 6
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 1,
            opacityTo: 0.8,
          },
        },
        xaxis: {
          tickPlacement: true,
          categories: years,
          labels: {
            show: true,
            rotate: -45,
            showDuplicates: true,
            hideOverlappingLabels: false,
            trim: true,
            rotateAlways: true,
          },
          title: {
            text: this.props.scena.years,
          }
        },
        title: {
          text: this.props.scena.comparative
        },
      },

      series: this.props.lpv[0],
      series2: this.props.lpv[1]
    };


  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.lpv[0] !== this.props.lpv[0]) {
      this.setState({ series: nextProps.lpv[0] });
    }
    if (nextProps.lpv[1] !== this.props.lpv[1]) {
      this.setState({ series2: nextProps.lpv[1], })
    }
  }




  render() {
    const style = {
      padding: '0px'
    }


    return (
      <div className={'row'} >

        <div className={'col s12'} style={style} >
          {this.state.loading === false && <Card>
            <CardHeader color="warning">
              <Typography variant="h6" gutterBottom style={{ color: '#FFF' }}>
                {this.props.text}
              </Typography>
            </CardHeader>
            <CardBody>
              <div className="mixed-chart">
                <Chart
                  options={this.state.options}
                  series={this.state.series}
                  type="line"
                  height="350"
                />
                <Chart
                  options={this.state.options2}
                  series={this.state.series2}
                  type="line"
                  height="350"
                />
              </div>
            </CardBody>
          </Card>}

        </div>
      </div >

    );
  }
}