import React from 'react';
import text from "../DB/text"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux"
import Particles from 'react-particles-js';
import PersonPin from '@material-ui/icons/PersonPin';
import { withStyles } from '@material-ui/core/styles';
import Glossary from "../Components/Glossary"
import { SocialIcon } from 'react-social-icons';


const styles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
    marginTop: "50px"
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
    textAlign: "center",
    color: "#ffffff",
    marginTop: "20px"
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },

  jss105: {
    width: "55px",
    height: "4px",
    margin: "8px auto 0",
    display: "block",
    backgroundColor: "#ff3366"
  }
});


class Gratitude extends React.Component {


  constructor(props) {
    super(props);
  }

  render() {

    const txt = text[this.props.chooseLenguaje].tools;


    const { classes } = this.props;

    return (

      <div style={{ backgroundColor: "#062489", height: '100%' }}>
        <div className="welcome-area wow fadeInUp" id="home" style={{
          background: "#062489",
          fontFamily: 'Montserrat',
          fontSize: '14px',
          color: '#cbe5ff', zIndex: 0
        }}>
          <Particles style={{
            position: 'fixed'
          }}
            params={{
              "particles": {
                "number": {
                  "value": 50
                },
                "size": {
                  "value": 3
                }
              },
              "interactivity": {
                "events": {
                  "onhover": {
                    "enable": true,
                    "mode": "repulse"
                  }
                }
              }
            }}
          />

          <Typography variant="h4" marked="center" className={classes.title} component="h2">
            {txt.gratitude.toUpperCase()}
            <span class={classes.jss105}></span>
          </Typography>
          <div>
            <Grid container spacing={0}>



              {txt.namesGratitude.map(person => (

                <Grid item xs={12} md={4} style={{ marginBottom: 50 }}>
                  <div className={classes.item}>
                    <div className={classes.number}></div>

                    <PersonPin style={{ fontSize: 60, color: "#ffa200" }} />
                    <Typography variant="h5" align="center" style={{ color: "#ffffff" }} >
                      {person.name}
                    </Typography>
                    <div style={{ flexDirection: "row" }} >
                      {
                        person.socialMedia.length > 1 ?

                          person.socialMedia.map(link => (<SocialIcon url={link} style={{ height: 35, width: 35, margin: 10 }} />))
                          : null


                      }
                    </div>
                  </div>
                </Grid>




              ))}








            </Grid>
          </div>


        </div>

      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return { chooseLenguaje: state.lenguaje };
};
const mapDispatchToProps = dispatch => {
  return {
  };
};


const AppScreen = connect(mapStateToProps, mapDispatchToProps)(Gratitude);

export default withStyles(styles)(AppScreen);

