import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const FunctionSupervival = ({ txt, handleInputChange, value, handleSendInfo, index }) => {

  return (
    <form style={{ flexWrap: 'wrap', }} noValidate autoComplete="off">


      <TextField
        id="standard-name"
        label={txt.alphaPrivateVehicles}
        style={{ width: 100, color: 'black !important', fontSize: 19, marginLeft: 20, marginRight: 20 }}
        value={value.Avp}
        onChange={handleInputChange(index)}
        margin="normal"
        name="Avp"
        color="black"
        type="number"
        fontSize="19"
      />

      <TextField
        id="standard-name"
        label={txt.alphaTaxis}
        style={{ width: 100, color: 'black !important', marginLeft: 20, marginRight: 20 }}
        value={value.alfaTaxis}
        onChange={handleInputChange(index)}
        margin="normal"
        name="alfaTaxis"
        color="black"
        type="number"
      />

      <TextField
        id="standard-name"
        label={txt.alphaBuses}
        style={{ width: 100, color: 'black !important', marginLeft: 20, marginRight: 20 }}
        value={value.alfaBuses}
        onChange={handleInputChange(index)}
        margin="normal"
        name="alfaBuses"
        color="black"
        type="number"
      />

      <TextField
        id="standard-name"
        label={txt.alphaTrucks}
        style={{ width: 100, color: 'black !important', marginLeft: 20, marginRight: 20 }}
        value={value.alfaCamiones}
        onChange={handleInputChange(index)}
        margin="normal"
        name="alfaCamiones"
        color="black"
        type="number"
      />

      <TextField
        id="standard-name"
        label={txt.betaPrivateVehicles}
        style={{ width: 100, color: 'black !important', marginLeft: 20, marginRight: 20 }}
        value={value.betaVP}
        onChange={handleInputChange(index)}
        margin="normal"
        name="betaVP"
        color="black"
        type="number"
      />

      <TextField
        id="standard-name"
        label={txt.betaTaxis}
        style={{ width: 100, color: 'black !important', marginLeft: 20, marginRight: 20 }}
        value={value.betaTaxis}
        onChange={handleInputChange(index)}
        margin="normal"
        name="betaTaxis"
        color="black"
        type="number"
      />
      <TextField
        id="standard-name"
        label={txt.betaBuses}
        style={{ width: 100, color: 'black !important', marginLeft: 20, marginRight: 20 }}
        value={value.betaBuses}
        onChange={handleInputChange(index)}
        margin="normal"
        name="betaBuses"
        color="black"
        type="number"
      />
      <TextField
        id="standard-name"
        label={txt.betaTrucks}
        style={{ width: 100, color: 'black !important', marginLeft: 20, marginRight: 20 }}
        value={value.betaCamiones}
        onChange={handleInputChange(index)}
        margin="normal"
        name="betaCamiones"
        color="black"
        type="number"
      />

      <TextField
        id="standard-name"
        label={txt.generalPublicVehicles}
        style={{ width: 150, color: 'black !important', marginLeft: 20, marginRight: 20 }}
        value={value.GVP}
        onChange={handleInputChange(index)}
        margin="normal"
        name="GVP"
        color="black"
        type="number"
      />
      <TextField
        id="standard-name"
        label={txt.generalTaxis}
        style={{ width: 100, color: 'black !important', marginLeft: 20, marginRight: 20 }}
        value={value.GTaxis}
        onChange={handleInputChange(index)}
        margin="normal"
        name="GTaxis"
        color="black"
        type="number"
      />
      <TextField
        id="standard-name"
        label={txt.generalBuses}
        style={{ width: 100, color: 'black !important', marginLeft: 20, marginRight: 20 }}
        value={value.GBuses}
        onChange={handleInputChange(index)}
        margin="normal"
        name="GBuses"
        color="black"
        type="number"
      />
      <TextField
        id="standard-name"
        label={txt.generalTrucks}
        style={{ width: 100, color: 'black !important', marginLeft: 20, marginRight: 20 }}
        value={value.GCamiones}
        onChange={handleInputChange(index)}
        margin="normal"
        name="GCamiones"
        color="black"
        type="number"
      />

      <Button variant="contained" color="primary" style={{
        height: 41,
        top: 20
      }}
        onClick={() => handleSendInfo(index)}>
        {txt.calculate}
      </Button>
    </form>
  );
}
export default FunctionSupervival
