import React from 'react';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem'

const ProyectNoPrivate = ({ txt, variationTaxi, variationBus, variationCamion, handleChange, index }) => {
  const listArray = () => {
    var arr = [];
    for (var j = -100; j <= 100; j++) {
      arr[arr.length] = j;
    }
    return arr;
  }
  return (

    <div className="row">


      <blockquote>
        {index == 0 && <span style={{ color: '#757575', marginRight: 10 }}>{txt.base}</span>}
        {index == 1 && <span style={{ color: '#757575', marginRight: 10 }}>{txt.comparison}</span>}
      </blockquote>


      <span style={{ color: '#757575' }}>{txt.variationCab}</span>
      <Select
        value={variationTaxi}
        onChange={handleChange(index)}
        style={{ marginLeft: 10, height: 30 }}
        input={
          <OutlinedInput
            style={{ borderColor: '#ffa200' }}
            labelWidth={50}
            name="variationTaxi"
            id={index}
          />
        }>
        {
          Array.from(listArray(), (val, i) => (
            <MenuItem key={val} style={{ paddingRight: 41 }} value={val}>  <span style={{ paddingRight: 41 }}> {val} %</span>  </MenuItem>
          ))
        }
      </Select>
      <div style={{ marginLeft: '15px' }}>
        <span style={{ color: '#757575' }}>{txt.variationBus}</span>
        <Select
          value={variationBus}
          onChange={handleChange(index)}
          style={{ marginLeft: 10, height: 30 }}
          input={
            <OutlinedInput
              style={{ borderColor: '#ffa200' }}
              labelWidth={50}
              name="variationBus"
              id={index}
            />
          }>
          {
            Array.from(listArray(), (val, i) => (
              <MenuItem key={val} style={{ paddingRight: 41 }} value={val}>  <span style={{ paddingRight: 41 }}> {val} %</span>  </MenuItem>
            ))
          }
        </Select>
      </div>

      <div style={{ marginLeft: '15px' }}>
        <span style={{ color: '#757575' }}>{txt.variationTruck}</span>
        <Select
          value={variationCamion}
          onChange={handleChange(index)}
          style={{ marginLeft: 10, height: 30 }}
          input={
            <OutlinedInput
              style={{ borderColor: '#ffa200' }}
              labelWidth={50}
              name="variationCamion"
              id={index}
            />
          }>
          {
            Array.from(listArray(), (val, i) => (
              <MenuItem key={val} style={{ paddingRight: 41 }} value={val}>  <span style={{ paddingRight: 41 }}> {val} %</span>  </MenuItem>
            ))
          }
        </Select>
      </div>
    </div>
  );
}
export default ProyectNoPrivate