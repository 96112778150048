import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Glossary from "../Glossary"

const OptionsView = ({ txt, visualization, handleVizualization }) => {
  return (
    <div className="row">
      <blockquote style={{ marginLeft: '10px' }}>
        <div style={{ display: 'flex' }}>
          <FormControl component="fieldset" style={{ margin: 20 }}>
            <FormLabel component="legend">{txt.visual}</FormLabel>
            <RadioGroup
              aria-label="visualization"
              name="visualization"
              value={visualization}
              onChange={handleVizualization}
              row
            >
              <FormControlLabel value="graph" control={<Radio />} label={txt.graphic} />
              <FormControlLabel value="table" control={<Radio />} label={txt.table} />
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset" style={{ margin: 20 }}>
            <FormLabel component="legend">{txt.glossary}</FormLabel>
            <Glossary txt={txt} />
          </FormControl>
        </div>
      </blockquote>
    </div>
  );
}
export default OptionsView