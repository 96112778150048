import React from 'react';
import Grid from '@material-ui/core/Grid';
import text from "../../DB/text";
import StackedColum from '../TypeGraphic/StackedColum';
import { RangeEurosDividedMillion, RangeYears, GetCity } from "../utility/Range";
import Table from '../TypeGraphic/Table'

export default class Emisiones extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      city: GetCity(props.city),
      lineYears: null,
      COPrivadosBencineros: null,
      COPrivadosDiesel: null,
      COPrivadosGas: null,
      COPrivadosTotales: null,
      COTotales: null,
      COBusesBencineros: null,
      COBusesDiesel: null,
      COBusesGas: null,
      COBusesTotales: null,
      COCamionesBencineros: null,
      COCamionesDiesel: null,
      COCamionesGas: null,
      COCamionesTotales: null,
      COTaxisBencineros: null,
      COTaxisDiesel: null,
      COTaxisGas: null,
      COTaxisTotales: null,
      columns: []
    }
  }



  componentDidMount() {
    this.allRangeEuro()
  }

  allRangeEuro() {

    var workbook

    var COPrivadosBencineros = [];
    var COPrivadosDiesel = [];
    var COPrivadosGas = [];
    var COPrivadosTotales = [];
    var COTotales = [];
    var COBusesBencineros = [];
    var COBusesDiesel = [];
    var COBusesGas = [];
    var COBusesTotales = [];
    var COCamionesBencineros = [];
    var COCamionesDiesel = [];
    var COCamionesGas = [];
    var COCamionesTotales = [];
    var COTaxisBencineros = [];
    var COTaxisDiesel = [];
    var COTaxisGas = [];
    var COTaxisTotales = [];


    for (const iterator of this.props.excel) {
      workbook = iterator.Sheets["ETAPA4_BGE"];

      COPrivadosBencineros.push(RangeEurosDividedMillion('E73:K93', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO Vehículos Particulares Bencineros Gran Santiago

      COPrivadosDiesel.push(RangeEurosDividedMillion('M73:S93', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO Vehículos Particulares Diésel Gran Santiago
      COPrivadosGas.push(RangeEurosDividedMillion('U73:U93', ['S/N'], workbook))//Emisiones CO Vehículos Particulares Gas Gran Santiago
      COPrivadosTotales.push(RangeEurosDividedMillion('Y73:Y93', ['Total'], workbook))//Emisiones CO Vehículos Particulares Gran Santiago Totales
      COTotales.push(RangeEurosDividedMillion('CK73:CK93', ['Total'], workbook)) //Emisiones CO Gran Santiago Totales


      COBusesBencineros.push(RangeEurosDividedMillion('Z73:AF93', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO Vehículos Particulares Bencineros Gran Santiago
      COBusesDiesel.push(RangeEurosDividedMillion('AH73:AN93', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO Vehículos Particulares Diésel Gran Santiago
      COBusesGas.push(RangeEurosDividedMillion('AQ73:AQ93', ['Total'], workbook))//Emisiones CO Vehículos Particulares Gran Santiago Totales
      COBusesTotales.push(RangeEurosDividedMillion('AT73:AT93', ['Total'], workbook)) //Emisiones CO Gran Santiago Totales


      COCamionesBencineros.push(RangeEurosDividedMillion('AU73:BA93', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO Vehículos Particulares Bencineros Gran Santiago
      COCamionesDiesel.push(RangeEurosDividedMillion('BC73:BI93', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO Vehículos Particulares Diésel Gran Santiago
      COCamionesGas.push(RangeEurosDividedMillion('BL73:BL93', ['S/N'], workbook))//Emisiones CO Vehículos Particulares Gas Gran Santiago
      COCamionesTotales.push(RangeEurosDividedMillion('BO73:BO93', ['Total'], workbook))//Emisiones CO Vehículos Particulares Gran Santiago Totales


      COTaxisBencineros.push(RangeEurosDividedMillion('BP73:BV93', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO Vehículos Particulares Bencineros Gran Santiago
      COTaxisDiesel.push(RangeEurosDividedMillion('BX73:CD93', ['S/N', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6'], workbook))//Emisiones CO Vehículos Particulares Diésel Gran Santiago
      COTaxisGas.push(RangeEurosDividedMillion('CG73:CG93', ['S/N'], workbook))//Emisiones CO Vehículos Particulares Gas Gran Santiago
      COTaxisTotales.push(RangeEurosDividedMillion('CJ73:CJ93', ['Total'], workbook))//Emisiones CO Vehículos Particulares Gran Santiago Totales

    }

    var lineYears = RangeYears('D73:D93', workbook);

    var columns = [text[this.props.lenguage].tools.TEURO, text[this.props.lenguage].tools.years, text[this.props.lenguage].tools.YEmissions]

    this.setState({
      lineYears,
      COPrivadosBencineros,
      COPrivadosDiesel,
      COPrivadosGas,
      COPrivadosTotales,
      COTotales,
      COBusesBencineros,
      COBusesDiesel,
      COBusesGas,
      COBusesTotales,
      COCamionesBencineros,
      COCamionesDiesel,
      COCamionesGas,
      COCamionesTotales,
      COTaxisBencineros,
      COTaxisDiesel,
      COTaxisGas,
      COTaxisTotales,
      columns
    })
  }



  componentWillReceiveProps(nextProps) {
    if (nextProps.flagChange == true) {

      if (nextProps.city) {
        var city = GetCity(nextProps.city)
        this.setState({ city }, () => {
        });
      }
      this.allRangeEuro();
    }
  }

  render() {
    const style = {
        

        
    };

    const txt = text[this.props.lenguage].graph;
    return (
      <div style={style}>

        <div  >
          <Grid item xs={12}>
            {this.state.COPrivadosBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g56 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COPrivadosBencineros} /> : null
            }

            {this.state.COPrivadosDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g57 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COPrivadosDiesel} /> : null
            }
            {this.state.COPrivadosGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g58 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COPrivadosGas} /> : null
            }
            {this.state.COPrivadosTotales && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g59 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COPrivadosTotales} /> : null
            }
            {this.state.COTotales && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g60 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COTotales} /> : null
            }

            {this.state.COBusesBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g61 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COBusesBencineros} /> : null
            }

            {this.state.COBusesDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g62 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COBusesDiesel} /> : null
            }
            {this.state.COBusesGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g63 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COBusesGas} /> : null
            }
            {this.state.COBusesTotales && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g64 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COBusesTotales} /> : null
            }


            {this.state.COCamionesBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g65 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COCamionesBencineros} /> : null
            }

            {this.state.COCamionesDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g66 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COCamionesDiesel} /> : null
            }
            {this.state.COCamionesGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g67 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COCamionesGas} /> : null
            }
            {this.state.COCamionesTotales && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g68 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COCamionesTotales} /> : null
            }


            {this.state.COTaxisBencineros && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g69 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COTaxisBencineros} /> : null
            }

            {this.state.COTaxisDiesel && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g70 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COTaxisDiesel} /> : null
            }
            {this.state.COTaxisGas && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g71 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COTaxisGas} /> : null
            }
            {this.state.COTaxisTotales && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g72 + this.state.city}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.COTaxisTotales} /> : null
            }
          </Grid>

          <Grid item xs={12}>
            {this.state.COPrivadosBencineros && this.props.visualization === 'table' ?
              <Table text={txt.g56 + this.state.city}
                columns={this.state.columns} lpv={this.state.COPrivadosBencineros} /> : null
            }

            {this.state.COPrivadosDiesel && this.props.visualization === 'table' ?
              <Table text={txt.g57 + this.state.city}
                columns={this.state.columns} lpv={this.state.COPrivadosDiesel} /> : null
            }
            {this.state.COPrivadosGas && this.props.visualization === 'table' ?
              <Table text={txt.g58 + this.state.city}
                columns={this.state.columns} lpv={this.state.COPrivadosGas} /> : null
            }
            {this.state.COPrivadosTotales && this.props.visualization === 'table' ?
              <Table text={txt.g59 + this.state.city}
                columns={this.state.columns} lpv={this.state.COPrivadosTotales} /> : null
            }
            {this.state.COTotales && this.props.visualization === 'table' ?
              <Table text={txt.g60 + this.state.city}
                columns={this.state.columns} lpv={this.state.COTotales} /> : null
            }

            {this.state.COBusesBencineros && this.props.visualization === 'table' ?
              <Table text={txt.g61 + this.state.city}
                columns={this.state.columns} lpv={this.state.COBusesBencineros} /> : null
            }

            {this.state.COBusesDiesel && this.props.visualization === 'table' ?
              <Table text={txt.g62 + this.state.city}
                columns={this.state.columns} lpv={this.state.COBusesDiesel} /> : null
            }
            {this.state.COBusesGas && this.props.visualization === 'table' ?
              <Table text={txt.g63 + this.state.city}
                columns={this.state.columns} lpv={this.state.COBusesGas} /> : null
            }
            {this.state.COBusesTotales && this.props.visualization === 'table' ?
              <Table text={txt.g64 + this.state.city}
                columns={this.state.columns} lpv={this.state.COBusesTotales} /> : null
            }


            {this.state.COCamionesBencineros && this.props.visualization === 'table' ?
              <Table text={txt.g65 + this.state.city}
                columns={this.state.columns} lpv={this.state.COCamionesBencineros} /> : null
            }

            {this.state.COCamionesDiesel && this.props.visualization === 'table' ?
              <Table text={txt.g66 + this.state.city}
                columns={this.state.columns} lpv={this.state.COCamionesDiesel} /> : null
            }
            {this.state.COCamionesGas && this.props.visualization === 'table' ?
              <Table text={txt.g67 + this.state.city}
                columns={this.state.columns} lpv={this.state.COCamionesGas} /> : null
            }
            {this.state.COCamionesTotales && this.props.visualization === 'table' ?
              <Table text={txt.g68 + this.state.city}
                columns={this.state.columns} lpv={this.state.COCamionesTotales} /> : null
            }


            {this.state.COTaxisBencineros && this.props.visualization === 'table' ?
              <Table text={txt.g69 + this.state.city}
                columns={this.state.columns} lpv={this.state.COTaxisBencineros} /> : null
            }

            {this.state.COTaxisDiesel && this.props.visualization === 'table' ?
              <Table text={txt.g70 + this.state.city}
                columns={this.state.columns} lpv={this.state.COTaxisDiesel} /> : null
            }
            {this.state.COTaxisGas && this.props.visualization === 'table' ?
              <Table text={txt.g71 + this.state.city}
                columns={this.state.columns} lpv={this.state.COTaxisGas} /> : null
            }
            {this.state.COTaxisTotales && this.props.visualization === 'table' ?
              <Table text={txt.g72 + this.state.city}
                columns={this.state.columns} lpv={this.state.COTaxisTotales} /> : null
            }
          </Grid>
        </div>
      </div>
    );
  }
}



