
import StepOne from "./Containers/StepOne";
import StepTwo from "./Containers/StepTwo";
import StepThree from "./Containers/StepThree";
import StepFour from "./Containers/StepFour";
import Home from "./Containers/Home";
import Introduction from "./Containers/Introduction";
import Gratitude from "./Containers/Gratitude";


const dashboardRoutes =
{
  routes: [
    {
      path: "/:id",
      name: "Etapa 1",
      component: StepOne,
      layout: "/StepOne",
    },
    {
      path: "/:id",
      name: "Etapa 2",
      component: StepTwo,
      layout: "/StepTwo",
    },
    {
      path: "/:id",
      name: "Etapa 3",
      component: StepThree,
      layout: "/StepThree",
    },
    {
      path: "/:id",
      name: "Etapa 4",
      component: StepFour,
      layout: "/StepFour",
    },
    {
      path: "",
      name: "Home",
      component: Home,
      layout: "/",
    }, {
      path: "",
      name: "Introduction",
      component: Introduction,
      layout: "/Introduction",
    }, {
      path: "",
      name: "Gratitude",
      component: Gratitude,
      layout: "/Gratitude",
    },
  ],
  stepOne: [
    {
      path: "/proyectprivate",
      name: {
        español: "Proyección Privados",
        english: "Private Projection"
      },
      layout: "/StepOne",
    },
    {
      path: "/proyectnoprivate",
      name: {
        español: "Proyección No Particulares",
        english: "Non-Particular Projection"
      },
      layout: "/StepOne",
    },
    {
      path: "/onecombustibleprivate",
      name: {
        español: "Combustible Privados",
        english: "Private Fuel"
      },
      layout: "/StepOne",
    },
    {
      path: "/onecombustiblepublic",
      name: {
        español: "Combustible No Particulares",
        english: "Non Particular Fuel"
      },
      layout: "/StepOne",
    },
    {
      path: "/oneelectric",
      name: {
        español: "Electricos",
        english: "Electric"
      },
      layout: "/StepOne",
    },
  ],
  stepTwo: [
    {
      path: "/totalparkage",
      name: {
        español: "Edad Inicial",
        english: "Initial Age"
      },
      layout: "/StepTwo",
    },
    {
      path: "/totalparkageFuel",
      name: {
        español: "Por Combustible",
        english: "By fuel"
      },
      layout: "/StepTwo",
    },
    {
      path: "/survivalfunction",
      name: {
        español: "Función Supervivencia",
        english: "Survival Function"
      },
      layout: "/StepTwo",
    },
    {
      path: "/technologicalcomposition",
      name: {
        español: "Composición Tecnológica",
        english: "Technological Composition"
      },
      layout: "/StepTwo",
    },
    {
      path: "/parkAge",
      name: {
        español: "Edad del Parque",
        english: "Park Age"
      },
      layout: "/StepTwo",
    }
  ],
  stepThree: [
    {
      path: "/basedistance",
      name: {
        español: "Distancia Base",
        english: "Base Distance"
      },
      layout: "/StepThree",
    }, {
      path: "/kmvehicles",
      name: {
        español: "Kilómetros Recorridos",
        english: "Kilometers Tours"
      },
      layout: "/StepThree",
    },

  ],
  stepFour: [
    {
      path: "/factoremisiones",
      name: {
        español: "Emisiones CO",
        english: "CO Emissions"
      },
      layout: "/StepFour",
    },
    {
      path: "/emisionesnox",
      name: {
        español: "Emisiones Nox",
        english: "Nox Emissions",
      },
      layout: "/StepFour",
    },
    {
      path: "/emisionespm",
      name: {
        español: "Emisiones PM2.5",
        english: "PM2.5 Emissions",
      },
      layout: "/StepFour",
    },
    {
      path: "/emisionesconoxpm",
      name: {
        español: "Resumen Emisiones",
        english: "Summary Emissions",
      },
      layout: "/StepFour",
    },
    {
      path: "/resumenemisiones",
      name: {
        español: "Emisiones CO2",
        english: "CO2 emissions",
      },
      layout: "/StepFour",
    },
    {
      path: "/emisionescotwo",
      name: {
        español: "Resumen CO2",
        english: "CO2 Summary",
      },
      layout: "/StepFour",
    },
  ],

  listRoutes: [
    '/StepOne/proyectprivate', '/StepOne/proyectnoprivate', '/StepOne/onecombustibleprivate', '/StepOne/onecombustiblepublic', '/StepOne/oneelectric',
    '/StepTwo/totalparkage', '/StepTwo/totalparkageFuel',
    '/StepTwo/survivalfunction', '/StepTwo/technologicalcomposition', '/StepTwo/parkAge',
    '/StepThree/basedistance', '/StepThree/kmvehicles',
    '/StepFour/factoremisiones', '/StepFour/emisionesnox', '/StepFour/emisionespm', '/StepFour/emisionesconoxpm', '/StepFour/resumenemisiones', '/StepFour/emisionescotwo'
  ]
};

export default dashboardRoutes;
