import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers/index';

const logger = store => next => action => {

  let result = next(action)

  return result;
}

export default function store() {
  return createStore(reducer,
    compose(
      applyMiddleware(logger,
        thunk
      )
    )
  );
}
