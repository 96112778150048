import React from 'react';
import Chart from "react-apexcharts";
import Card from "../../CustomComponents/Card/Card.jsx";
import CardHeader from "../../CustomComponents/Card/CardHeader.jsx";
import CardBody from "../../CustomComponents/Card/CardBody.jsx";

import Typography from '@material-ui/core/Typography';

export default class BarColum extends React.Component {

    constructor(props) {
        super(props);
        var years = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
        if (this.props.years) {
            years = this.props.years;
        }
        var txtYaxis = '';
        if (this.props.yaxis) {
            txtYaxis = this.props.yaxis
        }

        this.state = {
            options: {
                chart: {
                    id: this.props.lpv[0][0].data[0],
                    group: "BacktestCharts",
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],

                plotOptions: {
                    bar: {
                        horizontal: false,
                        endingShape: 'rounded',
                        columnWidth: '55%',
                    },
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        opacityFrom: 1,
                        opacityTo: 0.8,
                    },
                },

                dataLabels: {
                    enabled: false
                },


                yaxis: {
                    title: {
                        text: txtYaxis,
                        style: {
                            fontSize: 14
                        }
                    },
                    min: 0,
                    labels: {
                        formatter: (val) => {

                            if (this.props.isPercentage) {

                                if (val) {
                                    var value = val * 100
                                    return value.toFixed(1) + '%'
                                } else {
                                    return 0
                                }
                            } else {

                                if (val) {
                                    var valor = Math.trunc(val);
                                    return valor.toLocaleString("es-CL")
                                } else {
                                    return 0
                                }
                            }

                        },
                    },
                },
                xaxis: {
                    tickPlacement: true,
                    categories: years,
                    labels: {
                        show: true,
                        rotate: -45,
                        showDuplicates: true,
                        hideOverlappingLabels: false,
                        trim: true,
                        rotateAlways: true,
                    },
                    title: {
                        text: this.props.scena.years
                    }
                },
                title: {
                    text: this.props.scena.base
                },
            },
            options2: {
                chart: {
                    id: this.props.lpv[0][0].data[0],
                    group: "BacktestCharts",
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],

                plotOptions: {
                    bar: {
                        horizontal: false,
                        endingShape: 'rounded',
                        columnWidth: '55%',
                    },
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        opacityFrom: 1,
                        opacityTo: 0.8,
                    },
                },
                dataLabels: {
                    enabled: false
                },


                yaxis: {
                    title: {
                        text: txtYaxis,
                        style: {
                            fontSize: 14
                        }
                    },
                    min: 0,
                    labels: {
                        formatter: (val) => {

                            if (this.props.isPercentage) {

                                if (val) {
                                    var value = val * 100
                                    return value.toFixed(1) + '%'

                                } else {
                                    return 0
                                }
                            } else {


                                if (val) {
                                    var valor = Math.trunc(val);
                                    return valor.toLocaleString("es-CL")
                                } else {
                                    return 0
                                }
                            }

                        },
                    },
                },
                xaxis: {
                    tickPlacement: true,
                    categories: years,
                    labels: {
                        show: true,
                        rotate: -45,
                        showDuplicates: true,
                        hideOverlappingLabels: false,
                        trim: true,
                        rotateAlways: true,
                    },
                    title: {
                        text: this.props.scena.years
                    }
                },
                title: {
                    text: this.props.scena.comparative
                },
            },
            series: this.props.lpv[0],
            series2: this.props.lpv[1],
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.lpv[0] !== this.props.lpv[0]) {
            this.setState({ series: nextProps.lpv[0] });
        }
        if (nextProps.lpv[1] !== this.props.lpv[1]) {
            this.setState({ series2: nextProps.lpv[1] });
        }
    }

    render() {
        const style = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px'
        }


        return (
            <div className={'row'} >
                <div className={'col s12'} style={style} >
                    <Card>
                        <CardHeader color="warning">
                            <Typography variant="h6" gutterBottom style={{ color: '#FFF' }}>
                                {this.props.text}
                            </Typography>
                        </CardHeader>
                        <CardBody>
                            <div className="mixed-chart">
                                <Chart
                                    options={this.state.options}
                                    series={this.state.series}
                                    type="line"
                                    height="350"
                                />
                                <Chart
                                    options={this.state.options2}
                                    series={this.state.series2}
                                    type="line"
                                    height="350"
                                />
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div >


        );
    }
}