
import XLSX from '@sheet/core';
import S5SCalc from '@sheet/formula';
S5SCalc.XLSXLib = XLSX; // <-- make the connection


const StepOneElectricPrivate = (val, workbook) => {
  var deps = S5SCalc.build_deps(workbook);

  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D518", val.lvSaturationElectric, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D520", val.deltaC, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D521", val.p, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D522", val.q, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D523", val.t0, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D524", val.deltaP, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "G520", val.l, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "G521", parseFloat(val.k), deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "G522", val.t02, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J520", val.fcv, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J521", val.phev, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J522", val.bev, deps);

  return workbook
}

const StepOneElectricTaxi = (val, workbook) => {
  var deps = S5SCalc.build_deps(workbook);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D518", val.lvSaturationElectric, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D656", val.deltaC, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D657", val.p, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D658", val.q, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D659", val.t0, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D660", val.deltaP, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "G656", val.l, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "G657", val.k, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "G658", val.t02, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J656", val.fcv, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J657", val.phev, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J658", val.bev, deps);
  return workbook
}

const StepOneElectricBus = (val, workbook) => {
  var deps = S5SCalc.build_deps(workbook);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D518", val.lvSaturationElectric, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D793", val.deltaC, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D794", val.p, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D795", val.q, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D796", val.t0, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D797", val.deltaP, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "G793", val.l, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "G794", val.k, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "G795", val.t02, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J793", val.fcv, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J794", val.phev, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J795", val.bev, deps);
  return workbook
}

const StepOneElectricCamion = (val, workbook) => {
  var deps = S5SCalc.build_deps(workbook);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D927", val.lvSaturationElectric, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D928", val.deltaC, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D929", val.p, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D930", val.q, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D931", val.t0, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D932", val.deltaP, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "G927", val.l, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "G928", val.k, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "G929", val.t02, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J927", val.fcv, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J928", val.phev, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J929", val.bev, deps);
  return workbook
}

const StepOneHandle = (valueInt, workbook, type) => {
  valueInt = parseInt(valueInt)
  var deps = S5SCalc.build_deps(workbook);
  if (type === 'city') {

    return new Promise(
      (resolve, reject) => {
        try {
          var url = window.location.origin + "/GUESS" + valueInt + ".xlsx";
          var req = new XMLHttpRequest();
          req.open("GET", url, true);
          req.responseType = "arraybuffer";
          req.onload = e => {
            var data = new Uint8Array(req.response);
            var workbook = XLSX.read(data, { type: "array" });

            resolve(workbook);
          };
          req.send();

        } catch (error) {
          reject(error);
        }
      });

  } else if (type === 'growthPopulation') {
    deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D155", valueInt / 100, deps);
    return workbook
  }
  else if (type === 'growthGdp') {
    deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D156", valueInt / 100, deps);
    return workbook
  }
  else if (type === 'input') {
    deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J16", valueInt / 100, deps);
    return workbook
  }
  else if (type === 'variationTaxi') {
    deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D215", valueInt / 100, deps);
    return workbook
  }
  else if (type === 'variationBus') {
    deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D216", valueInt / 100, deps);
    return workbook
  }
  else if (type === 'variationCamion') {
    deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D217", valueInt / 100, deps);
    return workbook
  }
  else if (type === 'lvSaturation') {

    deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D425", valueInt, deps);


    return workbook
  }
}

const StepOneHandleByFour = (val, workbook) => {
  var deps = S5SCalc.build_deps(workbook);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D155", val.gp / 100, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D156", val.gdp / 100, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J16", val.inp / 100, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D215", val.vt / 100, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D216", val.vb / 100, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D217", val.vc / 100, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "D425", val.ls, deps);
  return workbook
}

const StepTwoSurvival = (val, workbook) => {
  var deps = S5SCalc.build_deps(workbook);

  /*
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D629", parseFloat(val.Avp), deps);

  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "E629", parseFloat(val.alfaBuses), deps);
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "F629", parseFloat(val.alfaCamiones), deps);
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "G629", parseFloat(val.alfaTaxis), deps);

  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D630", parseFloat(val.betaVP), deps);
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "E630", parseFloat(val.betaBuses), deps);
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "F630", parseFloat(val.betaCamiones), deps);
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "G630", parseFloat(val.betaTaxis), deps);
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D275", parseFloat(val.GVP), deps);
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "E275", parseFloat(val.GBuses), deps);
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "F275", parseFloat(val.GCamiones), deps);
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "G275", parseFloat(val.GTaxis), deps);
  */

  if (val.Avp) {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D629", parseFloat(val.Avp), deps);
  } if (val.alfaTaxis) {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "E629", parseFloat(val.alfaTaxis), deps);
  } if (val.alfaBuses) {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "F629", parseFloat(val.alfaBuses), deps);
  } if (val.alfaCamiones) {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "G629", parseFloat(val.alfaCamiones), deps);
  } if (val.betaVP) {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D630", parseFloat(val.betaVP), deps);
  } if (val.betaTaxis) {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "E630", parseFloat(val.betaTaxis), deps);
  } if (val.betaBuses) {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "F630", parseFloat(val.betaBuses), deps);
  } if (val.betaCamiones) {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "G630", parseFloat(val.betaCamiones), deps);
  } if (val.GVP) {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D275", parseFloat(val.GVP), deps);
  } if (val.GBuses) {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "E275", parseFloat(val.GBuses), deps);
  } if (val.GCamiones) {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "F275", parseFloat(val.GCamiones), deps);
  } if (val.GTaxis) {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "G275", parseFloat(val.GTaxis), deps);
  }
  return workbook
}

const StepTwoPark = (val, workbook) => {
  var deps = S5SCalc.build_deps(workbook);

  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D629", parseFloat(val.vp), deps);
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "E629", parseFloat(val.taxis), deps);//taxis
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "F629", parseFloat(val.BBuses), deps);//BBuses
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "G629", parseFloat(val.camiones), deps);//camiones
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D630", parseFloat(val.Bvp), deps);//Bvp
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "E630", parseFloat(val.BTaxis), deps);//BTaxis
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "F630", parseFloat(val.BBuses), deps);//BBuses
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "G630", parseFloat(val.BCamiones), deps);//BCamiones
  return workbook
}

const CityChange = (valueInt) => {


  return new Promise(
    (resolve, reject) => {
      try {
        var url = window.location.origin + "/GUESS" + valueInt + ".xlsx";
        var req = new XMLHttpRequest();
        req.open("GET", url, true);
        req.responseType = "arraybuffer";
        req.onload = e => {
          var data = new Uint8Array(req.response);
          var workbook = XLSX.read(data, { type: "array" });

          resolve(workbook);
        };
        req.send();

      } catch (error) {
        reject(error);
      }
    });

}


const StepTwoHandle = (val, workbook, type) => {

  var deps = S5SCalc.build_deps(workbook);
  if (type === 'tcVehicle') {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D317", val, deps);

    return workbook
  }
  else if (type === 'buses') {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D365", val, deps);
    return workbook
  }
  else if (type === 'camiones') {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D413", val, deps);
    return workbook
  } else if (type === 'taxis') {
    deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D463", val, deps);
    return workbook
  }
}

const StepTwoHandlebyFour = (val, workbook) => {
  var deps = S5SCalc.build_deps(workbook);
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D317", val.tcVehicle, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D365", val.buses, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D413", val.camiones, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA2_BGE", "D463", val.taxis, deps);
  return workbook
}

const StepThreeVD = (val, workbook) => {
  var deps = S5SCalc.build_deps(workbook);
  deps = S5SCalc.update_value(workbook, "ETAPA3_BGE", "D170", parseFloat(val.autos) / 100, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA3_BGE", "E170", parseFloat(val.buses) / 100, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA3_BGE", "F170", parseFloat(val.camiones) / 100, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA3_BGE", "G170", parseFloat(val.taxis) / 100, deps);
  return workbook
}

const StepThreeVK = (val, workbook) => {
  var deps = S5SCalc.build_deps(workbook);
  deps = S5SCalc.update_value(workbook, "ETAPA3_BGE", "D255", parseFloat(val.autos) / 100, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA3_BGE", "E255", parseFloat(val.buses) / 100, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA3_BGE", "F255", parseFloat(val.camiones) / 100, deps);
  deps = S5SCalc.update_value(workbook, "ETAPA3_BGE", "G255", parseFloat(val.taxis) / 100, deps);
  return workbook
}

const RangeSlider = (val, workbook, type) => {
  var deps = S5SCalc.build_deps(workbook);

  if (type === "Private") {
    deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "I429", parseFloat(val[3]), deps);
    deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "I430", parseFloat(val[2]), deps);
  } else if (type === "Taxis") {
    deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J429", parseFloat(val[3]), deps);
    deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "J430", parseFloat(val[2]), deps);
  } else if (type === "Buses") {
    deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "K429", parseFloat(val[3]), deps);
    deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "K430", parseFloat(val[2]), deps);
  } else if (type === "Camiones") {
    deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "L429", parseFloat(val[3]), deps);
    deps = S5SCalc.update_value(workbook, "ETAPA1_BGE", "L430", parseFloat(val[2]), deps);
  }

  return workbook
}

export { RangeSlider, StepTwoHandlebyFour, StepOneHandleByFour, StepThreeVK, StepThreeVD, StepTwoPark, StepTwoHandle, CityChange, StepTwoSurvival, StepOneElectricPrivate, StepOneElectricTaxi, StepOneElectricBus, StepOneElectricCamion, StepOneHandle }