import React from 'react'
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import StackedColum from '../TypeGraphic/StackedColum';
import text from '../../DB/text';
import { RangeEurosDividedMillion, RangeYears, GetCity } from "../utility/Range"
import Table from '../TypeGraphic/Table'


export default class EmisionesCO2 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      city: GetCity(props.city),
      lineYears: null,
      CO2Chile: null,
      columns: []
    }
  }


  componentDidMount() {
    this.allRangeEuro()

  }

  allRangeEuro() {

    var workbook
    var CO2Chile = [];
    for (const iterator of this.props.excel) {
      workbook = iterator.Sheets["ETAPA4_BGE"];

      var Particulares = RangeEurosDividedMillion('Y570:Y590', ['Vehículos Particulares'], workbook);//Emisiones CO Vehículos Particulares Bencineros Gran Santiago
      var Buses = RangeEurosDividedMillion('AT570:AT590', ['Buses'], workbook);//Emisiones CO Vehículos Particulares Diésel Gran Santiago
      var Camiones = RangeEurosDividedMillion('BO570:BO590', ['Camiones'], workbook);//Emisiones CO Vehículos Particulares Gas Gran Santiago
      var Taxis = RangeEurosDividedMillion('CJ570:CJ590', ['Taxis'], workbook);//Emisiones CO Vehículos Particulares Gran Santiago Totales

      CO2Chile.push([Particulares[0], Buses[0], Camiones[0], Taxis[0]])
    }

    var lineYears = RangeYears('D570:D590', workbook);

    var columns = [text[this.props.lenguage].tools.TYPE, text[this.props.lenguage].tools.years, text[this.props.lenguage].tools.YEmissions]

    this.setState({
      lineYears,
      CO2Chile,
      columns
    })
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.flagChange == true) {
      if (nextProps.city) {
        var city = GetCity(nextProps.city)
        this.setState({ city }, () => {
        });
      }
      this.allRangeEuro();
    }
  }

  render() {
    const style = {
        
        
        
    };
    const txt = text[this.props.lenguage].graph;
    return (
      <div style={style}>

        <div  >
          <Grid item xs={12}>

            {this.state.CO2Chile && this.props.visualization === 'graph' ?
              <StackedColum scena={text[this.props.lenguage].tools} text={txt.g123}
                years={this.state.lineYears} yaxis={text[this.props.lenguage].tools.YEmissions} lpv={this.state.CO2Chile} /> : null
            }
          </Grid>

          <Grid item xs={12}>

            {this.state.CO2Chile && this.props.visualization === 'table' ?
              <Table text={txt.g123}
                columns={this.state.columns} lpv={this.state.CO2Chile} /> : null
            }
          </Grid>
        </div>
      </div>
    );
  }
}