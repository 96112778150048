import React from 'react';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

class Introduction extends React.Component {

  state = {
    open: false,
    url: '',
    title: ''
  };

  handleImage = (url, title) => {
    var open = !this.state.open
    this.setState({ open, url, title });
  }

  modal = () => {
    var open = !this.state.open
    this.setState({ open });
  }

  rand = () => {
    return Math.round(Math.random() * 20) - 10;
  }

  getModalStyle = () => {
    const top = 20 + this.rand();
    const left = 45 + this.rand();
    return {
      top: `${top}%`,
      width: `100%`,
    };
  }

  render() {
    const { title, text, step, tileData, classes } = this.props;
    const resolveText = () => {
      var resultText = this.props.text;

      if (this.props.links) {

        for (const link of this.props.links) {
          //var strLink = link.link(link);
          var strLink = "<a target='_blank' href='" + link + "'>" + link + "</a>";
          resultText = resultText.replace('_link', strLink)
        }
        return resultText
      } else {
        return this.props.text
      }
    }
    var path = window.location.origin;
    const styles = {
      root: {
        height: 45,
          
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: '#fff',
      },
    }


    return (
      <blockquote style={{ marginLeft: '10px' }}>
        <Typography variant="h5" component="h3">
          {title}
        </Typography>
        <Typography component="div">
          <div className="content" dangerouslySetInnerHTML={{ __html: resolveText() }}></div>
        </Typography>
        {tileData &&

          <div style={styles.root}>
            {tileData.map(tile => (
              <div key={tile.title} style={{ marginTop: 10 }}>
                <Chip
                  key={tile.title + 1}
                  label={tile.title}
                  onClick={() => this.handleImage(path + '/' + tile.img, tile.title)}

                  variant="outlined"

                />
              </div>
            ))}

            <div>
              <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.state.open}
                onClose={() => this.modal()}
                style={{ overflow: 'scroll' }}
              >
                <div style={this.getModalStyle()} className={classes.paper}>
                  <Typography variant="h6" id="modal-title">
                    {this.state.title}
                  </Typography>
                  <Typography variant="subtitle1" id="simple-modal-description">
                    <img src={this.state.url} alt={''} style={{ maxWidth: "100%", maxHeight: 300 }} />
                  </Typography>
                  <SimpleModalWrapped />
                </div>
              </Modal>
            </div>
          </div>
        }
      </blockquote>
    );
  }
}

const stylesDos = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 100,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
});

const SimpleModalWrapped = withStyles(stylesDos)(Introduction);

export default SimpleModalWrapped;