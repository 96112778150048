import React from 'react';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem'

const OneCombustiblePrivate = ({ txt, lvSaturation, handleChange, index }) => {
  return (

    <div className="row">

      <blockquote>
        {index == 0 && <span style={{ color: '#757575', marginRight: 10 }}>{txt.base}</span>}
        {index == 1 && <span style={{ color: '#757575', marginRight: 10 }}>{txt.comparison}</span>}
      </blockquote>


      <span style={{ color: '#757575' }}>{txt.DegreeSaturation}</span>
      <Select
        value={lvSaturation}
        onChange={handleChange(index)}
        style={{ marginLeft: 20, height: 40 }}
        input={
          <OutlinedInput
            style={{ borderColor: '#ffa200' }}
            labelWidth={50}
            name="lvSaturation"
            id="outlined-city-simple"
          />
        }>
        {
          [500, 600, 700, 800].flatMap(i => (
            <MenuItem key={i} style={{ paddingRight: 41 }} value={i}>  <span style={{ paddingRight: 41 }}> {i} </span>  </MenuItem>

          ))
        }
      </Select>
    </div>
  );
}
export default OneCombustiblePrivate