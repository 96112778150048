import React from 'react';
import TotalParkAgeVehicles from '../Components/SteepTwo/TotalParkAgeVehicles';
import TotalParkAgeVehicleFuel from '../Components/SteepTwo/TotalParkAgeVehicleFuel';
import TechnologicalComposition from '../Components/SteepTwo/TechnologicalComposition';
import SurvivalFunction from '../Components/SteepTwo/SurvivalFunction';
import ParkAge from '../Components/SteepTwo/ParkAge';
import Introduction from '../Components/Introduction';
import { connect } from "react-redux";
import City from '../Components/Tools/City';
import OptionsView from '../Components/Tools/OptionsView';
import FunctionSupervival from '../Components/Tools/FunctionSupervival';
import TechComposition from '../Components/Tools/TechComposition';
import ParkAgeTools from '../Components/Tools/ParkAgeTools';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import text from "../DB/text"
import {
    StepTwoSurvival,
    CityChange,
    StepTwoHandle,
    StepTwoPark
} from "../Components/utility/SetInputs"

import LinearDeterminate from "../Components/utility/LinearDeterminate"
import images from '../DB/images';
import routes from "../routes.js";
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import XLSX from '@sheet/core';
import S5SCalc from '@sheet/formula';
import { excel, city } from '../actionCreators';
S5SCalc.XLSXLib = XLSX;

class StepTwo extends React.Component {
    constructor(props) {
        super(props);
        var Inputs = this.setDefaultValues(props.excel);

        this.state = {
            tab: 0,
            visualization: "graph",
            loading: false,
            Inputs
        }
    }

    setDefaultValues = (excel) => {

        var Inputs = [];

        for (let index = 0; index < excel.length; index++) {
            var valDefaul = excel[index].Sheets.ETAPA2_BGE
            var objAuxInput = {
                index: index,
                fsValues: {
                    Avp: valDefaul.D273.v,
                    alfaTaxis: valDefaul.G273.v,
                    alfaBuses: valDefaul.E273.v,
                    alfaCamiones: valDefaul.F273.v,
                    betaVP: valDefaul.D274.v,
                    betaTaxis: valDefaul.G274.v,
                    betaBuses: valDefaul.E274.v,
                    betaCamiones: valDefaul.F274.v,
                    GVP: valDefaul.D275.v,
                    GTaxis: valDefaul.G275.v,
                    GBuses: valDefaul.E275.v,
                    GCamiones: valDefaul.F275.v,
                },
                sendPkValues: {
                },
                sendFsValues: {

                },
                pkValues: {
                    vp: 1.618,
                    taxis: 1.618,
                    buses: 1.618,
                    camiones: 1.618,
                    Bvp: -0.141,
                    BTaxis: -0.188,
                    BBuses: -0.161,
                    BCamiones: -0.47,
                },
                taxis: valDefaul.D463.v,
                camiones: valDefaul.D413.v,
                buses: valDefaul.D365.v,
                tcVehicle: valDefaul.D317.v,
            }

            Inputs.push(JSON.parse(JSON.stringify(objAuxInput)));
        }

        return Inputs;
    }

    handleVizualization = event => {
        this.setState({ visualization: event.target.value });
    };
    handleInputChange = index => event => {
        var nowState = this.state.Inputs
        nowState[index].fsValues[event.target.name] = event.target.value
        nowState[index].sendFsValues[event.target.name] = event.target.value
        this.setState({
            Inputs: nowState
        });
        //   this.setState({ fsValues: { ...this.state.fsValues, [event.target.name]: event.target.value } });
    }
    handleInputChangePK = index => event => {
        var nowState = this.state.Inputs
        nowState[index].pkValues[event.target.name] = event.target.value
        nowState[index].sendPkValues[event.target.name] = event.target.value
        this.setState({
            Inputs: nowState
        });
        //this.setState({ pkValues: { ...this.state.pkValues, [event.target.name]: event.target.value } });
    }

    handleSendInfo = (index) => {
        this.setState({
            loading: true
        }, () => {
            setTimeout(() => {

                var workbook = this.props.excel[index];
                var val = this.state.Inputs[index].sendFsValues;

                workbook = StepTwoSurvival(val, workbook)
                var sendWorkbook = this.props.excel;
                sendWorkbook[index] = workbook

                this.props.changeExcel({ workbook: sendWorkbook })
                this.setState({ flagChange: true, loading: false }, () => {
                    this.setState({ flagChange: false }, () => {
                    })
                })
            }, 1000);
        })
    }

    handleSendInfoPark = (index) => {
        this.setState({
            loading: true
        }, () => {
            setTimeout(() => {

                var workbook = this.props.excel[index];
                console.log('this.state.Inputs[index].fsValues', this.state.Inputs[index].sendPkValues);
                var val = this.state.Inputs[index].sendPkValues

                workbook = StepTwoPark(val, workbook)
                var sendWorkbook = this.props.excel;
                sendWorkbook[index] = workbook
                this.props.changeExcel({ workbook: sendWorkbook })
                this.setState({ flagChange: true, loading: false }, () => {
                    this.setState({ flagChange: false }, () => {
                    })
                })
            }, 1000);
        })
    }

    handleChange = index => event => {
        this.setState({
            loading: true
        }, () => {
            setTimeout(() => {
                var workbook = this.props.excel[index];
                var value = event.target.value;
                var valueInt = parseInt(value);
                if (event.target.name === 'city') {
                    this.props.changeCity({ city: event.target.value });
                    var prom = CityChange(event.target.value, workbook);

                    prom.then((workbook) => {
                        this.props.changeCity({ city: valueInt });
                        var newWorkbook = []

                        newWorkbook.push(JSON.parse(JSON.stringify(workbook)));
                        newWorkbook.push(JSON.parse(JSON.stringify(workbook)));

                        this.props.changeExcel({ workbook: newWorkbook })

                        var newValuesDefault = this.setDefaultValues(newWorkbook)
                        this.setState({
                            Inputs: newValuesDefault
                        });
                        this.setState({ [event.target.name]: event.target.value });
                        this.setState({ flagChange: true, loading: false }, () => {
                            this.setState({ flagChange: false }, () => {
                            })
                        })
                    }).catch(function (error) {
                        // ops, mom don't buy it
                        console.log(error.message);
                    });
                } else {
                    workbook = StepTwoHandle(event.target.value, workbook, event.target.name)
                }
                var sendWorkbook = this.props.excel;
                sendWorkbook[index] = workbook
                this.props.changeExcel({ workbook: sendWorkbook })

                this.setState(prevState => ({
                    Inputs: prevState.Inputs.map(
                        obj => (obj.index === index ? Object.assign(obj, { [event.target.name]: event.target.value }) : obj)
                    )
                }));
                this.setState({ flagChange: true, loading: false }, () => {
                    this.setState({ flagChange: false }, () => {
                    })
                })
            }, 1000);
        })
    };

    handleChangeTabs = (event, tab) => {
        this.setState({ tab });
    };

    handleDirection = (direction) => {
        var position = routes.listRoutes.indexOf(this.props.match.url)
        if (direction == 'next') {
            this.props.history.push(routes.listRoutes[position + 1])
        } else {
            this.props.history.push(routes.listRoutes[position - 1])
        }
    }


    render() {
        const txt = text[this.props.lenguaje]
        return (
            <div style={{ paddingTop: '24px', paddingLeft: '64px', paddingRight: '64px', }}>

                {this.state.loading === true &&
                    <div style={{ marginTop: '5%', zIndex: 9999, }}>
                        <LinearDeterminate /> </div>}

                {this.state.loading === false && <div>

                    <div style={{ marginTop: '5%' }}>

                        <div style={{ position: 'fixed', bottom: '40%', left: 8 }}>
                            <IconButton style={{ padding: '5px', background: '#dadada4d' }} onClick={() => this.handleDirection('before')} name="before">
                                <ChevronLeftIcon style={{ height: 35, width: 35 }} />
                            </IconButton>
                        </div>
                        <div style={{ position: 'fixed', bottom: '40%', right: '7px', float: 'right' }}>
                            <IconButton style={{ padding: '5px', background: '#dadada4d' }} onClick={() => this.handleDirection('next')} name="before">
                                <ChevronRightIcon style={{ height: 35, width: 35 }} />
                            </IconButton>
                        </div>

                        {this.props.match.params.id === 'totalparkage' || this.props.match.params.id == 'totalparkageFuel' ?
                            <div>
                                <AppBar position="static" color="default">
                                    <Tabs
                                        value={this.state.tab}
                                        onChange={this.handleChangeTabs}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Tab label={txt.tools.intro} />
                                        <Tab label={txt.tools.info} />
                                        <Tab label={txt.tools.herra} />
                                        <Tab label={txt.tools.cambCiudad} />
                                    </Tabs>
                                </AppBar>
                                {
                                    this.state.tab != 3 && <div className={"card-panel"} style={{ width: "100%" }}>
                                        {this.state.tab === 0 && <Introduction title={txt.stepTwo.intro.title} text={txt.stepTwo.intro.description} />}
                                        {this.state.tab === 1 && <Introduction tileData={images.StepTwoDie.tileData} title={txt.stepTwo.die.title} text={txt.stepTwo.die.description} />}
                                        {this.state.tab === 2 && <OptionsView txt={txt.tools} visualization={this.state.visualization} handleVizualization={this.handleVizualization} />}
                                    </div>
                                }
                            </div> : null
                        }

                        {this.props.match.params.id === 'survivalfunction' ?
                            <div>
                                <AppBar position="static" color="default">
                                    <Tabs
                                        value={this.state.tab}
                                        onChange={this.handleChangeTabs}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Tab label={txt.tools.config} />
                                        <Tab label={txt.tools.herra} />
                                        <Tab label={txt.tools.info} />
                                        <Tab label={txt.tools.cambCiudad} />
                                    </Tabs>
                                </AppBar>
                                {
                                    this.state.tab != 3 && <div className={"card-panel"} style={{ width: "100%" }}>
                                        {this.state.tab === 0 &&
                                            this.props.excel.map((label, index) => {
                                                return ( //Inputs
                                                    <div>
                                                        {index == 0 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.base}</span>}
                                                        {index == 1 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.comparative}</span>}

                                                        <FunctionSupervival
                                                            txt={txt.tools}
                                                            key={index}
                                                            index={index}
                                                            handleInputChange={this.handleInputChange}
                                                            value={this.state.Inputs[index].fsValues}
                                                            handleSendInfo={this.handleSendInfo} />
                                                    </div>
                                                );
                                            })
                                        }
                                        {this.state.tab === 1 && <OptionsView txt={txt.tools} visualization={this.state.visualization} handleVizualization={this.handleVizualization} />}
                                        {this.state.tab === 2 && <Introduction tileData={images.StepTwoFs.tileData} title={txt.stepTwo.fs.title} text={txt.stepTwo.fs.description} />}
                                    </div>
                                }
                            </div> : null
                        }

                        {this.props.match.params.id === 'technologicalcomposition' ?
                            <div>
                                <AppBar position="static" color="default">
                                    <Tabs
                                        value={this.state.tab}
                                        onChange={this.handleChangeTabs}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Tab label={txt.tools.config} />
                                        <Tab label={txt.tools.herra} />
                                        <Tab label={txt.tools.info} />
                                        <Tab label={txt.tools.cambCiudad} />
                                    </Tabs>
                                </AppBar>
                                {
                                    this.state.tab != 3 && <div className={"card-panel"} style={{ width: "100%" }}>
                                        {this.state.tab === 0 &&
                                            this.props.excel.map((label, index) => {
                                                return ( //Inputs
                                                    <div>
                                                        {index == 0 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.base}</span>}
                                                        {index == 1 && <span style={{ color: '#757575', marginLeft: 20 }}>{txt.tools.comparative}</span>}

                                                        <TechComposition
                                                            key={index}
                                                            index={index}
                                                            tcVehicle={this.state.Inputs[index].tcVehicle}
                                                            vpHandleChange={this.handleChange}
                                                            taxis={this.state.Inputs[index].taxis}
                                                            camiones={this.state.Inputs[index].camiones}
                                                            buses={this.state.Inputs[index].buses}
                                                            txt={txt.tools} />
                                                    </div>

                                                );
                                            })}
                                        {this.state.tab === 1 && <OptionsView txt={txt.tools} visualization={this.state.visualization} handleVizualization={this.handleVizualization} />}
                                        {this.state.tab === 2 && <Introduction tileData={images.StepTwoTC.tileData} title={txt.stepTwo.ct.title} text={txt.stepTwo.ct.description} />}
                                    </div>
                                }
                            </div> : null
                        }

                        {this.props.match.params.id === 'parkAge' ?
                            <div>
                                <AppBar position="static" color="default">
                                    <Tabs
                                        value={this.state.tab}
                                        onChange={this.handleChangeTabs}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Tab label={txt.tools.config} />
                                        <Tab label={txt.tools.herra} />
                                        <Tab label={txt.tools.info} />
                                        <Tab label={txt.tools.cambCiudad} />
                                    </Tabs>
                                </AppBar>
                                {
                                    this.state.tab != 3 && <div className={"card-panel"} style={{ width: "100%" }}>
                                        {this.state.tab === 0 &&
                                            this.props.excel.map((label, index) => {
                                                return ( //Inputs
                                                    <div>
                                                        {index == 0 && <span style={{ color: '#757575' }}>{txt.tools.base}</span>}
                                                        {index == 1 && <span style={{ color: '#757575' }}>{txt.tools.comparative}</span>}

                                                        <FunctionSupervival
                                                            txt={txt.tools}
                                                            key={index}
                                                            index={index}
                                                            handleInputChange={this.handleInputChange}
                                                            value={this.state.Inputs[index].fsValues}
                                                            handleSendInfo={this.handleSendInfo} />
                                                    </div>
                                                );
                                            })}
                                        {this.state.tab === 1 && <OptionsView txt={txt.tools} visualization={this.state.visualization} handleVizualization={this.handleVizualization} />}
                                        {this.state.tab === 2 && <Introduction tileData={images.StepTwoPA.tileData} title={txt.stepTwo.ep.title} text={txt.stepTwo.ep.description} />}
                                    </div>
                                }
                            </div> : null
                        }
                    </div>
                    {this.state.tab === 3 && <div className={"card-panel"}><City txt={txt.tools} index={0} city={this.props.city} handleChange={this.handleChange} /> </div>}
                    {this.props.match.params.id === 'totalparkage' ?
                        <div>
                            {
                                this.props.excel ? <TotalParkAgeVehicles visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} city={this.props.city} flagChange={this.state.flagChange} />
                                    : null
                            }
                        </div> : null}
                    {this.props.match.params.id == 'totalparkageFuel' ?
                        <div>
                            {
                                this.props.excel ? <TotalParkAgeVehicleFuel visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} city={this.props.city} flagChange={this.state.flagChange} />
                                    : null
                            }
                        </div> : null}
                    {this.props.match.params.id == 'survivalfunction' ?
                        <div>

                            {
                                this.props.excel ? <SurvivalFunction visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} city={this.props.city} flagChange={this.state.flagChange} />
                                    : null
                            }
                        </div> : null}
                    {this.props.match.params.id == 'technologicalcomposition' ?
                        <div>
                            <TechnologicalComposition visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} city={this.props.city} flagChange={this.state.flagChange} />
                        </div> : null}
                    {this.props.match.params.id == 'parkAge' ?
                        <div>
                            <ParkAge visualization={this.state.visualization} excel={this.props.excel} lenguage={this.props.lenguaje} city={this.props.city} flagChange={this.state.flagChange} />
                        </div> : null}
                </div>
                }
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return { lenguaje: state.lenguaje, excel: state.excel, city: state.city };
};

const mapDispatchToProps = dispatch => {
    return {
        changeExcel: datos => dispatch(excel(datos)),
        changeCity: datos => dispatch(city(datos))
    };
};

const AppScreen = connect(mapStateToProps, mapDispatchToProps)(StepTwo);

export default AppScreen;
