import React from 'react'
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Lpv from '../TypeGraphic/Lpv';
import text from "../../DB/text"
import { RangeEuros, RangeYears, GetCity } from "../utility/Range"
import Table from '../TypeGraphic/Table'

export default class VehicleNoPrivate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            valueEGV: 0,
            city: GetCity(props.city),
            lineYears: null,
            noPrivate: null,
            columns: []
        }
    }
    componentDidMount() {
        this.allRangeEuro();
    }

    allRangeEuro() {

        console.log('lengauje', this.props.lenguaje);
        var workbook;
        var noPrivate = [];
        for (const iterator of this.props.excel) {
            workbook = iterator.Sheets["ETAPA1_BGE"];
            var lenguaje = text[this.props.lenguage].tools.TBCLenguaje
            var taxi = RangeEuros('D167:D189', [lenguaje[0]], workbook);
            var bus = RangeEuros('E167:E189', [lenguaje[1]], workbook);
            var camion = RangeEuros('G167:G189', [lenguaje[2]], workbook);
            noPrivate.push([taxi[0], bus[0], camion[0]])
        }


        var lineYears = RangeYears('D61:D83', workbook);
        var columns = [text[this.props.lenguage].tools.TYPE, text[this.props.lenguage].tools.years, text[this.props.lenguage].tools.YVNP]

        this.setState({
            lineYears,
            noPrivate,
            columns
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.flagChange == true) {
            if (nextProps.city) {
                var city = GetCity(nextProps.city)
                this.setState({ city }, () => {
                });
            }
            this.allRangeEuro();
        }
    }
    render() {
        return (
            <div>

                <div >

                    {this.state.noPrivate && this.props.visualization === 'graph' ? <Lpv scena={text[this.props.lenguage].tools} yaxis={text[this.props.lenguage].tools.YVNP} text={text[this.props.lenguage].graph.g124 + this.state.city} years={this.state.lineYears} lpv={this.state.noPrivate} /> : null}

                    {this.state.noPrivate && this.props.visualization === 'table' ? <Table text={text[this.props.lenguage].graph.g3 + this.state.city} columns={this.state.columns} lpv={this.state.noPrivate} /> : null}

                </div>
            </div>
        );
    }
}

