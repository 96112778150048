import { combineReducers } from "redux";

const initialState = {
  matrixValue: [],
  matrixFormula: [],
  parseValue: {},
  parseFunction: {},
  loading: false,
}


const stageOne = (state = initialState, action) => {
  if (action.type == "INITIAL_STAGE_ONE") {
    return action;
  }
  else if (action.type == "GET_MATRIX_ONE") {
    return state;
  } else if (action.type == "SET_MATRIX_ONE") {
    state.matrixValue = action.matrixValue;
    return state;
  }
  return state
}

const stageTwo = (state = initialState, action) => {
  if (action.type == "INITIAL_STAGE_TWO") {
    return action;
  }
  else if (action.type == "GET_MATRIX_TWO") {
    return state;
  } else if (action.type == "SET_MATRIX_TWO") {
    state.matrixValue = action.matrixValue;
    return state;
  }
  return state
}

const stageThree = (state = initialState, action) => {
  if (action.type == "INITIAL_STAGE_THREE") {
    return action;
  }
  return state
}

const stageFour = (state = initialState, action) => {
  if (action.type == "INITIAL_STAGE_FOUR") {
    return action;
  }
  return state
}

const lenguaje = (state = 'español', action) => {

  console.log(action);
  if (action.type == "LENGUAJE") {
    state = action.lenguaje;
    return state;
  }
  return state
}
const excel = (state = null, action) => {

  if (action.type == "EXCEL") {
    state = action.workbook;
  }
  return state
}

const city = (state = 1, action) => {

  if (action.type == "CITY") {
    state = action.city;
  }
  return state
}


const reducer = combineReducers({
  stageOne, stageTwo, stageThree, stageFour, lenguaje, excel, city
});
export default reducer;

