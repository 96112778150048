import React from 'react'
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import { row } from '../../DB/row';
import StackedColum from '../TypeGraphic/StackedColum';
import text from "../../DB/text";
import { RangeEuros, RangeYears, GetCity } from "../utility/Range";
import Table from '../TypeGraphic/Table';

export default class KmVehicles extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            city: GetCity(props.city),
            lineYears: null,
            kmPrivate: null,
            kmBuses: null,
            kmCamiones: null,
            kmTaxis: null,
            columns: []
        }
    }



    componentDidMount() {
        this.allRangeEuro();
    }


    allRangeEuro() {

        // var saturation = RangeEuros('L210:L230', ['Bencineros'], workbook);


        var workbook
        var saturation = [];
        var kmPrivate = [];
        var kmBuses = [];
        var kmCamiones = [];
        var kmTaxis = [];

        var typeFuels = text[this.props.lenguage].tools;
        for (const iterator of this.props.excel) {
            workbook = iterator.Sheets["ETAPA3_BGE"];


            let bencineros = RangeEuros('L210:L230', [typeFuels.benchers], workbook);
            let diesel = RangeEuros('T210:T230', [typeFuels.diesel], workbook);
            let gas = RangeEuros('V210:V230', [typeFuels.gas], workbook);
            let electrico = RangeEuros('X210:X230', [typeFuels.electric], workbook);
            kmPrivate.push([bencineros[0], diesel[0], gas[0], electrico[0]])

            bencineros = RangeEuros('AG210:AG230', [typeFuels.benchers], workbook);
            diesel = RangeEuros('AO210:AO230', [typeFuels.diesel], workbook);
            gas = RangeEuros('AQ210:AQ230', [typeFuels.gas], workbook);
            electrico = RangeEuros('AS210:AS230', [typeFuels.electric], workbook);

            kmBuses.push([bencineros[0], diesel[0], gas[0], electrico[0]])

            bencineros = RangeEuros('BB210:BB230', [typeFuels.benchers], workbook);
            diesel = RangeEuros('BJ210:BJ230', [typeFuels.diesel], workbook);
            gas = RangeEuros('BL210:BL230', [typeFuels.gas], workbook);
            electrico = RangeEuros('BN210:BN230', [typeFuels.electric], workbook);

            kmCamiones.push([bencineros[0], diesel[0], gas[0], electrico[0]])

            bencineros = RangeEuros('BW210:BW230', [typeFuels.benchers], workbook);
            diesel = RangeEuros('CE210:CE230', [typeFuels.diesel], workbook);
            gas = RangeEuros('CG210:CG230', [typeFuels.gas], workbook);
            electrico = RangeEuros('CI210:CI230', [typeFuels.electric], workbook);

            kmTaxis.push([bencineros[0], diesel[0], gas[0], electrico[0]])

        }
        var lineYears = RangeYears('D210:D230', workbook)

        var columns = [text[this.props.lenguage].tools.TYPEC, text[this.props.lenguage].tools.years, text[this.props.lenguage].stepThree.vk.title]


        this.setState({
            lineYears,
            kmPrivate,
            kmBuses,
            kmCamiones,
            kmTaxis,
            columns
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.flagChange == true) {
            if (nextProps.city) {
                var city = GetCity(nextProps.city)
                this.setState({ city }, () => {
                });
            }
            this.allRangeEuro();
        }
    }

    render() {
        const style = {
              
              
              
        };

        return (
            <div style={style}>
                <div  >
                    <Grid item xs={12}>
                        {this.state.kmPrivate && this.props.visualization === 'graph' ? <StackedColum scena={text[this.props.lenguage].tools} yaxis={text[this.props.lenguage].stepThree.vk.title} text={text[this.props.lenguage].graph.g52 + this.state.city} years={this.state.lineYears} lpv={this.state.kmPrivate} /> : null}
                        {this.state.kmBuses && this.props.visualization === 'graph' ? <StackedColum scena={text[this.props.lenguage].tools} yaxis={text[this.props.lenguage].stepThree.vk.title} text={text[this.props.lenguage].graph.g53 + this.state.city} years={this.state.lineYears} lpv={this.state.kmBuses} /> : null}
                        {this.state.kmCamiones && this.props.visualization === 'graph' ? <StackedColum scena={text[this.props.lenguage].tools} yaxis={text[this.props.lenguage].stepThree.vk.title} text={text[this.props.lenguage].graph.g54 + this.state.city} years={this.state.lineYears} lpv={this.state.kmCamiones} /> : null}
                        {this.state.kmTaxis && this.props.visualization === 'graph' ? <StackedColum scena={text[this.props.lenguage].tools} yaxis={text[this.props.lenguage].stepThree.vk.title} text={text[this.props.lenguage].graph.g55 + this.state.city} years={this.state.lineYears} lpv={this.state.kmTaxis} /> : null}
                    </Grid>

                    {this.state.kmPrivate && this.props.visualization === 'table' ? <Table columns={this.state.columns} text={text[this.props.lenguage].graph.g52 + this.state.city} lpv={this.state.kmPrivate} /> : null}
                    {this.state.kmBuses && this.props.visualization === 'table' ? <Table columns={this.state.columns} text={text[this.props.lenguage].graph.g53 + this.state.city} lpv={this.state.kmBuses} /> : null}
                    {this.state.kmCamiones && this.props.visualization === 'table' ? <Table columns={this.state.columns} text={text[this.props.lenguage].graph.g54 + this.state.city} lpv={this.state.kmCamiones} /> : null}
                    {this.state.kmTaxis && this.props.visualization === 'table' ? <Table columns={this.state.columns} text={text[this.props.lenguage].graph.g55 + this.state.city} lpv={this.state.kmTaxis} /> : null}

                </div>
            </div>
        );
    }
}



